button.disabled {opacity: 0.8;}

.login{ width: 100%; height: 100%; position: absolute; font-family: "SF Display", "Lato", sans-serif; }

/*Bg Image*/
.login .bg-image { width: 50%; height: 100%; background: #363740; position: absolute; top: 0; left: 0; }
.login .bg-image img{ mix-blend-mode: color-burn; width: 100%; height: 100%; object-fit: cover; }

/*Wrap*/
.login .login-wrap{ position: absolute; top: 50%; left: 50%; margin-top: -270px; margin-left: 160px; width: 380px; }
.login .login-wrap .logo{ margin: 0 auto 30px; width: 250px; }
.login .login-wrap .logo img{ width: 100%; }

/*Box*/
.login .login-wrap .login-box{ background: #fff; box-shadow: 0 0 40px rgba(0, 0, 0, 0.1); border-radius: 8px; padding: 40px 60px; }
.login .login-wrap .login-box .title{ text-align: center; color: #444; text-transform: uppercase; margin-bottom: 30px; letter-spacing: 1px; }
.login .login-wrap .login-box .description{ color: #818181; margin-bottom: 30px; font-size: 13px; }
.login .login-wrap .login-box .reset-button{ margin: 0px 0px 20px; color: #787e8c; background: none; border: none;}
.login .login-wrap .login-box .disabled-text{ color: #444; margin-bottom: 30px; font-size: 13px;}
.login .login-wrap .login-box .reset-button:hover{ color: #cf4727; transition: 0.5s; text-decoration: underline;}
.login .login-wrap .login-box input{ width: 100%; background: #eee; border-radius: 8px; border: none; padding: 10px 20px; font-size: 16px; margin-bottom: 30px; }
.login .login-wrap .login-box .label-check{ color: #787E8C; font-size: 14px; }
.login .login-wrap .login-box .red-button{ width: 100%; text-align: center; border-radius: 8px; padding: 10px 0; cursor: pointer; margin-bottom: 20px; font-weight: normal; text-transform: uppercase; font-family: "SF Display", "Lato", sans-serif; font-weight: bold; letter-spacing: 2px; background: #cf4727; color: #fff; border: none; display: block; font-size: 16px; }
.login .login-wrap .login-box .warning_msg_login{ margin: 10px 0 20px; fonta-weight: 500; color: #f00; text-align: center; }

/*Forgot Password*/
.login .login-wrap .login-box .forgot-password{ text-align: center; }
.login .login-wrap .login-box .forgot-password .forgot{ display: inline-block; color: #787e8c; font-size: 15px; text-decoration: none; cursor: pointer; }
.login .login-wrap .login-box .forgot-password .forgot:hover{ text-decoration: underline; }
.login .login-wrap .login-box .message-container .icon-message { width: 70px; margin: 0 auto;}
.login .login-wrap .login-box .message-container h2 { text-align: center; color: #444; text-transform: uppercase; margin: 20px 0; }

/*Mobile*/
@media screen and (max-width: 1280px){

    .login .bg-image { width: 40%; }
    .login .login-wrap{ margin-left: 50px; }

}

@media screen and (max-width: 1024px){

    .login{ position: relative; height: auto; }
    .login .bg-image { display: none; }
    .login .login-wrap{ margin-left: -190px; margin-bottom: 100px; margin-top: 100px; }

}
