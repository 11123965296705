/*Modals*/
.modal{ position: fixed; width: 100%; height: 100%; background: rgba(0,0,0,0.5); top: 0; left: 0; z-index: 10000; }
.modal .modal-content{ background: #FFF; width: 400px; height: 400px; position: absolute; left: 50%; top: 50%; margin-left: -200px; margin-top: -200px; border-radius: 10px; padding: 30px 0; }
.modal .modal-content .modal-title{ display: block; text-align: center; color: #000; font-size: 24px; font-weight: 600; margin-bottom: 40px; }
.modal .icon-close{ position: absolute; right: 15px; top: 15px; font-size: 11px; width: 20px; height: 20px; text-align: center; line-height: 20px; display: block; cursor: pointer; }
.modal .title-bar{ color: #8f9197; text-align: center; margin-bottom: 40px; position: relative; }
.modal .title-bar:before{ content: ""; display: block; width: 100%; height: 1px; left: 0; background: #e3e3e3; position: absolute; top: 9px; }
.modal .title-bar span{ display: inline-block; background: #FFF; padding: 0 10px; z-index: 10; position: relative; }

    /*Filter Favorites Modal*/
    .modal.map-settings .modal-content{ width: 570px; height: 500px; margin-left: -285px; margin-top: -250px; text-align: center; }
    .modal.map-settings .modal-content ul{ margin-bottom: 50px; }
    .modal.map-settings .modal-content ul li{ width: 240px; margin: 0 auto 20px; line-height: 32px; text-align: left; }
    .modal.map-settings .modal-content ul li .switch{ float: left; margin-right: 20px; }
    .modal.map-settings .modal-content .modal-title{ margin-bottom: 70px; }
    .modal.map-settings .modal-content .title-bar{ margin-bottom: 55px; }

    /*Share Modal*/
    .modal.share{ background: rgba(0,0,0,0.8); display: flex; justify-content: center; align-items: center; }
    .modal.share .modal-content{ width: 400px; max-height: calc(100vh - 100px); height: auto; overflow-y: auto; background: #FFF; margin: auto; top: auto; left: auto; }
    .modal.share .modal-content .header{ padding: 30px 0 0; }
    .modal.share .modal-content .title{ font-family: "Muli"; text-align: center; font-size: 20px; margin-bottom: 15px; letter-spacing: -1px; padding: 0 60px; }
    .modal.share .modal-content .subtitle{ color: #737582; font-size: 14px; margin-bottom: 30px; text-align: center; line-height: 1.3; padding: 0 30px; }
    .modal.share .modal-content .search-bar{ margin: 0 30px; margin-bottom: 20px; position: relative; }
    .modal.share .modal-content .search-bar .icon-search-bar{ color: #C9CED6; font-size: 18px; position: absolute; right: 8px; top: 9px; }
    .modal.share .modal-content .search-bar input{ background: #f3f4f4; border: none; padding-right: 40px; }
    .modal.share .modal-content .user-list{ max-height: calc(100vh - 470px); padding: 0; border-bottom: 1px solid #b3b3b3; margin-bottom: 20px; }
    /* USER STAFF */
    .modal.share .modal-content.user-staff .user-list{ height: calc(100vh - 470px); min-height: 150px; }
    .modal.share .modal-content.user-staff .user-list li{ width: 100%; position: relative; padding: 10px 60px 10px 30px; }
    .modal.share .modal-content.user-staff .user-list li span{ display: block; width: 100%; }
    .modal.share .modal-content.user-staff .user-list li .name{ font-size: 16px !important; color: #000 !important; line-height: 1.3; margin-bottom: 2px; }
    .modal.share .modal-content.user-staff .user-list li .email{ font-size: 12px; color: #8f9197; line-height: 1.3; }
    .modal.share .modal-content.user-staff .user-list li a{ position: absolute; right: 10px; top: 5px }
    /* NOT USER STAFF */
    .modal.share .modal-content.not-user-staff {height: auto; overflow-y: auto;}
    .modal.share .modal-content.not-user-staff .user-list {width: 100%; padding: 20px 20px 60px; height: 320px; min-height: 300px; }
    .modal.share .modal-content.not-user-staff .user-list .guide {display: block; color: #000000; margin-bottom: 5px; font-size: 14px;}
    .modal.share .modal-content.not-user-staff .user-list p-chips {width: 100%; height: 35%; display: flex; align-items: center; justify-content: center; margin-bottom: 30px;}
    .modal.share .modal-content.not-user-staff .user-list p-chips div{width: 100%; height: 100%;}
    .modal.share .modal-content.not-user-staff .user-list p-chips div ul{width: 100%; height: 100%; overflow-y: auto;}
    .modal.share .modal-content.not-user-staff .user-list p-chips div ul::-webkit-scrollbar { width: 12px; height: 15px; }
    .modal.share .modal-content.not-user-staff .user-list p-chips div ul::-webkit-scrollbar-track { background-color: rgba(204, 204, 204, 0.5); border-left: none; }
    .modal.share .modal-content.not-user-staff .user-list p-chips div ul::-webkit-scrollbar-thumb { background-color: #CCCCCC; border-radius: 10px; }
    .modal.share .modal-content.not-user-staff .user-list p-chips div ul::-webkit-scrollbar-thumb:hover { background-color: #A3A3A3 }
    .modal.share .modal-content.not-user-staff .user-list p-chips div li{width: auto;}
    .modal.share .modal-content.not-user-staff .user-list p-chips div.ui-chips > ul.ui-inputtext .ui-chips-token{background:#EBF1F7; color: #000000;}
    .modal.share .modal-content.not-user-staff .user-list p-chips div li.ui-chips-token{margin-bottom: 2px; max-width: 100%;}
    .modal.share .modal-content.not-user-staff .user-list p-chips div li.ui-chips-token .ui-chips-token-label{font-size: 15px;}
    .modal.share .modal-content.not-user-staff .user-list p-chips div li.ui-chips-token .ui-chips-token-icon{font-size: 12px;}
    .modal.share .modal-content.not-user-staff .user-list p-chips div li.ui-chips-input-token{border-bottom: none; width: 100%;}
    .modal.share .modal-content.not-user-staff .user-list p-chips div li.ui-chips-input-token:hover{background: none;}
    .modal.share .modal-content.not-user-staff .user-list p-chips div li.ui-chips-input-token input{width: 100%;}
    .modal.share .modal-content.not-user-staff .user-list p-chips div li .pi-times{background: none;}
    .modal.share .modal-content.not-user-staff .user-list textarea{resize: none; height: 50%; }


    .modal.share .modal-content .bottom-options{ border-bottom: 1px solid #b3b3b3; padding: 5px 30px 10px; }
    .modal.share .modal-content .bottom-options .recent,
    .modal.share .modal-content .bottom-options .select-all{ color: #8f9197; cursor: pointer; }
    .modal.share .modal-content .bottom-options .recent{ float: left; }
    .modal.share .modal-content .bottom-options .select-all{ float: right; border-bottom: #8f9197; }
    .modal.share .modal-content .buttons{ text-align: center; padding: 0 20px; }
    .modal.share .modal-content .buttons .btn{ margin: 0 5px 10px; }
    .modal.share .modal-content .ui-chkbox-box{ position: relative; top: 12px; right: 20px; }

    .modal.share.share-location .modal-content .title{ margin-bottom: 5px; }
    .modal.share.share-location .modal-content .subtitle{ margin-bottom: 5px; }
    .modal.share .modal-content .api-num{ text-align: center; display: block; font-size: 13px; color: #737582; margin-bottom: 25px; }

    /*Share Modal Folder List*/
    .modal.share-list .modal-content {overflow-y: auto;}
    .modal.share-list .modal-content .user-list {padding: 0; border-bottom: 0.1px solid rgb(0, 0, 0, 0.1);}
    .modal.share-list .modal-content .user-list{ height: calc(100% - 370px); border-bottom: 1px solid #b3b3b3; margin-bottom: 30px; }
    .modal.share-list .modal-content .user-list li {height: 60px; padding-top: 20px; padding-bottom: 20px;}
    .modal.share-list .modal-content .user-list li .name{ font-weight: 500; font-size: 18px; float: left; width: 250px; }
    .modal.share-list .modal-content .user-list li{ width: 100%; position: relative; padding: 10px 60px 10px 30px; }
    .modal.share-list .modal-content .user-list li span{ display: block; width: 100%; }
    .modal.share-list .modal-content .user-list li .name{ font-size: 16px !important; color: #000 !important; line-height: 1.3; margin-bottom: 2px; }
    .modal.share-list .modal-content .user-list li a{ position: absolute; right: 10px; top: 5px }
    .modal.share-list .modal-content .bottom-options{ padding: 5px 30px 10px; border-bottom: 0.1px solid rgb(0, 0, 0, 0.1); height: 60px; padding-top: 30px; }

    .modal.share-list .modal-content .folder-list li .pins-value{ position: relative; float: left; margin-right: 20px; top: -4px; }
    .modal.share-list .modal-content .folder-list li .pins-value .icon-pins{ color: #cf4727; font-size: 26px; }
    .modal.share-list .modal-content .folder-list li .pins-value .value{ color: #FFF; background: #313A52; display: inline-block; width: auto; padding: 2px 7px; font-size: 10px; border-radius: 11px; position: absolute; right: -2px; top: 0; }


    .modal.share-list .modal-content .user-list.folder-list{ height: 60px; border-bottom: none; margin-bottom: 0;}
    .modal.share-list .modal-content .user-list.folder-list.two{ max-height: 120px; }
    .modal.share-list .modal-content .user-list.folder-list.three{ max-height: 180px; }

    .modal.share-list .modal-content .user-list.favorite-list {height: 360px;}
    .modal.share-list .modal-content .user-list.favorite-list .scrollbar{ max-height: 300px; overflow-y: auto; }
    .modal.share-list .modal-content .user-list.favorite-list.two {max-height: 300px;}
    .modal.share-list .modal-content .user-list.favorite-list.two .scrollbar{ max-height: 240px; overflow-y: auto;  }
    .modal.share-list .modal-content .user-list.favorite-list.three {max-height: 240px;}
    .modal.share-list .modal-content .user-list.favorite-list.three .scrollbar{ max-height: 180px; overflow-y: auto; }

    .modal.share-list .modal-content .user-list.favorite-list li {height: auto; min-height: 60px; padding-bottom: 10px;}
    .modal.share-list .modal-content .user-list.favorite-list li figure {float: left; margin-right: 13px; }
    .modal.share-list .modal-content .user-list.favorite-list li figure img {width: 15px; height: 26px;}

    .modal.share-list .modal-content .user-list.pins-list {height:max-content; max-height: 480px; padding: 0;}
    .modal.share-list .modal-content .user-list.pins-list .scrollbar{ height: 30vh; max-height: 420px; }
    .modal.share-list .modal-content .user-list.pins-list li {height: auto; min-height: 60px; padding-bottom: 10px;}
    .modal.share-list .modal-content .user-list.pins-list li figure {float: left; margin-right: 13px; }
    .modal.share-list .modal-content .user-list.pins-list li figure img {width: 15px; height: 26px;}

    .modal.share-list .modal-content .ui-chkbox-box{ position: relative; top: 50%; margin-top: 5px; }
    .modal.share.share-location .share-folder .title{ margin-bottom: 20px; }

    /*Add Entrance Modal*/
    .modal.add-entrance{ position: absolute; top: 0; width: 480px; z-index: 1000; left: -480px; height: 100%; background: rgba(0,0,0,0.8); }
    .pins-section .modal.add-entrance{ left: 0;}
    .modal.add-entrance .modal-content{ padding: 30px 20px; height: 530px; margin-top: -300px; }
    .modal.add-entrance .title{ font-family: "Muli"; text-align: center; font-size: 20px; margin-bottom: 15px; letter-spacing: -1px; }
    .modal.add-entrance .subtitle{ color: #737582; font-size: 14px; margin-bottom: 50px; text-align: center; }
    .modal.add-entrance input{ margin-bottom: 20px; }
    .modal.add-entrance .buttons{ text-align: center; margin-top: 70px; }
    .modal.add-entrance .buttons .btn{ margin: 0 10px; height: 30px; line-height: 30px; width: 100px; padding: 0; }

    .modal.add-entrance .colors{ margin-top: 20px; }
    .modal.add-entrance .colors ul li{ width: 35px; height: 35px; border-radius: 100%; border: 2px solid #F4F4F5; margin-right: 5px; cursor: pointer; float: left; position: relative; }

    .modal.add-entrance .colors ul li.default{ background: #cf4727; }
    .modal.add-entrance .colors ul li.white{ background: #FFF; }
    .modal.add-entrance .colors ul li.black{ background: #000; }
    .modal.add-entrance .colors ul li.blue{ background: #4F97E9; }
    .modal.add-entrance .colors ul li.green{ background: #83BC5F; }
    .modal.add-entrance .colors ul li.yellow{ background: #F6CB6E; }
    .modal.add-entrance .colors ul li.orange{ background: #EF9148; }
    .modal.add-entrance .colors ul li.red{ background: #DC555B; }
    .modal.add-entrance .colors ul li.pink{ background: #C02C68; }

    .modal.add-entrance .colors ul li.active:before{  content: '\e801'; font-family: 'wellsite'; display: block; font-size: 18px; color: #FFF; position: absolute; top: 7px; left: 6px; }
    .modal.add-entrance .colors ul li.white.active:before{ color: #000; }


    .tab-view-panels .panel.map-pins .modal.add-entrance{ position: relative; top: 0; left: 0; display: flex; justify-content: center; align-items: center; height: 100%; background: rgba(0,0,0,0.8); overflow: hidden;}
    .tab-view-panels .panel.map-pins .modal.add-entrance .modal-content{ margin: 0; position: relative; display: block;  width: 100%; padding: 0; top: 0; left: 0; height: 100%; border-radius: 0; overflow: auto; }
    .tab-view-panels .panel.map-pins .modal.add-entrance .modal-content .scroll{padding: 50px 30px;}

    .favorites-section .modal.add-entrance{ left: 0px !important; }

    /*Modal Remove*/
    .modal.remove{ background: rgba(0,0,0,0.8); }
    .modal.remove .modal-content{ height: 300px; }
    .modal.remove .modal-content .title{ font-family: "Muli"; text-align: center; font-size: 20px; margin-bottom: 10px; letter-spacing: -1px; padding: 50px 40px; }
    .modal.remove .modal-content .buttons{ text-align: center; padding: 0 50px; }
    .modal.remove .modal-content .buttons .btn{ margin: 0 5px 10px; }

    /*Modal Confirmation*/
    .modal.confirmation{ background: rgba(0,0,0,0.8); z-index: 10000; }
    .modal.confirmation .modal-content{ height: 300px; }
    .modal.confirmation .modal-content .title{ font-family: "Muli"; text-align: center; font-size: 30px; margin-bottom: 10px; letter-spacing: -1px; padding: 20px 40px; }
    .modal.confirmation .modal-content .modal-body{ padding: 0 30px; }
    .modal.confirmation .modal-content .modal-body p{ text-align: center; }
    .modal.confirmation .modal-content .buttons{ text-align: center; padding: 0 50px; position: absolute; bottom: 20px; width: 100%; }
    .modal.confirmation .modal-content .buttons .btn{ margin: 0 5px 10px; }

    /*Modal New Folder*/
    .modal.new-folder{ background: rgba(0,0,0,0.8); }
    .modal.new-folder .modal-content{ height: 300px; padding: 0 30px; }
    .modal.new-folder .modal-content .title{ font-family: "Muli"; text-align: center; font-size: 20px; margin-bottom: 10px; letter-spacing: -1px; padding: 50px 40px 30px; }
    .modal.new-folder input{ margin-bottom: 70px; }
    .modal.new-folder .modal-content .buttons{ text-align: center; padding: 0 50px; }
    .modal.new-folder .modal-content .buttons .btn{ margin: 0 5px 10px; }

    /*Modal Add List*/
    .modal.add-list{ background: rgba(0,0,0,0.8); }
    .modal.add-list .modal-content{ height: 500px; padding: 0 30px; margin-top: -350px; }
    .modal.add-list .modal-content .title{ font-family: "Muli"; text-align: center; font-size: 20px; margin-bottom: 10px; letter-spacing: -1px; padding: 50px 40px 5px; }
    .modal.add-list .subtitle{ color: #737582; font-size: 14px; margin-bottom: 20px; text-align: center; padding: 0 70px; }

    .modal.add-list .modal-content .buttons{ text-align: center; padding: 0 50px; margin-top: 40px; }
    .modal.add-list .modal-content .buttons .btn{ margin: 0 5px 10px; }
    .modal.add-list .modal-content form label{ color: #9FA2B4; font-weight: bold; display: block; margin-bottom: 10px; font-size: 13px; }
    .modal.add-list .modal-content form input{ margin-bottom: 20px; }

        .modal.add-list .modal-content.pins{ height: 390px; margin-top: -300px; }
        .modal.add-list .modal-content.pins .subtitle{  margin-bottom: 40px; }
        .modal.add-list .modal-content.pins .buttons{ margin-top: 30px; }

    /*Filter by Favorite Modal*/
    .filter-by-favorites-modal .modal-content{ height: 600px; margin-top: -300px; width: 570px; margin-left: -285px; }
    .filter-by-favorites-modal .modal-content .title{ text-align: center; font-size: 24px; margin-bottom: 10px; font-weight: 600; padding: 20px 0; }
    .filter-by-favorites-modal .modal-content .search-bar{ margin: 0 30px; margin-bottom: 40px; position: relative; }
    .filter-by-favorites-modal .modal-content .search-bar .icon-search-bar{ color: #C9CED6; font-size: 18px; position: absolute; right: 8px; top: 9px; }
    .filter-by-favorites-modal .modal-content .search-bar input{ background: #f3f4f4; border: none; padding-right: 40px; }
    .filter-by-favorites-modal .modal-content .folder-list{ height: 290px; overflow-y: auto; padding: 0; border-bottom: 1px solid #d9d9d9; border-top: 1px solid #d9d9d9; margin-bottom: 30px; }
    .filter-by-favorites-modal .modal-content .folder-list li{ width: 100%; position: relative; padding: 30px 35px 25px 30px; border-bottom: 1px solid #d9d9d9; cursor: pointer; transition: background 0.3s ease; }
    .filter-by-favorites-modal .modal-content .folder-list li:hover,
    .filter-by-favorites-modal .modal-content .folder-list li.active{ background: #EBF1F7; }
    .filter-by-favorites-modal .modal-content .folder-list li .pins-value{ position: relative; float: left; margin-right: 20px; top: -4px; }
    .filter-by-favorites-modal .modal-content .folder-list li .pins-value .icon-pins{ color: #cf4727; font-size: 26px; }
    .filter-by-favorites-modal .modal-content .folder-list li .pins-value .value{ color: #FFF; background: #313A52; display: inline-block; width: auto; padding: 2px 7px; font-size: 10px; border-radius: 11px; position: absolute; right: -2px; top: 0; }
    .filter-by-favorites-modal .modal-content .folder-list li .name{ font-weight: 600; font-size: 20px; float: left; }
    .filter-by-favorites-modal .modal-content .btn{ margin: 0 auto; display: block; margin-top: 47px; }


    /*Filter Reset credentials Modal*/
    .modal.reset-credentials .modal-content{ width: 570px; height: 500px; margin-left: -285px; margin-top: -250px; text-align: center; padding: 30px; }
    .modal.reset-credentials .modal-content ul{ margin-bottom: 50px; }
    .modal.reset-credentials .modal-content ul li{ margin: 0 auto 20px; line-height: 32px; text-align: left; }
    .modal.reset-credentials .modal-content .modal-title{ margin-bottom: 70px; }
    .modal.reset-credentials .modal-content .title-bar{ margin-bottom: 55px; }
    .modal.reset-credentials .modal-content .btn{ margin-top: 30px;}


    /* Notifications Modal */
    .modal.notification{ background: rgba(0,0,0,0.8); }
    .modal.notification .modal-content{ height: 300px; }
    .modal.notification .modal-content .title{ font-family: "Muli"; text-align: center; font-size: 20px; margin-bottom: 10px; letter-spacing: -1px; padding: 50px 40px; }

    .modal.notification .modal-content .btn{text-align: center; width: 80px; position: absolute; bottom: 40px; left: 50%; margin-left: -40px;}


    /* Email Modal */
    .modal.add-email .modal-content {height: 325px; margin-top: -162.5px; width: 425px; margin-left: -212.5px;}
    .modal.add-email .modal-content .title {text-align: center; padding: 10px 20px;}
    .modal.add-email .modal-content .title.confirmation { padding: 20px;}
    .modal.add-email .modal-content .modal-body {padding: 30px 20px;}
    .modal.add-email .modal-content .modal-body p{text-align: center;}
    .modal.add-email .modal-content .modal-body input {margin: 0 0 10px;}
    .modal.add-email .modal-content .modal-body span {display: block; padding: 10px; font-weight: 600; color: #9FA2B4;}
    .modal.add-email .modal-content .modal-footer {position: absolute; width: 100%; bottom: 30px; padding: 0 20px;}
    .modal.add-email .modal-content .modal-footer.confirmation {display: flex; justify-content: center}

    .modal.add-email .modal-content .modal-footer button{float: right; margin: 0 10px;}
    .notification.contact-us .notification-body {display: flex; align-items: center; flex-direction: column;}
    .notification.contact-us .footer {width: 100%; display: flex; align-items: center; justify-content: center; height: 100px;}
    .notification.contact-us .btn.contact-us {width: auto; bottom: auto; left: auto; margin-left: auto}

    /* Pending Users */
    .modal.pending-users .modal-content{width: 80%; height: 70vh; margin-left: -40%; margin-top: -35vh; max-height: 700px; padding: 15px 0;}
    .modal.pending-users .modal-content .header{padding: 0 0 20px;}


    .modal.pending-users .modal-content .body .table-wrapper{max-height: 620px; height: calc(70vh - 150px); overflow: hidden;}
    .modal.pending-users .modal-content .body .table-wrapper table tbody tr td.buttons .btn{margin-right: 5px;}
    .modal.pending-users .modal-content .body .table-wrapper table tbody tr td .btn.btn-sqr{padding: 8px; float: right;}
    .modal.pending-users .modal-content .body .table-wrapper table tbody tr td .btn.btn-sqr.resend{background: #4284F5; border: 1px solid #4284F5;}
    .modal.pending-users .modal-content .body .table-wrapper table tbody tr td .btn.btn-sqr.resend:hover{color: #4284F5; background: #FFFFFF;}
    .modal.pending-users .modal-content .body .table-wrapper table tbody tr td .btn.btn-sqr .icon-close{position: relative; top: 0; right: 0; font-size: 100%;}

    .table-container.pending-users-list .title{margin-left: 30px;}
    .table-container.pending-users-list table .spacer{width: 2%;}
    .table-container.pending-users-list table .mail{width: 21.5%;}
    .table-container.pending-users-list table .sent-date{width: 21.5%;}
    .table-container.pending-users-list table .invited-by{width: 21.5%;}
    .table-container.pending-users-list table .business-plan{width: 21.5%;}
    .table-container.pending-users-list table .buttons{min-width: 100px; width: 10%;}
    .table-container.pending-users-list table span.date{ display: block; color: #252733; font-size: 14px; font-weight: 600; transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
    .table-container.pending-users-list table span.hour{ display: block; color: #C5C7CD; font-size: 12px; transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

    /* Show Recorded Route Modal */
    .modal.show-recorded-route .modal-content{ width: 800px; height: 200px; margin-left: -400px; margin-top: -100px; text-align: center; padding: 30px 0; max-height: 200px; overflow: hidden; display: flex; flex-direction: column; align-items: center; justify-content: space-between;}
    .modal.show-recorded-route .modal-content .modal-footer { display: flex; width: 100%; flex-direction: row; align-items: center; justify-content: right; padding: 0 30px; }
    .modal.show-recorded-route .modal-content .modal-footer button:nth-child(even) { margin-left: 10px; }


    /* Show Folder Modal */
    .modal.show-folder .modal-content{ width: 800px; height: 500px; margin-left: -400px; margin-top: -250px; text-align: center; padding: 30px 0; max-height: 500px; overflow: hidden;}
    .modal.show-folder .modal-content .marker-list { display: block; position: relative; width: 100%; height: calc(100% - 80px); top: 20px; }
    .modal.show-folder .modal-content .marker-list li{ transition: all 0.3s ease;}
    .modal.show-folder .modal-content .marker-list li:hover{ background: #EBF1F7;}
    .modal.show-folder .modal-content .marker-list li:first-of-type .list-content { border-top: 0.3px solid rgb(0, 0, 0,0.1);}
    .modal.show-folder .modal-content .marker-list li .list-content{ padding: 30px 110px 30px 40px; border-bottom: 0.3px solid rgb(0, 0, 0,0.1); display: flex; flex-direction: row; align-items: center;}
    .modal.show-folder .modal-content .marker-list li .list-content div {margin-left: 30px;}
    .modal.show-folder .modal-content .marker-list li .list-content .marker-coordinates {display: flex; flex-direction: column; align-items: baseline;}
    .modal.show-folder .modal-content .marker-list li .list-content .marker-coordinates p{color: #737582;}
    .modal.show-folder .modal-content .marker-list li .list-content .name{ float: left; color: #52575C; font-size: 16px; font-weight: 600; transition: all 0.3s ease; cursor: pointer;}
    .modal.show-folder .modal-content .marker-list li .icon-share{ font-size: 24px; position: relative; top: 6px;}
    .modal.show-folder .modal-content .marker-list li figure {float: left; margin-right: 13px;}
    .modal.show-folder .modal-content .marker-list li figure img {width: 15px; height: 26px;}
    .modal.show-folder .modal-content .marker-list li .marker-actions{ top: 20px; }
    .modal.show-folder .modal-content .modal-footer{position: absolute; width: 100%; bottom: 15px; padding: 0 20px;}
    .modal.show-folder .modal-content .modal-footer button{float: right; margin: 0 10px;}

    .modal.show-folder-name{}
    .modal.show-folder-name .modal-content{ width: 600px; height: 200px; margin-left: -300px; margin-top: -100px; text-align: center; padding: 30px; max-height: 200px; overflow: hidden;}
    .modal.show-folder-name .modal-content .modal-body{ margin-top: 30px}
    .modal.show-folder-name .modal-content .modal-footer{position: absolute; width: 100%; bottom: 15px; padding: 0 20px;}
    .modal.show-folder-name .modal-content .modal-footer button{float: right; margin: 0 30px;}

    /* Message Broadcast Modal */
    .modal.user-list .modal-content{ width: 600px; height: 600px; margin-left: -300px; margin-top: -300px; padding: 30px 0; max-height: 600px; overflow: hidden; position: relative;}
    .modal.user-list .modal-content .title{font-size: 24px; font-weight: 500; display: block; width: 100%; text-align: center;}
    .modal.user-list .modal-content .modal-body{margin: 20px 0 ; position: relative; height: calc(100% - 100px); border-bottom: 1px solid #b3b3b3;}
    .modal.user-list .modal-content .search-bar{ margin: 0 30px; margin-bottom: 20px; position: relative; }
    .modal.user-list .modal-content .search-bar .icon-search-bar{ color: #C9CED6; font-size: 18px; position: absolute; right: 8px; top: 9px; }
    .modal.user-list .modal-content .search-bar input{ background: #f3f4f4; border: none; padding-right: 40px; }
    .modal.user-list .modal-content .user-list{ height: calc(100% - 100px); overflow: hidden; padding: 0; }
    .modal.user-list .modal-content .user-list li{ width: 100%; position: relative; padding: 10px 60px 10px 30px; }
    .modal.user-list .modal-content .user-list li:hover{ background: #ebf1f759;}
    .modal.user-list .modal-content .user-list li span{ display: block; width: 100%; }
    .modal.user-list .modal-content .user-list li .user{ display: flex; flex-direction: row;}
    .modal.user-list .modal-content .user-list li .user .profile{ position: relative; display: flex; width: 48px; height: 48px; align-items: center; justify-content: center; font-size: 24px; background: #313A5280; color: #FFFFFF; border-radius: 100%; font-weight: 600; margin-right: 10px;}
    .modal.user-list .modal-content .user-list li .user .user-data{ display: flex; flex-direction: column; justify-content: center; align-items: flex-start; }
    .modal.user-list .modal-content .user-list li .user .user-data .fullname{ font-size: 16px !important; color: #000 !important; line-height: 1.3; margin-bottom: 2px; }
    .modal.user-list .modal-content .user-list li .user .user-data .email{ font-size: 12px; color: #8f9197; line-height: 1.3; }
    .modal.user-list .modal-content .user-list li a{ position: absolute; right: 10px; top: 5px }
    .modal.user-list .modal-content .bottom-options{ border-bottom: 1px solid #b3b3b3; padding: 5px 30px 10px; }
    .modal.user-list .modal-content .bottom-options .recent,
    .modal.user-list .modal-content .bottom-options .select-all{ color: #8f9197; cursor: pointer; }
    .modal.user-list .modal-content .bottom-options .recent{ float: left; }
    .modal.user-list .modal-content .bottom-options .select-all{ float: right; border-bottom: #8f9197; }
    .modal.user-list .modal-content .ui-chkbox-box{ position: relative; top: 12px; right: 20px; }
    .modal.user-list .modal-content .modal-footer{display: flex; justify-content: center;}
    .modal.user-list .modal-content .modal-footer button.btn{ margin: 0 10px; }

    .modal.user-list .modal-content .modal-body.message{padding: 0 80px;}
    .modal.user-list .modal-content .modal-body.message .selected-users{height: 100px; max-height: 100px; border: 1px solid #b3b3b3; border-radius: 4px; margin-bottom: 20px;}
    .modal.user-list .modal-content .modal-body.message .selected-users ul{padding: 10px;}
    .modal.user-list .modal-content .modal-body.message .selected-users ul li.selected-user{display: inline-flex; flex-direction: row; padding: 2px 5px; background: #EBF1F7; border-radius: 4px; margin-right: 5px; margin-bottom: 5px;}
    .modal.user-list .modal-content .modal-body.message .selected-users ul li.selected-user span:hover{cursor: default;}
    .modal.user-list .modal-content .modal-body.message .selected-users ul li.selected-user .icon-close{display: inline-block; position: relative; top: 0; left: 0;}

    .modal.user-list .modal-content .modal-body.message .message-text{height: calc(100% - 150px);}
    .modal.user-list .modal-content .modal-body.message .message-text textarea{height: 100%; padding: 20px; resize: none;}
    .modal.user-list .modal-content .modal-body.message .message-text textarea::placeholder{color: #bcbdc1;}

    /* Send Image Modal */
    .modal.send-image .modal-content{ width: 600px; height: 600px; margin-left: -300px; margin-top: -300px; padding: 30px 0; max-height: 600px; overflow: hidden; position: relative;}
    .modal.send-image .modal-content .title{font-size: 24px; font-weight: 500; display: block; width: 100%; text-align: center;}
    .modal.send-image .modal-content .modal-body{margin: 20px 0 ; position: relative; display: flex; justify-content: center; align-items: center; height: calc(100% - 100px); border-bottom: 1px solid #b3b3b3;}
    .modal.send-image .modal-content .modal-body .image-container{display: flex; flex-direction: column; justify-content: center; align-items: center; width: calc(100% - 100px); height: 380px; margin-bottom: 20px; border: 1px dashed #989ca8; border-radius: 8px;}
    .modal.send-image .modal-content .modal-body .image-container span{font-size: 14px;}
    .modal.send-image .modal-content .modal-body .image-container #file-upload {display: none;}
    .modal.send-image .modal-content .modal-body .image-container a{margin: 10px;}
    .modal.send-image .modal-content .modal-body .image-container button{margin-top: 15px; font-weight: 500;}
    .modal.send-image .modal-content .modal-footer{display: flex; justify-content: center;}
    .modal.send-image .modal-content .modal-footer button.btn{ margin: 0 10px; }

    .modal.send-image .modal-content .modal-body .image-container .image-list{display: flex; width: 100%; height: 100%; flex-direction: column; overflow: hidden;}
    .modal.send-image .modal-content .modal-body .image-container .image-list .image-item{display: flex; width: 100%; height: 140px; flex-direction: row; padding: 15px; align-items: center; justify-content: space-between; transition: all 0.3s ease;}
    .modal.send-image .modal-content .modal-body .image-container .image-list .image-item:hover{background: #EBF1F7;}
    .modal.send-image .modal-content .modal-body .image-container .image-list .image-item .left-part{display: flex; flex-direction: row; justify-content: center; align-items: center;}
    .modal.send-image .modal-content .modal-body .image-container .image-list .image-item .not-preview{display: flex; justify-content: center; align-items: center; width: 100px; height: 100px; min-width: 100px; min-height: 100px; border-radius: 6px; margin-right: 10px; border: 1px solid #8f9197;}
    .modal.send-image .modal-content .modal-body .image-container .image-list .image-item .not-preview span{font-size: 20px; color: #8f9197; text-align: center;}
    .modal.send-image .modal-content .modal-body .image-container .image-list .image-item figure{display: block; width: 100px; height: 100px; min-width: 100px; min-height: 100px; overflow: hidden; border-radius: 6px; margin-right: 10px;}
    .modal.send-image .modal-content .modal-body .image-container .image-list .image-item figure img{width: 100%; height: 100%; object-fit: cover; }
    .modal.send-image .modal-content .modal-body .image-container .image-list .image-item .image-title{display: inline-block;}
    .modal.send-image .modal-content .modal-body .image-container .image-list .image-item .right-part{display: flex; flex-direction: row; justify-content: center; align-items: center;}
    .modal.send-image .modal-content .modal-body .image-container .image-list .image-item .right-part .image-size{}
    .modal.send-image .modal-content .modal-body .image-container .image-list .image-item .right-part .image-delete.icon-close{position: relative; top: 0; left: 0; margin-left: 20px; font-size: 14px;}

    /* Add Marker Modal */
    .modal.add-marker{width: 100vw; height: 100vh; display: flex; justify-content: center; align-items: center;}
    .modal.add-marker .modal-content{ width: 40vw; height: 65vh; margin-left: auto; margin-top: auto; top: auto; left: auto; min-width: 400px; min-height: 350px;}
    .modal.add-marker .modal-content .icon-close{}
    .modal.add-marker .modal-content .title{text-align: center; font-size: 24px; font-weight: 600; padding: 20px;}
    .modal.add-marker .modal-content .modal-body{}
    .modal.add-marker .modal-content .modal-body .input-group{padding: 10px 20px;}
    .modal.add-marker .modal-content .modal-body .input-group div label{margin-left: 15px;}
    .modal.add-marker .modal-content .modal-body .input-group div input{margin-bottom: 20px;}
    .modal.add-marker .modal-content .modal-body .input-group textarea{min-height: 120px; resize: none; margin-bottom: 15px;}
    .modal.add-marker .modal-content .modal-body .input-group input{margin-top: 10px;}
    .modal.add-marker .modal-content .modal-body .buttons{border-top: 0.3px solid rgba(196,196,196,0.2); display: flex; justify-content: center; align-items: center; padding: 40px; position: absolute; width: 100%; bottom: 0;}
    .modal.add-marker .modal-content .modal-body .buttons button{margin: 0 20px}

    /* Show Markers Modal */
    .show-stops.modal{width: 100vw; height: 100vh; display: flex; justify-content: center; align-items: center;}
    .show-stops.modal .modal-content{width: 50vw; height: 60vh; overflow: hidden; margin-left: auto; margin-top: auto; top: auto; left: auto; min-width: 500px; min-height: 400px}
    .show-stops.modal .modal-content .modal-header{display: flex; flex-direction: column; padding: 10px 50px; justify-content: center; align-items: center; align-items: center;}
    .show-stops.modal .modal-content .modal-header h1{font-size: 20px;}
    .show-stops.modal .modal-body ul.tab-view{display: flex; width: 100%; flex-direction: row; justify-content: space-evenly; padding: 10px 50px;}
    .show-stops.modal .modal-body ul.tab-view li{display: block; padding: 5px 15px; color: #1f232e7F; transition: all 0.2s ease;}
    .show-stops.modal .modal-body ul.tab-view li:hover{border-bottom: 2px solid #CF4727; cursor: pointer;}
    .show-stops.modal .modal-body ul.tab-view li.selected{opacity: 1; border-bottom: 2px solid #CF4727; color: #1f232e}
    .show-stops.modal .modal-body .tab-view-panels {height: 40vh; min-height: 200px; overflow-y: auto;}

    /* Panel favorites */
    .show-stops.modal .modal-body .panel.favorites{  position: relative; width: 100%; }
    .show-stops.modal .modal-body .panel.favorites li.folder-item .list-content{ padding: 30px 40px 20px 40px }
    .show-stops.modal .modal-body .panel.favorites .pins-value{ position: relative; float: left; margin-right: 20px; top: -4px; }
    .show-stops.modal .modal-body .panel.favorites .pins-value .icon-pins{ color: #cf4727; font-size: 26px; }
    .show-stops.modal .modal-body .panel.favorites .pins-value .value{ color: #FFF; background: #313A52; display: inline-block; width: auto; padding: 2px 7px; font-size: 10px; border-radius: 11px; position: absolute; right: -2px; top: 0; }
    .show-stops.modal .modal-body .panel.favorites li.folder-item .list-content .name{ font-weight: 600; font-size: 18px; float: left; max-width: 260px; }
    .show-stops.modal .modal-body .panel.favorites li.folder-item .list-content i.icon-arrow{ float: right; visibility: hidden; }
    .show-stops.modal .modal-body .panel.favorites li.folder-item .list-content:hover i.icon-arrow{ visibility: visible; }
    .show-stops.modal .modal-body .panel.favorites li{ width: 100%; height: auto; border-bottom: 0.3px solid rgba(0, 0, 0, 0.1); position: relative; }
    .show-stops.modal .modal-body .panel.favorites li.folder-item {min-height: 80px;}

    .show-stops.modal .modal-body .panel.favorites li.folder-item .list-content.selected {border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
    .show-stops.modal .modal-body .panel.favorites li.folder-item .list-content.selected i.icon-arrow {visibility: visible;}
    .show-stops.modal .modal-body .panel.favorites li .folder-content ul li{ padding: 30px 80px 30px 100px; transition: all 0.3s ease;}
    .show-stops.modal .modal-body .panel.favorites li .folder-content ul li:hover{ background: #EBF1F7; cursor: pointer;}
    .show-stops.modal .modal-body .panel.favorites li .folder-content ul li .favorite-description {padding-left: 30px;}
    .show-stops.modal .modal-body .panel.favorites li .folder-content ul li .name{ width: 290px; float: left; color: #52575C; font-size: 16px; font-weight: 600; transition: all 0.3s ease; max-width: 320px; cursor: pointer; }
    .show-stops.modal .modal-body .panel.favorites li .folder-content ul li .api-num{ color: #A0A4A8; font-size: 14px;  transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

    .show-stops.modal .modal-body .panel.favorites li .list-content{ padding: 30px 110px 30px 40px; transition: all 0.3s ease;}
    .show-stops.modal .modal-body .panel.favorites li .list-content:hover{ background: #EBF1F7;}
    .show-stops.modal .modal-body .panel.favorites li .list-content .favorite-description {padding-left: 30px;}
    .show-stops.modal .modal-body .panel.favorites li .list-content .name{ width: 290px; float: left; color: #52575C; font-size: 16px; font-weight: 600; transition: all 0.3s ease; max-width: 320px; cursor: pointer; }
    .show-stops.modal .modal-body .panel.favorites li .list-content .icon-pin-small{ float: left; }
    .show-stops.modal .modal-body .panel.favorites li .list-content .api-num{ color: #A0A4A8; font-size: 14px;  transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
    .show-stops.modal .modal-body .panel.favorites li .icon-share{ font-size: 24px; position: relative; top: 6px; }
    .show-stops.modal .modal-body .panel.favorites li figure {float: left; margin-right: 13px; }
    .show-stops.modal .modal-body .panel.favorites li figure img {width: 15px; height: 26px;}
    .show-stops.modal .modal-body .panel.favorites li .marker-actions{ top: 20px; }
    .show-stops.modal .modal-body .panel.favorites div.separator {display: block; position: relative; background: #EBF1F7; height: 15px; width: 100%; }
    /* My pins panel */
    .show-stops.modal .modal-body .panel.my-pins{ position: relative; width: 100%; }
    .show-stops.modal .modal-body .panel.my-pins li{ width: 100%; height: auto; border-bottom: 0.3px solid rgb(0, 0, 0,0.1); position: relative; transition: all 0.3s ease; }
    .show-stops.modal .modal-body .panel.my-pins li.list-item:hover{ background: #EBF1F7;}
    .show-stops.modal .modal-body .panel.my-pins li.add-pin{ display: flex; justify-content: center; align-items: center; height: 100px; border-bottom: none }
    .show-stops.modal .modal-body .panel.my-pins li.add-pin button{ width: 50%; }
    .show-stops.modal .modal-body .panel.my-pins li .marker-actions{ top: 20px; }
    .show-stops.modal .modal-body .panel.my-pins li .list-content{ padding: 30px 110px 30px 40px; }
    .show-stops.modal .modal-body .panel.my-pins li .list-content .name{ width: 100%; float: left; display: block; color: #52575C; font-size: 16px; font-weight: 600; transition: all 0.3s ease; max-width: 320px; cursor: pointer; line-height: 1.3; margin-bottom: 10px;}
    .show-stops.modal .modal-body .panel.my-pins li .list-content .info{ display: flex; flex-direction: column; color: #737582; }
    /* Layer panel */
    .show-stops.modal .modal-body .panel.layer .search-results{ width: 100%; }
    .show-stops.modal .modal-body .panel.layer .search-results li.item-search{ width: 100%; height: auto; border-bottom: 0.3px solid rgb(0, 0, 0,0.1); position: relative; transition: all 0.3s; }
    .show-stops.modal .modal-body .panel.layer .search-results li.item-search:hover{ background: #EBF1F7; }
    .show-stops.modal .modal-body .panel.layer .search-results li.item-search.selected{ background: #EBF1F7; }
    .show-stops.modal .modal-body .panel.layer .search-results li.item-search .list-content { padding: 30px 110px 30px 40px;}
    .show-stops.modal .modal-body .panel.layer .search-results li.item-search .list-content .name{ display: block; float: left; color: #52575C; font-size: 16px; font-weight: 600; transition: all 0.3s ease; max-width: 300px;cursor: pointer; margin-bottom: 5px; }
    .show-stops.modal .modal-body .panel.layer .search-results li.item-search .list-content .icon-pin-small{ float: left;}
    .show-stops.modal .modal-body .panel.layer .search-results li.item-search .list-content .api-num{ display: block; color: #A0A4A8; font-size: 14px;  transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }


    /* Modal Set User Trucks */
    .modal.set-user-trucks {}
    .modal.set-user-trucks .modal-content{height: 80vh; margin-top: -40vh; display: flex; flex-direction: column; justify-content: space-between }
    .modal.set-user-trucks .modal-content .header{display: flex; flex-direction: column; align-items: center; justify-content: center;}
    .modal.set-user-trucks .modal-content .header .title{padding: 10px;}
    .modal.set-user-trucks .modal-content .header .description{font-size: 14px; font-weight: 500;}
    .modal.set-user-trucks .modal-content .header .search-container{ position: relative; display: flex; justify-content: center; align-items: center; width: 100%; margin-top: 20px; }
    .modal.set-user-trucks .modal-content .header .search-container input{ position: relative; width: 80%;  height: 40px; background: #FFF; border-radius: 8px; padding: 0 20px; padding-right: 55px; }
    .modal.set-user-trucks .modal-content .header .search-container .icon-search-bar{ position: absolute; top: 8px; right: 12%; color: #C9CED6; font-size: 20px; }
    .modal.set-user-trucks .modal-content .body .trucks-list{  }
    .modal.set-user-trucks .modal-content .body .trucks-summary {display: flex; justify-content: space-between; align-items: center; width: 100%; padding: 0 20px 10px; margin-top: 20px;}
    .modal.set-user-trucks .modal-content .body .trucks-summary a{ cursor: pointer; color: var(--color-gray-dark); }
    .modal.set-user-trucks .modal-content .body .truck-default ul {  }
    .modal.set-user-trucks .modal-content .body .truck-default .no-default-truck ul li.item { color: var(--color-gray); font-weight: 400; }
    .modal.set-user-trucks .modal-content .body .truck-default .no-default-truck ul li.item:hover { background-color: #FFF; }
    .modal.set-user-trucks .modal-content .body .truck-default ul li.item{ width: 100%; position: relative; padding: 20px 20px 20px 30px; border-bottom: 1px solid #9FA2B455; display: flex; flex-direction: row; justify-content: space-between; align-items: center; transition: all 0.3s ease; font-weight: 500;}
    .modal.set-user-trucks .modal-content .body .truck-default ul li.item i.icon-medal{ color: var(--color-default) }
    .modal.set-user-trucks .modal-content .body .truck-default ul li.item:hover{ background-color: #EBF1F7 }
    .modal.set-user-trucks .modal-content .body .trucks-list ul { height: calc(100vh - 560px); min-height: 150px; overflow-y: overlay }
    .modal.set-user-trucks .modal-content .body .trucks-list ul li.item{ width: 100%; position: relative; padding: 20px 20px 20px 30px; border-bottom: 1px solid #9FA2B455; display: flex; flex-direction: row; justify-content: space-between; align-items: center; transition: all 0.3s ease; font-weight: 500}
    .modal.set-user-trucks .modal-content .body .trucks-list ul li.item a.menu{ color: var(--color-gray); cursor: pointer; transition: all 0.3s ease }
    .modal.set-user-trucks .modal-content .body .trucks-list ul li.item a.menu:hover{ color: var(--color-black); }
    .modal.set-user-trucks .modal-content .body .trucks-list ul li.item:hover{ background-color: #EBF1F7 }
    .modal.set-user-trucks .modal-content .buttons {margin-top: 30px; display: flex; flex-direction: row; justify-content: space-between; align-items: center; width: 100%; padding: 0 20px;}
    .modal.set-user-trucks .modal-content .buttons a.add-truck {font-size: 13px; cursor: pointer;}
    .modal.set-user-trucks .modal-content .buttons a.add-truck:hover {text-decoration: underline;}
    .modal.set-user-trucks .modal-content .buttons .btn{ padding: 5px 24px;}
    .modal.set-user-trucks .modal-content .buttons .btn:last-of-type{ margin-left: 10px;}


    /* ----------------- POPUPS ----------------- */
    /* Popup Add Phone Number */
    .modal.add-phone-number {}
    .modal.add-phone-number .modal-content {display: flex; width: 560px; height: 400px; padding: 0 !important; overflow: hidden; flex-direction: column; justify-content: space-between; align-items: center; background: linear-gradient(154.51deg, #FEC357 -4.44%, #D85927 28.85%, #CF4727 50.05%);}
    .modal.add-phone-number .modal-content .icon-close {color: #FFF}
    .modal.add-phone-number .modal-content .modal-header {padding: 0 40px; display: flex; align-items: center; justify-content: center; height: 140px;}
    .modal.add-phone-number .modal-content .modal-body {border-top-left-radius: 50px; width: 100%; height: 260px; padding: 40px; background: url("../img/bg-popup-1.png") bottom / contain no-repeat #fff; background-size: cover; display: flex; flex-direction: column; justify-content: space-between;}
    .modal.add-phone-number .modal-content .modal-body .text {text-align: center; font-size: 17px; margin-bottom: 20px;}
    .modal.add-phone-number .modal-content .modal-body input {}
    .modal.add-phone-number .modal-content .footer {width: 100%; display: flex; flex-direction: row; justify-content: flex-end; }
    .modal.add-phone-number .modal-content .footer button {}

    /* Generic popup acknowledge */
    .modal.acknowledge {display: flex; flex-direction: column; justify-content: center; align-items: center;}
    .modal.acknowledge .modal-content {display: flex; width: 560px; height: auto; min-height: 300px; padding: 0 !important; overflow: hidden; flex-direction: column; justify-content: space-between; align-items: center; left: auto; top: auto; margin-left: auto; margin-top: auto;}
    .modal.acknowledge .modal-content .icon-close {}
    .modal.acknowledge .modal-content .modal-header {padding: 0 40px; display: flex; align-items: center; justify-content: center; height: 140px;}
    .modal.acknowledge .modal-content .modal-header h1{font-size: 30px;}
    .modal.acknowledge .modal-content .modal-body { width: 100%; padding: 0 40px 40px; display: flex; flex-direction: column; justify-content: space-between;}
    .modal.acknowledge .modal-content .modal-body .text {text-align: center; font-size: 17px; margin-bottom: 40px; line-height: 23px;}
    .modal.acknowledge .modal-content .modal-body input {}
    .modal.acknowledge .modal-content .footer {width: 100%; display: flex; flex-direction: row; justify-content: center; }
    .modal.acknowledge .modal-content .footer button {}


    /* External users modal */
    .modal.external-users { display: flex; align-items: center; justify-content: center; position: fixed; }
    .modal.external-users .modal-content-external-users { background: #FFF; width: 90%; max-height: 80%; border-radius: 10px; padding: 30px 0; position: relative}
    .modal.external-users .modal-content-external-users h1 { padding: 20px; color: var(--black1, #313A52); font-size: 30px; font-style: normal; font-weight: 600; line-height: normal; letter-spacing: 0.4px; }
    .modal.external-users .modal-content-external-users .number-cell { text-align: center;}
    .modal.external-users .modal-content-external-users .table-wrapper { max-height: 620px; height: calc(70vh - 150px); overflow: hidden;}
    .modal.external-users .modal-content-external-users table tbody td span { color: #cf4727;}
    .modal.external-users .modal-content-external-users table tbody td span:hover { cursor: pointer;}

    /* CSV uploader modal */
    .modal .modal-content { height: fit-content;}
    .modal .modal-content .modal-title-csv { display: block; text-align: center; color: #000; font-size: 24px; font-weight: 600; height: 80px;}
    .modal .modal-content .modal-description-csv { display: block; text-align: center; color: #737582; text-align: center; font-size: 14px; font-style: normal; font-weight: 400; line-height: 18.5px; letter-spacing: 0.2px; }
    .modal .modal-content .type-selection { display: flex; flex-direction: row; padding: 25px; gap: 10px; }
    .modal .modal-content .type-selection .type { display: flex; padding: 8px; align-items: center; gap: 8px; flex: 1 0 0; border-radius: 8px; border: 2px solid var(--Disable, #DEE1E6);  }
    .modal .modal-content .type-selection .type:hover { cursor: pointer; }
    .modal .modal-content .type-selection .type.selected {border: 2px solid var(--Secondary, #4284F5);}
    .modal .modal-content .type-selection .type div { border-radius: 6px; background: #EBF1F7; display: flex; height: 42px; padding: 7.2px; justify-content: center; align-items: center; gap: 6px;  }
    .modal .modal-content .type-selection .type h2 { color: #313A52; text-align: center; font-size: 14px; font-style: normal; font-weight: 600; line-height: 24px; }
    .modal .modal-content .btn-next { margin: 0px 25px; width: 88% !important; }

    .modal .modal-content .csv-template { color: #9FA2B4; font-size: 14px; font-style: normal; font-weight: 700; line-height: 140%; /* 19.6px */ letter-spacing: 0.266px; padding: 30px; }
    .modal .modal-content .csv-template .csv-template-info { display: flex; flex-direction: row; justify-content: space-between; border-radius: 4px; background: var(--Background-selected, #EBF1F7); height: 80px; padding: 8px 12px; align-items: center; align-self: stretch; margin-top: 10px;}
    .modal .modal-content .csv-template .csv-template-info p {  color: #C64227; text-align: center; font-size: 16px; font-style: normal; font-weight: 600; line-height: 140%; letter-spacing: 0.304px; }
    .modal .modal-content .csv-template .csv-template-info p:hover{ cursor: pointer; }
    .modal .modal-content .csv-template .csv-template-info .icon-name { display: flex; flex-direction: row; gap: 5px; align-items: center;  }
    .modal .modal-content .csv-template .csv-template-info .icon-name p { color: var(--Tertiary-Blue, #363740); font-size: 14px; font-style: normal; font-weight: 400; line-height: normal; letter-spacing: 0.2px;  }
    .modal .modal-content .csv-template .csv-template-info .icon-name i { font-size: 14px; flex-shrink: 0;  }
    .modal .modal-content .csv-upload-file p { color: #9FA2B4; font-size: 14px; font-style: normal; font-weight: 700; line-height: 140%; /* 19.6px */ letter-spacing: 0.266px; padding: 0px 30px; }
    .modal .modal-content .csv-upload-file .file-uploader { width: 85%; height: 80px; border-radius: 4px; border: 1px dashed #C4C4C4; border-radius: 4px; border: 1px dashed #C4C4C4; padding: 25px; margin-left: 30px; margin-top: 5px;}
    .modal .modal-content .csv-upload-file .file-uploader.error {border: 1px dashed #E8592B; background-color: #E8592B14;}
    .modal .modal-content .csv-upload-file .file-uploader .file-input { border-radius: 8px; border: 1px solid #9FA2B4; width: 100%; height: 30px; flex-shrink: 0; justify-content: center; display: flex; justify-content: center; align-items: center; color: #9FA2B4; text-align: center; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; letter-spacing: 0.2px;}
    .modal .modal-content .csv-upload-file .file-uploader .file-input.error { color: #E8592B; border: 1px solid #E8592B; }
    .modal .modal-content .csv-upload-file .file-uploader .file-input.error p { color: #E8592B; }
    .modal .modal-content .csv-upload-file .file-uploader .file-input .csv-info { color: #cf4727; text-align: center; font-size: 14px; font-style: normal; font-weight: 600; line-height: 20px; letter-spacing: 0.2px;}
    .modal .modal-content .csv-upload-file .file-uploader .file-input:hover { cursor: pointer;}
    .modal .modal-content .csv-upload-file .file-uploader .file-input .csv-uploader { display: none; }
    .modal .modal-content .csv-upload-file .error-section { display: flex; flex-direction: row; justify-content: flex-start; padding: 5px 30px; gap: 5px; align-items: flex-start; }
    .modal .modal-content .csv-upload-file .error-info p { color: var(--Error, #E8592B) !important; font-size: 12px !important; font-style: normal !important; font-weight: 500 !important; line-height: 140% !important; letter-spacing: 0.266px !important; padding: 2px; }

    .modal .modal-content .buttons { display: flex; flex-direction: row; gap: 10px; justify-content: center;padding: 20px; }
    .modal .modal-content .buttons button { width: 100%; }
    .modal .modal-content .buttons .btn-white { font-size: 14px !important; }




