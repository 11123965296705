.chat-container{display: flex; flex-direction: row;}

.chat-container .user-list-box{position: relative; width: 480px; height: 100vh; overflow: hidden; background-color: #EBF1F7; box-shadow: 5px 0 5px rgba(0, 0, 0, 0.08); z-index: 100;}

.chat-container .chat-view{position: relative; width: calc(100vw - 480px - 72px); max-width: calc(100vw - 480px - 72px); height: 100vh; display: flex; flex-direction: column; background: #FFFFFF;}


/* User List */
.chat-container .user-list-box .list-search{position: relative; height: 250px; padding: 40px; display: flex; flex-direction: column; justify-content: center; align-items: center;}
.chat-container .user-list-box .list-search .title{font-size: 30px; font-weight: 600; margin-bottom: 30px;}
.chat-container .user-list-box .list-search .search{ position: relative; width: 100%; border-radius: 8px; border: none;}
.chat-container .user-list-box .list-search .search i{position: absolute; font-size: 20px; color: #959595; text-align: center; top: 50%; right: 10px; transform: translateY(-50%);}
.chat-container .user-list-box .list-search .footer{display: flex; position: absolute; width: 100%; flex-direction: row; justify-content: space-between; bottom: 10px; padding: 0 40px; color: #1F232E80; }
.chat-container .user-list-box .list-search .footer a{color: #1F232E80; text-decoration: underline;}
.chat-container .user-list-box .list-search .footer a:hover{cursor: pointer;}

.chat-container .user-list-box .user-list{ overflow: hidden; height: calc(100vh - 250px); background: #FFFFFF;}
.chat-container .user-list-box .user-list ul {}
.chat-container .user-list-box .user-list ul li{position: relative; display: block; transition: 0.5s; display: block; width: 100%; height: 100px; background-color: #FFFFFF; align-items: center; border-bottom: 0.3px solid rgba(0, 0, 0, 0.2); padding: 0;}
.chat-container .user-list-box .user-list ul li:hover{cursor: pointer; background-color: #ebf1f759; }
.chat-container .user-list-box .user-list ul li.hide {display: none;}

.chat-container .user-card{display: block; width: 100%; height: 100%; position: relative;}
.chat-container .user-card.selected{background-color: #F3F7FA;}
.chat-container .user-card .container{position: relative; display: flex; flex-direction: row; height: 100%; align-items: center; width: 100%; padding: 0 40px;}
.chat-container .user-card .container .user{position: relative; display: flex; flex-direction: row; align-items: center;}
.chat-container .user-card .container .user .profile{position: relative; display: flex; width: 48px; height: 48px; align-items: center; justify-content: center; font-size: 24px; background: #313A5280; color: #FFFFFF; border-radius: 100%; font-weight: 600;}
.chat-container .user-card .container .user .user-data{margin-left: 10px;}
.chat-container .user-card .container .user .user-data .fullname{font-size: 18px; font-weight: 400;}
.chat-container .user-card .container .user .user-data .email{font-size: 13px; color: #00000080;}
.chat-container .user-card .container .notification{display: inline-block; position: absolute; right: 40px; width: 16px; height: 16px; background: #4284F5; border-radius: 100%;}

/* Chat View */
.chat-container .chat-view .chat-header{position: relative; height: 90px; width: 100%; display: flex; flex-direction: row; align-items: center; padding: 0 40px; background: #EBF1F7;}
.chat-view .chat-header .profile{position: relative; display: flex; width: 48px; height: 48px; align-items: center; justify-content: center; font-size: 24px; background: #313A5280; color: #FFFFFF; border-radius: 100%; font-weight: 600;}
.chat-view .chat-header .user-data{margin-left: 10px;}
.chat-view .chat-header .user-data .fullname{font-size: 18px; font-weight: 400;}
.chat-view .chat-header .user-data .email{font-size: 13px; color: #00000080;}

.chat-view .chat-body{max-height: calc(100% - 80px - 90px); overflow-y: hidden;}
.chat-view .chat-body .message-list {padding: 30px 60px;}
.chat-view .chat-body .message-date{display: flex; align-items: center; width: 100%; justify-content: center; margin-bottom: 30px;}
.chat-view .chat-body .message-date p{padding: 4px 16px; font-size: 14px; font-weight: 400; color: #FFF; background: rgba(31, 35, 46, 0.5); border-radius: 4px;}

.chat-view .chat-input-box{position: absolute; bottom: 0; width: 100%; height: 80px; display: flex; flex-direction: row; align-items: center; padding: 0 40px; background: #EBF1F7;}
.chat-view .chat-input-box form{width: 100%; display: flex; justify-content: center;}
.chat-view .chat-input-box .content{border: none; width: 80%; margin-right: 5px;}
.chat-view .chat-input-box .buttons{margin: 0 10px; display: flex; flex-direction: row; }
.chat-view .chat-input-box .buttons button{display: flex; justify-content: center; align-items: center; margin: 0 2px; color: #cf4727; background: none; border: none; font-size: 24px; border-radius: 100%; width: 40px; height: 40px;}

/* Message Card */

.message-card .message-content{position: relative; display: inline-flex; flex-direction: row; align-items: center; justify-content: flex-start; padding: 16px 32px 24px 24px; border-radius: 8px; max-width: 60%; margin-bottom: 30px; min-width: 50px;}
.message-card .message-content.only-media{padding: 4px 5px 24px 5px; justify-content: center;}
.message-card .message-content p{ word-wrap: break-word; width: 100%;  }
.message-card .message-content p.unknown{ color: #7c7e92e1; }
.message-card .message-content .bottom{position: absolute; z-index: 10; font-size: 10px; color: #7c7e92; bottom: 5px; right: 10px;}
.message-card .message-content .bottom .time{margin-right: 5px}
.message-card .message-content .bottom .check{}
.message-card .message-content .bottom span i.icon-check:first-child{position: absolute; right: 5px;}
.message-card .message-content .bottom .seen i{color: var(--color-blue)}

/* .message-card .message-content .time{position: absolute; z-index: 10; font-size: 10px; color: #7c7e92; bottom: 5px; right: 10px;} */

/* MESSAGE TYPES */
/* Favorite Folder */
.message-card .message-content .content.folder .folder{width: 100%; min-width: 200px; background: #FFFFFF; padding: 30px 20px 20px 20px; border-radius: 8px; border: 1px solid #979ca8; display: block; position: relative;}
.message-card .message-content .content.folder .folder:hover{cursor: pointer;}
.message-card .message-content .content.folder .folder .type{position: absolute; top: 6px; left: 8px; font-size: 12px; color: #75787b; font-weight: 400;}
.message-card .message-content .content.folder .folder .folder-content{display: flex; position: relative; flex-direction: row; align-items: center;}
.message-card .message-content .content.folder .folder .pins-value{ position: relative; margin-right: 20px; align-self: center;}
.message-card .message-content .content.folder .folder .pins-value .icon-pins{ color: #cf4727; font-size: 26px; }
.message-card .message-content .content.folder .folder .pins-value .value{ color: #FFF; background: #313A52; display: inline-block; width: auto; padding: 2px 7px; font-size: 10px; border-radius: 11px; position: absolute; right: -2px; top: 0; }
.message-card .message-content .content.folder .folder .name{font-weight: 500;}

/* Marker */
/* This includes Favorite Marker, Custom Marker, Pin */
.message-card .message-content .content .marker {width: 100%; min-width: 200px; background: #EBF1F7; padding: 25px 10px 15px; border-radius: 6px; border: 1px solid #FFFFFF; display: block; position: relative;}
.message-card .message-content .content .marker:hover {cursor: pointer; }
.message-card .message-content .content .marker .type{position: absolute; top: 6px; left: 8px; font-size: 12px; color: #75787b; font-weight: 400;}
.message-card .message-content .content .marker .pin-value {display: flex; flex-direction: row; justify-content: flex-start; align-items: top;}
.message-card .message-content .content .marker .pin-value figure{position: relative; width: 15px; min-width: 15px; height: 26px;}
.message-card .message-content .content .marker .pin-value figure img{width: 100%; height: 100%;}
.message-card .message-content .content .marker .pin-value .name{position: relative; line-height: 26px; margin-left: 10px; font-weight: 400;}

.message-card .message-content.from-me{ position: relative; background: #dae3fe; border-bottom-right-radius: 0; float: right; }
.message-card .message-content.from-me:after{content: ""; position: absolute; z-index: 0; margin-bottom: -10px; bottom: 0; right: 0; height: 20px; width: 20px; background: linear-gradient(to top right, #FFFFFF00 0%, #FFFFFF00 50%, #dae3fe 50%, #dae3fe 100%); background-attachment: local; border-bottom-left-radius: 100%;}

.message-card .message-content.from-them{ position: relative; background: #f3f3f5; border-bottom-left-radius: 0; float: left;}
.message-card .message-content.from-them:after{content: ""; position: absolute; z-index: 0; margin-bottom: -10px; bottom: 0; left: 0; height: 20px; width: 20px; background: linear-gradient(to bottom right, #f3f3f5 0%, #f3f3f5 50%, #FFFFFF00 50%, #FFFFFF00 100%); background-attachment: local; border-bottom-right-radius: 100%;}


/* Image */

.message-card .message-content .content .image{ max-height: 300px; max-width: 500px; }
.message-card .message-content .content .image:hover{ cursor: pointer; }
.message-card .message-content .content .image figure{ max-height: 300px; overflow: hidden; border-radius: 4px; max-width: 100%;}
.message-card .message-content .content .image figure img{ max-height: 300px; height: 100%; object-fit: cover; max-width: 100%;}
.image-open{ position: fixed; z-index: 1000; width: 100vw; height: 100vh; top: 0; left: 0; background: #000000cc; display: flex; flex-direction: column; justify-content: center; align-items: center; }
.image-open figure{max-width: 60%; overflow: hidden; max-height: 80vh;}
.image-open figure:hover{cursor: pointer;}
.image-open figure img{width: 100%; height: 100%;}

/* Route */
.message-card .message-content .content .route {width: 100%; min-width: 200px; background: #EBF1F7; padding: 25px 10px 15px; border-radius: 6px; border: 1px solid #FFFFFF; display: block; position: relative;}
.message-card .message-content .content .route:hover {cursor: pointer; }
.message-card .message-content .content .route .type{position: absolute; top: 6px; left: 8px; font-size: 12px; color: #75787b; font-weight: 400;}
.message-card .message-content .content .route .pin-value {display: flex; flex-direction: row; justify-content: flex-start; margin-top: 15px;}
.message-card .message-content .content .route .pin-value figure{position: relative; width: 15px; min-width: 15px; height: 26px;}
.message-card .message-content .content .route .pin-value figure img{width: 100%; height: 100%;}
.message-card .message-content .content .route .pin-value .name{position: relative; line-height: 26px; margin-left: 10px; font-weight: 400;}

/* Custom Route */
.message-card .message-content .content .custom-route {width: 100%; min-width: 200px; background: #EBF1F7; padding: 25px 10px 15px; border-radius: 6px; border: 1px solid #FFFFFF; display: block; position: relative;}
.message-card .message-content .content .custom-route:hover {cursor: pointer; }
.message-card .message-content .content .custom-route .type{position: absolute; top: 6px; left: 8px; font-size: 12px; color: #75787b; font-weight: 400;}
.message-card .message-content .content .custom-route .info {}
.message-card .message-content .content .custom-route .info p{font-size: 13px;}
.message-card .message-content .content .custom-route .info p b{}
.message-card .message-content .content .custom-route .info ul{font-size: 13px;}
.message-card .message-content .content .custom-route .info ul li{margin-left: 5px;}
.message-card .message-content .content .custom-route .info ul li i.icon-pin-small{color: #4F97E9; opacity: 0.7;}
.message-card .message-content .content .custom-route .info ul li i.icon-doc{color: #F6CB6E; opacity: 0.7;}