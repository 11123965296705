.vehicles-list.table-container .scroll-x { overflow-x: auto; }

.vehicles-list.table-container .spacer{max-width: 3%; width: 3%;}
.vehicles-list.table-container .spacer-scroll{width: calc(3%);}
.vehicles-list.table-container .name,
.vehicles-list.table-container .transport-mode,
.vehicles-list.table-container .cargo,
.vehicles-list.table-container .gross-weight,
.vehicles-list.table-container .weight-per-axle,
.vehicles-list.table-container .created-by,
.vehicles-list.table-container .height,
.vehicles-list.table-container .length,
.vehicles-list.table-container .tunnel-category,
.vehicles-list.table-container .axle-count,
.vehicles-list.table-container .trailer-axle-count,
.vehicles-list.table-container .tires-count,
.vehicles-list.table-container .category,
.vehicles-list.table-container .trailer-count
{min-width: 150px; }
.vehicles-list.table-container .created-by.user-not-owner { color: var(--color-gray-dark); }

/* .dashboard-section.truck-detail { overflow-y: overlay;} */

.vehicles-list.table-container a {  color: #000;  transition: all .3s ease;}
.vehicles-list.table-container .table-wrapper { max-height: 45vh; }
.vehicles-list.table-container.edit-user-container .bottom-buttons{ display: block; text-align: center; margin: 0 auto; width: 220px; margin-top: 30px;}
.vehicles-list.table-container .bottom-buttons .red-button{ margin-left: 20px; }
.vehicles-list.table-container .menu a {cursor: pointer; color: var(--color-gray);}
.vehicles-list.table-container .menu a.selected {color: var(--color-gray);}
.vehicles-list.table-container .menu a:hover {color: var(--color-black);}

.edit-truck-container { padding: 30px; }
.edit-truck-container .header { padding-bottom: 30px; }

.edit-truck-container .form-truck { width: 100%; display: flex; flex-direction: row; }
.edit-truck-container .form-truck ul.truck-details {  width: 66.66%; column-count: 2; column-gap: 40px; }
.edit-truck-container .form-truck ul.truck-details li.truck-details-item { margin-bottom: 30px; break-inside: avoid-column;}
.edit-truck-container .form-truck ul.truck-details li.truck-details-item .value{ display: flex; flex-direction: row; justify-content: space-between; align-items: center; }
.edit-truck-container .form-truck ul.truck-details li.truck-details-item .value input::placeholder{color: var(--color-gray)}
.edit-truck-container .form-truck ul.truck-details li.truck-details-item .value input:-ms-input-placeholder{color: var(--color-gray)}
.edit-truck-container .form-truck ul.truck-details li.truck-details-item .value input::-ms-input-placeholder{color: var(--color-gray)}
.edit-truck-container .form-truck ul.truck-details li.truck-details-item .value .measure { font-weight: 500; color: var(--color-gray-dark); margin-left: 10px;  }

.edit-truck-container .form-truck ul.truck-details li.truck-details-item ul.items-in-row { display: flex; flex-direction: row; justify-content: space-between; }
.edit-truck-container .form-truck ul.truck-details li.truck-details-item ul.items-in-row li.truck-details-item { width: 48%; margin: 0; }
.edit-truck-container .form-truck ul.truck-details li.truck-details-item label { width: 100%; display: block; font-weight: 600; color: #9FA2B4; margin-bottom: 10px; font-size: 14px; }
.edit-truck-container .form-truck ul.truck-details .ui-multiselect {  padding: 10px 15px; }
.edit-truck-container .form-truck ul.truck-details .ui-multiselect .ui-multiselect-label {  padding: 0 !important; }

.edit-truck-container .form-truck div.third-column { width: 33.33%;}
.edit-truck-container .form-truck div.third-column .systems { width: 100%; padding: 40px; margin: 20px; border: 1px solid var(--color-gray); border-radius: 8px;}
.edit-truck-container .form-truck div.third-column .systems .item { margin-top: 10px }
.edit-truck-container .form-truck div.users { width: 100%; padding: 40px; margin: 20px; border: 1px solid var(--color-gray); height: 50vh; border-radius: 8px;}
.edit-truck-container .form-truck div.users .title { padding: 0 0 20px; }
.edit-truck-container .form-truck div.users p-listbox .ui-listbox { width: 100%; border-radius: 8px; overflow: hidden;}
.edit-truck-container .form-truck div.users p-listbox .ui-listbox .email { font-size: 12px; }
.edit-truck-container .form-truck div.users .ui-listbox-list-wrapper { max-height: 30vh; overflow-y: overlay;}