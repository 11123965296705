.card.audio-player {}
.card.audio-player .audio{}
.card.audio-player .audio .audio-container {display: flex; flex-direction: row; align-items: center; justify-content: space-between; margin-bottom: 30px;}
.card.audio-player .audio .audio-container audio{}
.card.audio-player .audio .audio-container .icon-trash{color: var(--color-default); cursor: pointer;}
.card.audio-player .record {display: flex; flex-direction: row; align-items: center; justify-content: flex-end; }
.card.audio-player .record div.mic{display: inline; justify-content: center; align-items: center; border: 2px solid var(--color-default); padding: 5px 20px; border-radius: 8px; }
.card.audio-player .record div.mic .icon-mic::before{ color: var(--color-default);}
.card.audio-player .record div.mic .icon-record::before{ color: var(--color-default); }
.card.audio-player .record div.mic.recording{animation: pulse-opacity 2s infinite;}
.card.audio-player .record div.mic.recording .icon-mic{}
.card.audio-player .speech-to-text{padding: 15px 10px; margin: 20px 5px; border: 1px solid var(--color-gray); border-radius: 8px;}
.card.audio-player .speech-to-text .text{resize: none; border: 0; padding: 10px 10px 10px 0;}

.card.image-uploader {}
.card.image-uploader .image{display: flex; position: relative; width: 100%; flex-direction: row; align-items: center; justify-content: center;}
.card.image-uploader .image figure{height: 200px; width: 200px; border-radius: 8px; position: relative; overflow: hidden; border: 1px solid var(--color-gray);}
.card.image-uploader .image figure img{ height: 100%; object-fit: cover;}
.card.image-uploader .image .icon-trash{display:block; position: absolute; color: var(--color-default); right: 0; cursor: pointer;}
.card.image-uploader .upload{}
.card.image-uploader .upload .upload-container{}
.card.image-uploader .upload .upload-container input{display: none;}
.card.image-uploader .upload .upload-container .file-upload{}

.csv-uploader-button {display: flex; justify-content: center; align-items: center; gap: 10px; border-radius: 8px; font-size: 24px; border: none; background: none;}
.csv-uploader-button i { color: #C64227;}
.csv-uploader-button i.disabled { opacity: 0.5; }
.csv-uploader-button i.disabled:hover { cursor: not-allowed; }
