body{ font-family: "SF Display", "Lato", sans-serif;font-size: 16px; background: #f7f8fc; }
body .ui-widget{ font-family: "SF Display", "Lato", sans-serif; }

select,
textarea,
input{ font-family: "SF Display", "Lato", sans-serif; border: 1px solid #C4C4C4; border-radius: 5px; width: 100%; padding: 10px 15px; }

.clearfix:after{content: ""; display: block; clear: both; }

input:focus,
button:focus,
select:focus{ outline: none; }
button[disabled]{ opacity: 0.8; cursor: not-allowed; pointer-events: none;}
input[readonly] { cursor: not-allowed; pointer-events: none;}
input[disabled]{ opacity: 0.8 ; cursor: not-allowed; pointer-events: none;}

.hidden{ display: none !important; }
.no-opacity{ opacity: 0; }
/* Scrollbar */
*::-webkit-scrollbar { position: absolute; width: 10px; height: 15px; transition: 1s; opacity: 0; }
*:hover::-webkit-scrollbar { opacity: 1; width: 10px; height: 15px; transition: 1s;}
*::-webkit-scrollbar-track { background-color: rgba(204, 204, 204, 0.5); border-left: none; width: 10px; }
*::-webkit-scrollbar-track { background-color: rgba(204, 204, 204, 0.5); border-left: none; width: 50px; }
*::-webkit-scrollbar-thumb { transition: 2s; background-color: #CCCCCC; border-radius: 10px; border: 5px solid rgba(204, 204, 204, 0);}
*::-webkit-scrollbar-thumb:hover { background-color: #A3A3A3; -webkit-width: 20px; width: 20px;}

/*Checkboxes*/
body .ui-chkbox .ui-chkbox-box{ border: 2px solid #c4c4c4; background-color: #FFF; width: 20px; height: 20px; text-align: center; border-radius: 3px; transition: background-color 0.2s, border-color 0.2s; }
body .ui-chkbox .ui-chkbox-box.ui-state-active{ border-color: #CF4727; background-color: #CF4727; color: #FFF; }
body .ui-chkbox .ui-chkbox-box.ui-state-active:not(.ui-state-disabled):hover{ border-color: #CF4727; background-color: #CF4727; color: #FFF; }
body .ui-chkbox .ui-chkbox-box:not(.ui-state-disabled):hover{ border-color: #c4c4c4; }
body .ui-chkbox .ui-chkbox-box.ui-state-focus{ outline: 0 none; outline-offset: 0; box-shadow: none; border-color: #c4c4c4; }
body .ui-chkbox .ui-chkbox-box.ui-state-focus.ui-state-active{ border-color: #CF4727; }
body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon{ overflow: hidden; position: relative; font-size: 13px; top: 2px; }
body .ui-chkbox-label.checkbox-bold-label{font-size: 18px; font-weight: 500}

/* Radio Buttons */
body .ui-radiobutton {}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active{border-color: var(--color-default); background-color: var(--color-default);}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active:hover{border-color: var(--color-default) !important; background-color: var(--color-default) !important;}
body .ui-radiobutton .ui-radiobutton-icon.ui-clickable.pi.pi-circle-on{background-color: #cf4727; border: 1px solid #FFF; transform: scale(1.7);}
body .ui-radiobutton .ui-checkbox-label{}
body .ui-radiobutton .ui-label-active{}
body .ui-radiobutton .ui-label-focus{border-color: var(--color-default); background-color: var(--color-default);}
body .ui-radiobutton .ui-label-disabled{}

/* Tooltip */
body .one-line-tooltip { max-width: max-content; }
body .one-line-tooltip .ui-tooltip-text{ white-space: nowrap; }

/*Buttons*/
.btn{ width: auto; text-align: center; border-radius: 8px; padding: 8px 25px; text-align: center; cursor: pointer; letter-spacing: 1px; font-weight: normal; font-family: "SF Display", "Lato", sans-serif; background: #cf4727; border: 1px solid #cf4727; color: #fff; display: inline-block; font-size: 15px; transition: all 0.3s ease; }
.btn:hover{ color: #cf4727; background: transparent; }
.btn.btn-white{ border: 1px solid #9FA2B4; color: #9FA2B4; background: #FFF; }
.btn.btn-white:hover{ color: #9FA2B4; }

.btn.primary{ width: auto; text-align: center; border-radius: 8px; padding: 8px 25px; text-align: center; cursor: pointer; letter-spacing: 1px; font-weight: normal; font-family: "SF Display", "Lato", sans-serif; background: #cf4727; border: 1px solid #cf4727; color: #fff; display: inline-block; font-size: 15px; transition: all 0.3s ease; }
.btn.secondary{ width: auto; text-align: center; border-radius: 8px; padding: 8px 25px; text-align: center; cursor: pointer; letter-spacing: 1px; font-weight: normal; font-family: "SF Display", "Lato", sans-serif; background: transparent; border: 1px solid #cf4727; color: #cf4727; display: inline-block; font-size: 15px; transition: all 0.3s ease; }
.btn.secondary:hover{background: transparent; color: #cf4727; border: 1px solid #cf4727 }
.btn.primary:hover{background: #cf4727; color: #FFF; border: 1px solid #cf4727 }
.btn.tertiary{border: 1px solid #9FA2B4; color: #9FA2B4; background: #FFF;}

.red-button{ background: #CF4727; color: #FFF; border: none; display: block; font-size: 16px; font-family: "SF Display", "Lato", sans-serif; cursor: pointer; }
.add-button{ width: 100px; font-size: 13px; border-radius: 8px; padding: 10px 0; float: left; margin-top: 30px; }
.add-button i{ margin-right: 5px; }
.cursor-pointer{ cursor: pointer; }

/*Main Container*/
.main-container{ padding-left: 72px; position: absolute; width: 100%; height: 100vh; top: 0; left: 0; overflow-y: overlay; }

/*Search*/
.search_popover{ position: absolute; max-height: 400px; top: 50px; background: #fff; box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12); border-radius: 8px; left: 0; width: 100%; padding: 10px 0; z-index: 10; overflow-y: auto; }
.search_popover .list li{ padding: 10px 20px; transition: all 0.3s ease; }
.search_popover .list li .name{ display: block; color: #52575C; font-size: 16px; font-weight: 600; transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.search_popover .list li .email{ display: block; color: #A0A4A8; font-size: 14px; transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.search_popover .list li:hover{ cursor: pointer; background: #f5faff; }
.search_popover .list li:hover .name,
.search_popover .list li:hover .email{ color: #52575C; }
.search_popover .no-results{ padding: 0 20px; }

.search-container.left input {padding-left: 40px;}

/*Switch*/
.switch{ width: 60px; height: 30px; border-radius: 40px; background: #DEE1E6; display: block; cursor: pointer; position: relative; overflow: hidden; text-indent: 300px; transition: all 0.3s ease }
.switch .dot{ width: 26px; height: 26px; background: #FFF; border-radius: 100%; display: block; left: 2px; top: 2px; position: absolute; transition: all 0.3s ease; }
.switch.on{ background: #CF4727 }
.switch.on .dot{ left: 32px; }
p-inputswitch .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider{ background-color: var(--color-default) !important; }


/*Loader*/
.loader-wrap{ position: fixed; top: 0; left: 0; width: 0; height: 0; background: rgba(0,0,0,0.5); z-index: 999999; opacity: 0; transition: opacity 0.3s ease; }
.loader-wrap.show{ opacity: 1; width: 100%; height: 100%; }
.loader-wrap .loader-spinner{ display: block; position: absolute; width: 80px; height: 80px; left: 50%; top: 50%; margin-left: -40px; margin-top: -40px; }
.loader-wrap .loader-spinner div{ position: absolute; border: 4px solid #fff; opacity: 1; border-radius: 50%; animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }
.loader-wrap .loader-spinner div:nth-child(2){ animation-delay: -0.5s; }

@keyframes lds-ripple{
  0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
  }
  100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
  }
}

/*Toasts*/
body .ui-toast .ui-toast-message.ui-toast-message-error{ background-color: #bd362f; border: 0 none; color: #FFF; font-weight: normal; font-weight: normal; }
body .ui-toast .ui-toast-message{ box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); margin: 0 0 1em 0; background: rgba(255, 255, 255, 1); border-radius: 5px; border-left: 6px solid rgba(59, 131, 246, 1); padding: 16px 16px 16px 10px; width: fit-content; }
.ui-toast .ui-toast-summary{ font-weight: normal; }
.ui-toast .ui-toast-icon { position: absolute; display: inline-block; left: 18px; top: 50%; padding: 0; font-size: 22px; margin-top: -13px; }
body .ui-toast .ui-toast-message.ui-toast-message-error .ui-toast-close-icon{ color: #FFF; font-size: 10px; top: 10px; right: 10px; }
.ui-toast .ui-toast-message-text-content{ padding: 0; margin-left: 0; padding-left: 38px; padding-right: 10px; }
.ui-toast .ui-toast-detail { white-space: pre-line }
.ui-toast .ui-toast-message .ui-toast-message-content { padding: 0; display: flex; align-items: center; flex-direction: row-reverse; }
.ui-toast .ui-toast-message .ui-toast-message-content .toast-content p{ margin-left: 8px; min-width: 280px; }
.ui-toast .ui-toast-message .ui-toast-close-icon { position: relative; top: auto; right: auto; margin-left: 16px; }
/* Pins */
.icon-pin-small.red{ color: #DC555B; }
.icon-pin-small.blue{ color: #4F97E9; }
.icon-pin-small.green{ color: #83BC5F; }
.icon-pin-small.yellow{ color: #F6CB6E; }
.icon-pin-small.black{ color: #000000; }
.icon-pin-small.white{ color: #FFFFFF; }
.icon-pin-small.default{ color: #CF4727; }
.icon-pin-small.pink{ color: #C02C68; }
.icon-pin-small.orange{ color: #EF9148; }

/* Colors */
.color-red{ color: #DC555B !important; }
.color-blue{ color: #4F97E9 !important; }
.color-green{ color: #83BC5F !important; }
.color-yellow{ color: #F6CB6E !important; }
.color-black{ color: #000000 !important; }
.color-white{ color: #FFFFFF !important; }
.color-default{ color: #CF4727 !important; }
.color-pink{ color: #C02C68 !important; }
.color-orange{ color: #EF9148 !important; }
.color-gray{ color: var(--color-gray); }

/* Animations */
.plus-to-close::before {transition: all 0.3s ease; transform: rotate(45deg);}
.close-to-plus::before {transition: all 0.3s ease; transform: rotate(0);}
@keyframes pulse-opacity {
	0% {
		transform: scale(0.95);
		opacity: .5;
	}

	70% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(0.95);
		opacity: .5;
	}
}

/* Tab View */
.tab-view{display: flex; width: 100%; flex-direction: row; justify-content: space-evenly; padding: 10px 0px;}
.tab-view li{position: relative; display: block; padding: 5px 15px; color: #1f232e7F; transition: all 0.2s ease; text-align: center;}
.tab-view li span.notification-badge{display: flex; position: absolute; right: -15px; top: -10px; font-size: 10px; width: 25px; height: 25px; border-radius: 100%; background: #CF4727; color: #FFF; justify-content: center; align-items: center; transition: all 0.2s ease;}
.tab-view li:hover{border-bottom: 2px solid #CF4727; cursor: pointer;}
.tab-view li.selected{opacity: 1; border-bottom: 2px solid #CF4727; color: #1f232e}

/* Icons */
.icon-arrow.back{ cursor: pointer; font-size: 15px; transform: rotate(90deg); transition: all 0.3s ease; }
.icon-arrow.back:hover{ transform: rotate(90deg) translateY(5px); }

/* Text align */
.text-align-center { text-align: center; }
.text-align-right { text-align: right; }
.text-align-left { text-align: left; }
/* Menu PrimeNG */
.ui-menu {border-radius: 8px !important; overflow: hidden;}
.ui-menu li.ui-menuitem {border-bottom: 0.3px solid #0000004D;}
.ui-menu li.ui-menuitem:hover .ui-menuitem-link .ui-menuitem-text {color: var(--color-default) !important; }
.ui-menu li.ui-menuitem .ui-menuitem-link .ui-menuitem-text{ font-size: 16px; font-weight: 500; transition: .2s;}

/* Variables */
:root{
  --color-default: #CF4727;
  --color-red: #DC555B;
  --color-blue: #4F97E9;
  --color-green: #83BC5F;
  --color-yellow: #F6CB6E;
  --color-black: #000000;
  --color-white: #FFFFFF;
  --color-pink: #C02C68;
  --color-orange: #EF9148;
  --color-gray: #C4C4C4;
  --color-gray-light: #F5F5F5;
  --color-gray-dark: #9FA2B4;
  --color-gray-40-opacity: #EBF1F766;
}

/* Map */
#map > agm-map > div.agm-map-container-inner.sebm-google-map-container-inner > div > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div {
  overflow: visible !important;
}