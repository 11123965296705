/*Page Sidebar*/
.page-sidebar{ position: fixed; width: 480px; height: 100vh; max-height: 100vh; left: 0; top: 0; background:#FFF; z-index: 9999; box-shadow: 5px 0px 20px rgba(0, 0, 0, 0.08); transform: translateX(-600px); transition: all 0.3s; z-index: 99; }
.page-sidebar.show{ transform: translateX(72px); }

.page-sidebar header{ width: 480px; height: 109px; background: #EBF1F7; position: relative; }
.page-sidebar header h1{ line-height: 109px; text-align: center; font-style: normal; font-weight: 600; font-size: 30px; color: #313A52; min-height: 25px;}
.page-sidebar header h2{ font-weight: 600; font-size: 20px; color: #313A52;}

.page-sidebar .colors ul li{ width: 35px; height: 35px; display: flex; justify-content: center; align-items: center; border-radius: 100%; border: 2px solid #F4F4F5; margin-right: 5px; cursor: pointer; float: left; position: relative; }

    .page-sidebar .colors ul li.default{ background: #cf4727; }
    .page-sidebar .colors ul li.white{ background: #FFF; }
    .page-sidebar .colors ul li.black{ background: #000; }
    .page-sidebar .colors ul li.blue{ background: #4F97E9; }
    .page-sidebar .colors ul li.green{ background: #83BC5F; }
    .page-sidebar .colors ul li.yellow{ background: #F6CB6E; }
    .page-sidebar .colors ul li.orange{ background: #EF9148; }
    .page-sidebar .colors ul li.red{ background: #DC555B; }
    .page-sidebar .colors ul li.pink{ background: #C02C68; }

.page-sidebar .colors ul li.active:before{  content: '\e802'; font-family: 'wellsite'; display: block; font-size: 18px; color: #FFF; position: absolute; top: 7px; left: 6px; }
.page-sidebar .colors ul li.white.active:before{ color: #000; }

/*Search Section*/
.search-section{ padding-top: 240px; }
.search-section .menu{ width: 100%; padding: 0; background: #FFF; height: 40px; }
.search-section .menu li{ display: inline-block; width: 33%; height: 40px; padding: 0 15px; }
.search-section .menu li a{ text-decoration:none; cursor: pointer; font-size: 18px; color:#000; display: block; width: 100%; margin: 0 auto; text-align: center; padding-bottom: 10px; height: 40px; line-height: 40px; border-bottom: 2px solid transparent; transition: all 0.3s ease; }
.search-section .menu li a.active{ border-bottom: 2px solid #CF4727; }
.search-section .menu li a:hover{ border-bottom: 2px solid #CF4727; }

.search-section .marker-search{ width: 100%; padding: 40px; background: #EBF1F7; position: relative; height: 200px; }
.search-section .marker-search .search-container{ position: relative; }
.search-section .marker-search .search-container input{ width: 100%;  height: 40px; background: #FFF;border: none; border-radius: 8px; padding: 0 20px; padding-right: 55px; }
.search-section .marker-search .search-container .icon-search-bar{ position: absolute; top: 8px; right: 15px; color: #C9CED6; font-size: 20px; }

.search-section .marker-search .bottom-options{ width: 100%; position: absolute; bottom: 0; left: 0; padding: 10px 40px 20px; }
.search-section .marker-search .bottom-options .clear-all{ text-decoration: underline; color: #858a93; font-size: 16px; cursor: pointer; float: right; }
.search-section .marker-search .bottom-options .clear-all:hover{ text-decoration: none; }
.search-section .marker-search .bottom-options .recent{ color: #858a93; font-size: 16px; float: left; }

.search-section .search-section-header{ position: fixed; height: 240px; top: 0; width: 480px; left: 0; }

.search-section .search-results{ overflow-y: auto; height: calc(100vh - 250px); position: absolute; width: 100%; }
.search-section .search-results li.item-search{ width: 100%; height: auto; border-bottom: 0.3px solid rgb(0, 0, 0,0.1); position: relative; transition: all 0.3s; }
.search-section .search-results li.item-search:hover{ background: #EBF1F7; }
.search-section .search-results li.item-search.selected {opacity: 1; border-bottom: 2px solid #CF4727; color: #1f232e }
.search-section .search-results li.item-search .list-content { padding: 30px 110px 30px 40px;}
.search-section .search-results li.item-search .list-content .name{ display: block; color: #52575C; font-size: 16px; font-weight: 600; transition: all 0.3s ease; max-width: 300px;cursor: pointer; margin-bottom: 5px; }
.search-section .search-results li.item-search .list-content .api-num{ display: block; color: #A0A4A8; font-size: 14px;  transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.search-section .search-results li.item-search .marker-actions {top: 20px}
.search-section .search-results.address li.item-search .btn.btn-white {position: absolute; right: 20px; bottom: 10px; padding: 5px 15px; font-weight: 600; font-size: 14px; line-height: 20px; letter-spacing: 0.2px; color: #9FA2B4;}
.search-section .search-results.lat-long li.item-search .btn.btn-white {position: absolute; right: 20px; bottom: 10px; padding: 5px 15px; font-weight: 600; font-size: 14px; line-height: 20px; letter-spacing: 0.2px; color: #9FA2B4;}

.search-section .marker-search select{ border-radius: 50px; border: none; padding: 0 15px; height: 30px; line-height: 30px; cursor: pointer; max-width: 130px; font-size: 13px; }
.search-section .marker-search .ui-multiselect{ border: none; max-width: 130px; border-radius: 50px; font-family: "SF Display", "Lato", sans-serif; height: 30px; line-height: 30px; font-size: 13px; }
.search-section .marker-search .ui-multiselect .ui-multiselect-label{ height: 30px; line-height: 30px; border: none; padding: 0 15px; width: 130px; }
.search-section .marker-search .ui-multiselect .ui-multiselect-trigger{ background: none; }
.search-section .ui-multiselect:not(.ui-state-disabled).ui-state-focus{ outline: 0 none; outline-offset: 0; box-shadow: none; border-color: auto; }

.search-section .marker-search .p-multiselect{ border: none; max-width: 130px; border-radius: 50px; font-family: "SF Display", "Lato", sans-serif; height: 30px; line-height: 30px; font-size: 13px; }
.search-section .marker-search .p-multiselect .p-multiselect-label{ height: 30px; line-height: 30px; border: none; padding: 0 15px; width: 130px; }
.search-section .marker-search .p-multiselect .p-multiselect-trigger{ background: none; }
.search-section .p-multiselect:not(.ui-state-disabled).ui-state-focus{ outline: 0 none; outline-offset: 0; box-shadow: none; border-color: auto; }

.search-section .search-selects{ float: right; display: inline-block; padding-top: 15px; width: 280px;}
.search-section .search-selects .ui-multiselect{ margin-left: 20px; }
.search-section .search-selects .p-multiselect{ margin-left: 20px; }

.search-section .marker-actions .icon-dots{ top: 9px; }


/*Lat-Long*/
.search-section.lat-long{ padding-top: 260px; }
.search-section .marker-search.lat-long{ height: 260px; }
.search-section .marker-search.lat-long .search-container{ margin-bottom: 15px; }
.search-section .marker-search.lat-long .search-container .label-info{ position: absolute; top: 11px; right: 16px; color: #b3b3b3; font-size: 15px; }
.search-section .marker-search.lat-long .btn{ float: right; }
.search-section .marker-search.lat-long .search-container input{ padding-right: 95px; }
.search-section .search-results.lat-long{ height: calc(100vh - 310px); }


/*Edit/Add Favorites*/
.page-sidebar.favorite{ position: fixed; width: 480px; height: 100vh; max-height: 100vh; left: 0; top: 0; background:#FFF; z-index: 9999; box-shadow: 5px 0px 20px rgba(0, 0, 0, 0.08); transform: translateX(-600px); transition: all 0.3s; padding: 40px; }
.page-sidebar.favorite.show{ transform: translateX(0); }
.page-sidebar.favorite .location .description{ color: #737582; font-size: 14px; margin-top: 30px; margin-bottom: 10px; text-align: center; }
.page-sidebar.favorite .title{ display: block; text-align: center; font-size: 24px; margin-bottom: 50px; }
.page-sidebar.favorite input,
.page-sidebar.favorite .ui-multiselect{ width: 100%; border: 1px solid #C4C4C4; border-radius: 5px; font-family: "SF Display", "Lato", sans-serif; padding: 10px 15px; font-size: 15px; margin-bottom: 20px; }
.page-sidebar.favorite .ui-multiselect .ui-inputtext{ margin-bottom: 0; }
.page-sidebar.favorite .ui-multiselect .ui-multiselect-label{ padding: 0; }
.page-sidebar.favorite .mini-title{ color: #808080; d-weight: 600; font-size: 14px; margin-bottom: 15px; }
.page-sidebar.favorite .buttons{ text-align: center; margin-top: 150px; }
.page-sidebar.favorite .buttons .btn{ margin: 0 10px; height: 30px; line-height: 30px; width: 100px; padding: 0; }
.page-sidebar.favorite .colors{ margin-top: 20px; }
.page-sidebar.favorite .colors ul li{ width: 35px; height: 35px; border-radius: 100%; border: 2px solid #F4F4F5; margin-right: 5px; cursor: pointer; float: left; position: relative; }

    .page-sidebar.favorite .colors ul li.default{ background: #cf4727; }
    .page-sidebar.favorite .colors ul li.white{ background: #FFF; }
    .page-sidebar.favorite .colors ul li.black{ background: #000; }
    .page-sidebar.favorite .colors ul li.blue{ background: #4F97E9; }
    .page-sidebar.favorite .colors ul li.green{ background: #83BC5F; }
    .page-sidebar.favorite .colors ul li.yellow{ background: #F6CB6E; }
    .page-sidebar.favorite .colors ul li.orange{ background: #EF9148; }
    .page-sidebar.favorite .colors ul li.red{ background: #DC555B; }
    .page-sidebar.favorite .colors ul li.pink{ background: #C02C68; }

.page-sidebar.favorite .colors ul li.active:before{  content: '\e802'; font-family: 'wellsite'; display: block; font-size: 18px; color: #FFF; position: absolute; top: 7px; left: 6px; }
.page-sidebar.favorite .colors ul li.white.active:before{ color: #000; }

/*Favorites Section*/
.favorites-section{ padding-top: 260px; }
.favorites-section .favorites-section-header{ position: fixed; height: 260px; top: 0; width: 480px; left: 0; }

.favorites-section .favorite-list{ overflow-y: auto; height: 200px; position: absolute; width: 100%; top: 500px; }
.favorites-section .favorite-list.folder-list{ height: 200px; top: 260px; }
.favorites-section .favorite-list.folder-list li .list-content{ padding: 30px 80px 20px 40px }
.favorites-section .favorite-list.folder-list .pins-value{ position: relative; float: left; margin-right: 20px; top: -4px; }
.favorites-section .favorite-list.folder-list .pins-value .icon-pins{ color: #cf4727; font-size: 26px; }
.favorites-section .favorite-list.folder-list .pins-value .value{ color: #FFF; background: #313A52; display: inline-block; width: auto; padding: 2px 7px; font-size: 10px; border-radius: 11px; position: absolute; right: -2px; top: 0; }
.favorites-section .favorite-list.folder-list .list-content .name{ font-weight: 600; font-size: 18px; float: left; max-width: 260px; line-break: anywhere; }

.favorites-section .favorite-list.folder-list{ height: 80px; top: 260px; }
.favorites-section .favorite-list.folder-list.two{ height: 160px; top: 260px; }
.favorites-section .favorite-list.folder-list.three{ height: 240px; top: 260px; }

.favorites-section .separator { position: absolute; top: 340px; background: #EBF1F7; height: 60px; width: 100%; }
.favorites-section .separator.two { position: absolute; top: 420px; background: #EBF1F7; height: 60px; width: 100%; }
.favorites-section .separator.three { position: absolute; top: 500px; background: #EBF1F7; height: 60px; width: 100%; }

.favorites-section .favorite-list{ height: calc(100vh - 400px); top: 400px; }
.favorites-section .favorite-list.zero{ height: calc(100vh - 260px); top: 260px; }
.favorites-section .favorite-list.two{ height: calc(100vh - 480px); top: 480px; }
.favorites-section .favorite-list.three{ height: calc(100vh - 560px); top: 560px; }

.favorites-section .icon-arrow{ position: absolute; left: 20px; top: 10px; cursor: pointer; font-size: 15px; transform: rotate(90deg); top: 48px; transition: all 0.3s ease; }
.favorites-section .icon-arrow:hover{ left: 15px; }
.favorites-section .marker-search{ padding: 0 30px; }
.favorites-section .marker-search input{ border: none; }
.favorites-section .search-container{ position: relative; margin-bottom: 10px; }
.favorites-section .search-container .icon-search-bar{ color: #C9CED6; font-size: 18px; position: absolute; right: 8px; top: 9px; }
.favorites-section .search-selects a{ color: #8f9197; cursor: pointer; text-decoration: underline; display: inline-block; padding: 5px 0 0; margin-right: 30px; margin-bottom: 10px; }
.favorites-section .search-selects select{ height: 30px; line-height: 30px; border: none; padding: 0 15px; width: 130px; float: right; border-radius: 50px; font-size: 13px; cursor: pointer; }
.favorites-section .search-selects .buttons{ float: left; }

.favorites-section .bottom-options { margin-top: 30px; }
.favorites-section .bottom-options .label{ color: #8f9197; cursor: pointer; display: inline-block; margin-bottom: 10px; float: left; }
.favorites-section .bottom-options .add-folder{ color: #8f9197; cursor: pointer; text-decoration: underline; display: inline-block; margin-bottom: 10px; float: right; padding-right: 5px; }

.favorites-section .favorite-list li{ width: 100%; height: auto; border-bottom: 0.3px solid rgb(0, 0, 0,0.1); position: relative; transition: all 0.3s ease; }
.favorites-section .favorite-list.folder-list li.folder-item {min-height: 80px;}
.favorites-section .favorite-list li:hover{ background: #EBF1F7;}
.favorites-section .favorite-list li .list-content{ padding: 30px 110px 30px 40px; }
.favorites-section .favorite-list li .list-content .favorite-description {padding-left: 30px;}
.favorites-section .favorite-list li .list-content .name{ width: 290px; float: left; color: #52575C; font-size: 16px; font-weight: 600; transition: all 0.3s ease; max-width: 320px; cursor: pointer; }
.favorites-section .favorite-list li .list-content .api-num{ color: #A0A4A8; font-size: 14px;  transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.favorites-section .favorite-list li .icon-share{ font-size: 24px; position: relative; top: 6px; }
.favorites-section .favorite-list li figure {float: left; margin-right: 13px; }
.favorites-section .favorite-list li figure img {width: 15px; height: 26px;}
.favorites-section .favorite-list li .marker-actions{ top: 20px; }


.favorites-section .favorite-list.folder-list li.folder-item:last-child .marker-actions .menu-popup.show{ top: -20px; right: 30px; }

/*Pins*/
.pins-section{ overflow-y: auto; height: auto; }
.pins-section .pins-section-header{ height: 260px; position: fixed; top: 0; width: 480px; left: 0; }
.pins-section .pins-section-header .icon-arrow{ position: absolute; left: 20px; top: 10px; cursor: pointer; font-size: 15px; transform: rotate(90deg); top: 48px; transition: all 0.3s ease; }
.pins-section .pins-section-header .icon-arrow:hover{ left: 15px; }
.pins-section .pins-section-header .marker-search{ padding: 0 30px; }
.pins-section .pins-section-header .marker-search input{ border: none; }
.pins-section .pins-section-header .marker-search .search-container{ position: relative; margin-bottom: 10px; }
.pins-section .pins-section-header .marker-search .search-container .icon-search-bar{ color: #C9CED6; font-size: 18px; position: absolute; right: 8px; top: 9px; }
.pins-section .pins-section-header .search-selects a{ color: #8f9197; cursor: pointer; text-decoration: underline; display: inline-block; padding: 5px 0 0; margin-right: 30px; margin-bottom: 10px; }
.pins-section .pins-section-header .search-selects select{ height: 30px; line-height: 30px; border: none; padding: 0 15px; width: 130px; float: right; border-radius: 50px; font-size: 13px; cursor: pointer; }
.pins-section .pins-section-header .search-selects .buttons{ float: left; }
.pins-section .pins-section-header .bottom-options { margin-top: 30px; }
.pins-section .pins-section-header .bottom-options .label{ color: #8f9197; cursor: pointer; display: inline-block; margin-bottom: 10px; float: left; }
.pins-section .pins-section-header .bottom-options .add-folder{ color: #8f9197; cursor: pointer; text-decoration: underline; display: inline-block; margin-bottom: 10px; float: right; padding-right: 5px; }

.pins-section .pin-list{ overflow-y: auto; height: 200px; position: absolute; width: 100%; top: 500px; }
/* scrollbar */
@media screen and (-webkit-min-device-pixel-ratio:0) {
    /* .pins-section .pin-list::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    .pins-section .pin-list::-webkit-scrollbar-track {
        background: #fff;
    }
    .pins-section .pin-list::-webkit-scrollbar-thumb {
        background: #C9CED6;
        border-radius: 10px;
    }
    .pins-section .pin-list::-webkit-scrollbar-thumb:hover {
        background: #A0A4A8;
    } */
    .pins-section .pin-list{overflow-y: overlay;}
}
.pins-section .pin-list::-webkit-scrollbar { width: 0; height: 15px; }
.pins-section .pin-list:hover::-webkit-scrollbar { width: 10px; height: 15px; transition: 1s;}
.pins-section .pin-list::-webkit-scrollbar-track { background-color: rgba(204, 204, 204, 0.5); border-left: none; width: 10px; }
.pins-section .pin-list::-webkit-scrollbar-track { background-color: rgba(204, 204, 204, 0.5); border-left: none; width: 50px; }
.pins-section .pin-list::-webkit-scrollbar-thumb { background-color: #CCCCCC; border-radius: 10px; border: 5px solid rgba(204, 204, 204, 0);}
.pins-section .pin-list::-webkit-scrollbar-thumb:hover { background-color: #A3A3A3; -webkit-width: 20px; width: 20px;}
/*  */
.pins-section .pin-list.folder-list{ height: 200px; top: 260px; }
.pins-section .pin-list.folder-list li .list-content{ padding: 30px 80px 18px 40px }
.pins-section .pin-list.folder-list .pins-value{ position: relative; float: left; margin-right: 20px; top: -4px; }
.pins-section .pin-list.folder-list .pins-value .icon-pins{ color: #cf4727; font-size: 26px; }
.pins-section .pin-list.folder-list .pins-value .value{ color: #FFF; background: #313A52; display: inline-block; width: auto; padding: 2px 7px; font-size: 10px; border-radius: 11px; position: absolute; right: -2px; top: 0; }
.pins-section .pin-list.folder-list .list-content .name{ font-weight: 600; font-size: 18px; float: left; max-width: 260px; line-break: anywhere; }

.pins-section .pin-list.folder-list{ height: 80px; top: 260px; min-height: 80px; }
.pins-section .pin-list.folder-list.two, .pins-section .pin-list.folder-list.three{ height: 160px; top: 260px; }
/* .pins-section .pin-list.folder-list.three{ height: 240px; top: 200px; } */

.pins-section .separator { position: absolute; top: 340px; background: #EBF1F7; height: 60px; width: 100%; }
.pins-section .separator.two, .pins-section .separator.three { position: absolute; top: 420px; background: #EBF1F7; height: 60px; width: 100%; }
/* .pins-section .separator.three { position: absolute; top: 440px; background: #EBF1F7; height: 60px; width: 100%; } */

.pins-section .pin-list{ height: calc(100vh - 500px); top: 400px; min-height: 300px; }
.pins-section .pin-list.zero{ height: calc(100vh - 360px); top: 260px; }
.pins-section .pin-list.two, .pins-section .pin-list.three{ height: calc(100vh - 600px); top: 480px; }
/* .pins-section .pin-list.three{ height: calc(100vh - 560px); top: 560px; } */


/* .pins-section .pin-list{ overflow-y: auto;  position: relative; width: 100%; height: calc(100vh - 200px); top: 200px; padding-bottom: 100px; } */
.pins-section .pin-list li{ width: 100%; height: auto; border-bottom: 0.3px solid rgb(0, 0, 0,0.1); position: relative; transition: all 0.3s ease; }
.pins-section .pin-list li:hover{ background: #EBF1F7;}
.pins-section .pin-list li .marker-actions{ top: 20px; }
.pins-section .pin-list li .list-content{ padding: 30px 110px 30px 70px; }
.pins-section .pin-list li .list-content i.icon-hamburger{ float: left; position: absolute; font-size: 25px; left: 12px; top: calc(50% - 10px);  color:rgb(0, 0, 0,0.2) }
.pins-section .pin-list li .list-content .title{ display: flex; flex-direction: row; }
.pins-section .pin-list li .list-content .name{ width: 100%; float: left; display: block; color: #52575C; font-size: 16px; font-weight: 600; transition: all 0.3s ease; max-width: 320px; cursor: pointer; ine-height: 1.3; margin-bottom: 10px;}
.pins-section .pin-list li .list-content .info{ color: #737582; }


.pins-section .btn-route-location{ float: right; margin-bottom: 30px; margin-right: 20px; }
.pins-section .add-pin-wrap{ position: absolute; bottom: 30px; left: 50%; margin-left: -75px; }

.pins-section .marker-actions .icon-dots{ top: 2px; }
.pins-section .marker-actions .icon-outline-pin{ top: 2px; }
.pins-section .marker-actions li:hover{ background: none; }

.pins-section .pin-list li{ width: 100%; height: auto; border-bottom: 0.3px solid rgb(0, 0, 0,0.1); position: relative; transition: all 0.3s ease; }
.pins-section .pin-list.folder-list li.folder-item {min-height: 80px;}
.pins-section .pin-list li:hover{ background: #EBF1F7;}
.pins-section .pin-list li .list-content .favorite-description {padding-left: 30px;}
.pins-section .pin-list li .list-content .name{ width: 290px; float: left; color: #52575C; font-size: 16px; font-weight: 600; transition: all 0.3s ease; max-width: 320px; cursor: pointer; line-break: anywhere; }
.pins-section .pin-list li .list-content .api-num{ color: #A0A4A8; font-size: 14px;  transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.pins-section .pin-list li .icon-share{ font-size: 24px; position: relative; top: 6px; }
.pins-section .pin-list li figure {float: left; margin-right: 13px; }
.pins-section .pin-list li figure img {width: 15px; height: 26px;}
.pins-section .pin-list li .marker-actions{ top: 20px; }
div.cdk-drag-preview.pins-section.drag-markers {display: flex; flex-direction: row; align-items: center; justify-content: left; padding: 15px 40px}
div.cdk-drag-preview.pins-section.drag-markers figure {margin-right: 20px;}
/* div.cdk-drag-preview.pins-section.drag-markers p {display: inline-block;} */
/* div.cdk-drag-preview {padding: 15px 40px 15px 80px; box-sizing: border-box; background-color: #0000001e; list-style-type: none; border-radius: 8px; display: block; position: relative; width: 450px;}
li.stop.cdk-drag-placeholder {visibility: hidden;}
div.cdk-drag-preview .point {visibility: hidden;}
div.cdk-drag-preview input{width: 100%;}
div.cdk-drag-preview i{visibility: hidden;}
ul.cdk-drop-list-dragging .stop:not(.cdk-drag-placeholder) {transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);}
ul.cdk-drop-list-dragging .stop .point::after{visibility: hidden;}
.cdk-drag-animating { transition: transform 250ms cubic-bezier(0, 0, 0.2, 1); } */


/*User Tracking*/
.user-tracking{ padding-top: 220px; }
.user-tracking .favorites-section-header{ height: 220px; }
.user-tracking .user-list{ overflow-y: auto; height: calc(100vh - 220px); position: absolute; width: 100%; top: 220px; }
.user-tracking .user-list .name{ display: block; color: #52575C; font-size: 16px; font-weight: 600; transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; line-height: 1.3; }
.user-tracking .user-list .email{ display: block; color: #A0A4A8; font-size: 14px; transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; line-height: 1.3; }
.user-tracking .user-list li{ padding: 20px 30px; position: relative; border-bottom: 1px solid #d9d9d9; }
.user-tracking .user-list li a{ position: absolute; right: 30px; top: 50%; margin-top: -5px; display: block; }
.user-tracking .user-list li .ui-chkbox-box{ top: -4px; position: relative; }
.user-tracking .user-list li .initials{ width: 40px; height: 40px; display: block; float: left; background: #797f8d; color: #FFF; border-radius: 100%; text-align: center; margin-right: 10px; font-size: 20px; font-weight: 500; line-height: 40px; }
.user-tracking .user-list li .user-info{ padding-right: 30px; padding-top: 2px; }
.user-tracking .bottom-options { margin-top: 30px; display: flex; flex-direction: row; justify-content: space-between; align-items: center; }
.user-tracking .bottom-options .tab-view { padding: 0; width: auto;  }
.user-tracking .bottom-options .label{ color: #8f9197; cursor: pointer; display: inline-block; margin-bottom: 10px; float: left; }
.user-tracking .bottom-options .select-all{ color: #8f9197; cursor: pointer; text-decoration: underline; display: inline-block; float: right; padding-right: 5px; }


/* Multi Routing */
.routing-section{ padding-top: 240px; position: relative;  height: 100vh;}
.routing-section .routing-section-header{ position: absolute; height: 235px; top: 0; width: 480px; left: 0; }
.routing-section .marker-search{ padding: 0 30px; }
.routing-section .marker-search input{ border: none; }
.routing-section .search-container{ position: relative; margin-bottom: 10px; padding: 10px 20px;}
.routing-section .date-filter{ display: flex; flex-direction: row; justify-content: space-between; align-items: center; width: 100%;}
.routing-section .date-filter .ui-dropdown{ border: none; }
.routing-section .date-filter ui-inputtext { color: #333333; }
.routing-section .date-filter .ui-dropdown:not(.ui-state-disabled).ui-state-focus{ box-shadow: none; }
.routing-section .date-filter .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight {background-color: #CF4727;}
.routing-section .date-filter .ui-dropdown .ui-dropdown-trigger { background-color: #CF4727; }
.routing-section .date-filter .ui-dropdown .ui-dropdown-trigger .pi-chevron-down{ color: white; }

.routing-section .date-filter .ui-datepicker table td > a.ui-state-active, body .ui-datepicker table td > span.ui-state-active { background-color: #CF4727; }
.routing-section .date-filter ui-datepicker { z-index: 99999 !important; }
.routing-section .date-filter .ui-calendar{ display: flex; }
.routing-section .date-filter p-calendar{ width: 50%; padding: 10px; }
.routing-section .date-filter p-calendar button{ background-color: #CF4727; border: none; }
.routing-section .date-filter p-calendar button:hover{ cursor: default; background-color: #CF4727;}
.routing-section .date-filter p-calendar input:focus{ box-shadow: none !important;}
.routing-section .search-container .icon-search-bar{ color: #C9CED6; font-size: 18px; position: absolute; left: 3px; top: -10px; padding: 30px;}
.routing-section .content { width: 100%; display: block; height: calc(100vh - 360px); overflow: hidden;}

.routing-section .content .routes{ width: 100%; display: flex; height: calc(100vh - 415px)}
.routing-section .content .routes ul.routes-list{ width: 100%; display: flex; height: 100%; flex-direction: column;}
.routing-section .content .routes ul.routes-list li.route-item{ position: relative; width: 100%; display: flex; min-height: 200px; padding: 30px 26px; border-bottom: 0.3px solid #00000031; flex-direction: column;}
.routing-section .content .routes ul.routes-list li.route-item.not-reviewed{ background: #F9F9F9; }
.routing-section .content .routes ul.routes-list li.route-item div.body{ position: relative }
.routing-section .content .routes ul.routes-list li.route-item div.body span.not-reviewed{ display: flex; font-size: small; color: #737582; flex-direction: row; justify-content: flex-start; align-items: center; position: absolute; top: -20px; left: 0px; }
.routing-section .content .routes ul.routes-list li.route-item div.body span.not-reviewed .small-circle{ display: block; width: 8px; height: 8px; border-radius: 100%; background: var(--color-default); margin-right: 5px; }
.routing-section .content .routes ul.routes-list li.route-item div.body .title{ margin-bottom: 20px; line-break: anywhere; width: 70%; font-size: 19px;}
.routing-section .content .routes ul.routes-list li.route-item div.body .creator{ color: #737582; display: flex; flex-direction: column; font-size: smaller; padding-bottom: 10px;}
.routing-section .content .routes ul.routes-list li.route-item div.body .record-route-link{ color: #737582; display: flex; flex-direction: column; font-size: smaller; padding-bottom: 10px;}
.routing-section .content .routes ul.routes-list li.route-item div.body .record-route-link .url{ color: #737582; font-size: 14px; padding-bottom: 10px; text-decoration: underline;}
.routing-section .content .routes ul.routes-list li.route-item div.body .creator .destination { display: flex; flex-direction: row;}
.routing-section .content .routes ul.routes-list li.route-item div.body .creator b{ }
.routing-section .content .routes ul.routes-list li.route-item div.body .creator span{ margin-left: 2px; }
.routing-section .content .routes ul.routes-list li.route-item div.body .creator .name{ }
.routing-section .content .routes ul.routes-list li.route-item div.body .creator .email{ }
.routing-section .content .routes ul.routes-list li.route-item div.body .description{ display: block; max-width: fit-content; padding-bottom: 20px; }
.routing-section .content .routes ul.routes-list li.route-item div.body .url{ display: block; max-width: fit-content; color: #000; line-break: anywhere;}
.routing-section .content .routes ul.routes-list li.route-item div.body .url:hover{ cursor: pointer;}
.routing-section .content .routes ul.routes-list li.route-item div.body div.route-url p.title{ font-weight: bold; margin-top: 5px; }
.routing-section .content .routes ul.routes-list li.route-item div.body div.route-url .url{ color: #cf4727; opacity: 0.8; transition: 0.2s; text-decoration: underline; cursor: pointer;}
.routing-section .content .routes ul.routes-list li.route-item div.body div.route-url .url:hover{ opacity: 1; }
.routing-section .content .routes ul.routes-list li.route-item div.bottom-options{ width: 100%; display: flex; justify-content: space-between; align-self: center; margin-top: 20px; padding: 0 20px 0 0;}
.routing-section .content .routes ul.routes-list li.route-item div.bottom-options .tag { display: flex; align-items: center; justify-content: center;}
.routing-section .content .routes ul.routes-list li.route-item div.bottom-options .tag .hazmat{ color: var(--color-default); padding: 6px 10px; border-radius: 16px; background: rgba(207, 71, 39, 0.2); white-space: nowrap; }
.routing-section .content .routes ul.routes-list li.route-item div.bottom-options .buttons { display: flex; flex-direction: row; }
.routing-section .content .routes ul.routes-list li.route-item div.bottom-options .buttons button{ margin-left: 20px; white-space: nowrap; }
.routing-section .content .routes ul.routes-list li.route-item div.body div.return { padding: 20px; border-radius: 13px; background-color: var(--color-gray-40-opacity); margin: 15px auto; }
.routing-section .content .routes ul.routes-list li.route-item div.body div.return .menu{ position: relative; }
.routing-section .content .routes ul.routes-list li.route-item div.body div.return .menu i.icon-dots{ position: absolute; right: 20px; }
.routing-section .content .routes ul.routes-list li.route-item div.body div.return p.route-type{  margin-bottom: 5px; font-weight: bold; font-size: 13px;}
.routing-section .content .routes ul.routes-list li.route-item div.body div.return a.url { color: var(--color-default); opacity: 0.8; transition: 0.2s; text-decoration: underline; cursor: pointer; }
.routing-section .content .routes ul.routes-list li.route-item div.body div.return a.url:hover { opacity: 1; }

.routing-section .content .empty{ width: 100%; display: flex; justify-content: center; height: 100%;}
.routing-section .content .empty p{ display: inline-block; margin-top: 80px; font-size: 18px; color: #000000; opacity: 0.5; width: 300px; text-align: center;}
.routing-section .bottom { width: 100%; display: flex; position: absolute; bottom: 0; left: 0; height: 100px; justify-content: center; align-items: center; padding: 0 40px; box-shadow: -3px -3px 4px 2px #0000000D;}
.routing-section .bottom button.add-button{margin: 0; flex-grow: 1; font-weight: 600; height: 50px;}
.routing-section .bottom button.add-button i{font-size: 12px}

.routing-section.add-route {position: fixed; z-index: 2; top: 0; left: 0; height: 100vh; padding-top: 0; width: 480px; background-color: #FFFFFF;}
.routing-section.add-route i.icon-close{position: absolute; top: 20px; right: 20px; color: #000000; cursor: pointer;}
.routing-section.add-route .content{display: flex; flex-direction: column; align-items: center; width: 100%; height: 100%;}
.routing-section.add-route .content .title{text-align: center;}
.routing-section.add-route .content .title h1{font-size: 24px; font-weight: 500;}
.routing-section.add-route .content .title p{margin-top: 20px; opacity: .5; font-size: 18px; }
.routing-section.add-route .content form{margin-top: 60px;}

.routing-section.add-route .content form label{font-weight: 700; margin-bottom: 4px; display: inline-block;}
.routing-section.add-route .content form input:first-of-type{margin-bottom: 30px;}
.routing-section.add-route .content form input{border-radius: 8px; border: 1px solid #C4C4C4; padding: 10px 20px;}
.routing-section.add-route .content form textarea{border-radius: 8px; border: 1px solid #C4C4C4; padding: 10px 20px; resize: none; height: 80px;}

.routing-section.add-route .content .parameters {width: 100%;}
.routing-section.add-route .content .parameters .hazmat ,
.routing-section.add-route .content .parameters .return-route {margin-top: 20px; padding: 20px; border-top: var(--color-gray) 1px solid; width: 100%;}
.routing-section.add-route .content .parameters .return-route {border-bottom: var(--color-gray) 1px solid;}
.routing-section.add-route .content .parameters .hazmat .check-hazmat,
.routing-section.add-route .content .parameters .return-route .check-return {display: flex; flex-direction: column; justify-content: flex-start;}
.routing-section.add-route .content .parameters .hazmat .check-hazmat p-checkbox .ui-chkbox-label,
.routing-section.add-route .content .parameters .return-route .check-return p-checkbox .ui-chkbox-label {margin-left: 20px;}
.routing-section.add-route .content .parameters .hazmat .check-hazmat span.description,
.routing-section.add-route .content .parameters .return-route .check-return span.description {font-size: small; color: var(--color-gray); margin-left: 40px;}
.routing-section .hazmat .select-vehicle{display: flex; flex-direction: column;  margin-top: 20px; padding: 14px 20px; border: 1px solid var(--color-black); border-radius: 8px; cursor: pointer;}
.routing-section .hazmat .select-vehicle.disabled{opacity: .5; cursor: default; }
.routing-section .hazmat .select-vehicle .information{display: flex; flex-direction: row; justify-content: space-between; align-items: center;}
.routing-section .hazmat .select-vehicle .information .title{text-align: left; font-weight: 500;}
.routing-section .hazmat .select-vehicle .information .icon-pencil{color: var(--color-default); font-size: 23px;}
.routing-section .hazmat .select-vehicle .vehicle-profile .not-vehicle-profile-selected{font-size: smaller; color: var(--color-gray-dark);}
.routing-section.add-route .content .buttons{display: flex; flex-direction: row; justify-content: space-between; width: 100%; box-shadow: -3px -3px 4px 2px #0000000D; padding: 24px 40px;}
.routing-section.add-route .content .buttons button{flex-grow: 1; height: 50px; width: 50%;}
.routing-section.add-route .content .buttons button:last-child{margin-left: 15px;}

/* Header of add-stops */
.routing-section.add-stops {position: fixed; z-index: 2; top: 0; left: 0; height: 100vh; padding-top: 0; width: 480px; background-color: #FFFFFF;}
.routing-section.add-stops header {position: relative; height: auto;}
.routing-section.add-stops header p.description{ text-align: center; line-height: 25px; color: #000000; opacity: 0.5; font-size: 18px; padding: 0 40px; margin-bottom: 20px; z-index: 1;}
.routing-section.add-stops header .stops{ padding: 5px 0; display: flex; flex-direction: column; align-items: center; }
.routing-section.add-stops header .stops li.stop{position: relative; display: flex; flex-direction: row; align-items: center; margin-bottom: 20px;}
.routing-section.add-stops header .stops li.stop .point{position: relative; display: flex; justify-content: center; align-items: center; width: 20px; height: 20px; border-radius: 100%; border: 2px solid #CF4727; margin-right: 20px;}
.routing-section.add-stops header .stops li.stop .point.last{width: 20px; height: 20px; border-radius: 100%; border: 0; margin-right: 20px;}
.routing-section.add-stops header .stops li.stop .point.last i.icon-location-2{margin-right: 0; font-size: 20px;}
.routing-section.add-stops header .stops li.stop.dashed-line .point::after{content: ''; display: block; position: absolute; width: 1px; height: 41px; border-left: 2px dotted #CF4727; top: 18px; left: 7px;}
.routing-section.add-stops header .stops li.stop.dashed-line .point.last::after{width: 0; height: 0; border-left: 0; top: auto; left: auto;}
.routing-section.add-stops header .stops li.stop.your-location .point{background-color: #CF4727;}
.routing-section.add-stops header .stops li.stop .point i{ color: #CF4727; font-size: 10px;}
.routing-section.add-stops header .stops li.stop .icon-location-2 {position: relative; display: flex; justify-content: center; align-items: center; width: 20px; height: 20px; border-radius: 100%; border: 0; margin-right: 20px; color: #CF4727; font-size: 20px;}
.routing-section.add-stops header .stops li.stop.your-location .point.location{background-color: #CF4727;}
.routing-section.add-stops header .stops li.stop .point.location i{ color: #CF4727; font-size: 20px;}
.routing-section.add-stops header .stops li.stop input{ width: 350px; padding-right: 35px;}
/*.routing-section.add-stops header .stops li.stop i.icon{ position: absolute; right: 18px; color: #000000; cursor: pointer; visibility: collapse;}*/
/*.routing-section.add-stops header .stops li.stop:hover i.icon{ visibility: visible;}*/
.routing-section.add-stops header .stops li.stop.add-stop { padding-top: 1px; display: flex; flex-direction: row; justify-content: space-between; margin-left: 40px; }
.routing-section.add-stops header .stops li.stop.add-stop .point i{ color: #CF4727; }
.routing-section.add-stops header .stops li.stop.add-stop .btn{ width: 125%; height: 40px; }
.routing-section.add-stops header .stops li.stop.add-stop .btn.btn-white{ background-color: transparent; font-size: 10px; height: 40px; color: #CF4727; border: 1px solid #CF4727;; }
.routing-section.add-stops header .stops li.stop.add-stop .icon-edit{ font-size: 18px; margin-left: 10px; }
.routing-section.add-stops header .stops li.stop.add-stop .button-dots{ border: none; background: none; }
.routing-section.add-stops header .stops li.stop.add-stop .button-dots .icon-dots{ font-size: 20px; color: #CF4727; position: relative; }
.routing-section.add-stops header .stops li.stop.add-stop .button-dots .icon-dots.disabled { opacity: 0.5; cursor: not-allowed;}
.routing-section.add-stops header .stops li.stop.add-stop .button-dots .icon-dots.disabled:hover { cursor: not-allowed;}
/* Header add-stops dragdrop */
div.cdk-drag-preview {padding: 15px 40px 15px 80px; box-sizing: border-box; background-color: #0000001e; list-style-type: none; border-radius: 8px; display: block; position: relative; width: 450px;}
li.stop.cdk-drag-placeholder {visibility: hidden;}
div.cdk-drag-preview .point {visibility: hidden;}
div.cdk-drag-preview input{width: 100%;}
div.cdk-drag-preview i{visibility: hidden;}
ul.cdk-drop-list-dragging .stop:not(.cdk-drag-placeholder) {transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);}
ul.cdk-drop-list-dragging .stop .point::after{visibility: hidden;}
.cdk-drag-animating { transition: transform 250ms cubic-bezier(0, 0, 0.2, 1); }


/* Container of add-stops */

/* Details */
.routing-section.add-stops .save-route .details {width: 100%; padding: 20px 50px;}
/* Save route */
.routing-section.add-stops .save-route{position: initial; width: 100%; height: 100%; bottom: 50px; display: flex; flex-direction: column; justify-content: space-between; align-items: center; }
.routing-section.add-stops .save-route .buttons{position: absolute; bottom: 50px; width: 100%; display: flex; flex-direction: row; justify-content: space-evenly;}
/* Panel tabs */
.routing-section.add-stops ul.tab-view{display: flex; width: 100%; flex-direction: row; justify-content: space-evenly; padding: 10px 0px;}
.routing-section.add-stops ul.tab-view .item-search{display: block; padding: 5px 15px; color: #1f232e7F; transition: all 0.2s ease;}
.routing-section.add-stops ul.tab-view .item-search:hover{border-bottom: 2px solid #CF4727; cursor: pointer;}
.routing-section.add-stops ul.tab-view .item-search.selected{opacity: 1; border-bottom: 2px solid #CF4727; color: #1f232e}
/* Favorites panel */
.routing-section.add-stops p-tristatecheckbox .ui-chkbox-icon.pi-times::before {content: '\e90f' !important;}
.routing-section.add-stops .panel.favorites{  position: relative; width: 100%; }
.routing-section.add-stops .panel .pins-value{ position: relative; float: left; margin-right: 20px; top: -4px; }
.routing-section.add-stops .panel .pins-value .icon-pins{ color: #cf4727; font-size: 26px; }
.routing-section.add-stops .panel .pins-value .value{ color: #FFF; background: #313A52; display: inline-block; width: auto; padding: 2px 7px; font-size: 10px; border-radius: 11px; position: absolute; right: -2px; top: 0; }
.routing-section.add-stops .panel .folder-item .list-content .name{ font-weight: 600; font-size: 18px; float: left; max-width: 260px; line-break: anywhere; }
.routing-section.add-stops .panel .folder-item .list-content i.icon-arrow{ float: right; }
.routing-section.add-stops .panel .folder-item .list-content:hover i.icon-arrow{ visibility: visible; }
/*.routing-section.add-stops .panel.favorites { width: 100%; height: auto; border-bottom: 0.3px solid rgba(0, 0, 0, 0.1); position: relative; }*/

.routing-section.add-stops .panel.favorites .folder-item .list-content.selected i.icon-arrow {visibility: visible;}

.routing-section.add-stops .panel.favorites .item-search .list-content{ padding: 30px 110px 30px 40px; transition: all 0.3s ease;}
.routing-section.add-stops .panel.favorites .item-search .list-content:hover{ background: #EBF1F7;}
.routing-section.add-stops .panel.favorites .item-search .list-content .favorite-description {padding-left: 30px;}
.routing-section.add-stops .panel.favorites .item-search .list-content .name{ width: 290px; float: left; color: #52575C; font-size: 16px; font-weight: 600; transition: all 0.3s ease; max-width: 320px; cursor: pointer; line-break: anywhere; }
.routing-section.add-stops .panel.favorites .item-search .list-content .api-num{ color: #A0A4A8; font-size: 14px;  transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.routing-section.add-stops .panel.favorites .item-search .icon-share{ font-size: 24px; position: relative; top: 6px; }
.routing-section.add-stops .panel.favorites .item-search figure {float: left; margin-right: 13px; }
.routing-section.add-stops .panel.favorites .item-search figure img {width: 15px; height: 26px;}
.routing-section.add-stops .panel.favorites .item-search .marker-actions{ top: 20px; }
.routing-section.add-stops .panel.favorites div.separator {display: block; position: relative; background: #EBF1F7; height: 15px; width: 100%; }
/* My pins panel */
.routing-section.add-stops .panel.my-pins{ position: relative; width: 100%; }
.routing-section.add-stops .panel.my-pins .custom-pin .marker-actions{ top: 20px; }
.routing-section.add-stops .panel.my-pins .custom-pin .list-content .name{ width: 100%; float: left; display: block; color: #52575C; font-size: 16px; font-weight: 600; transition: all 0.3s ease; max-width: 320px; cursor: pointer; line-height: 1.3; margin-bottom: 10px; line-break: anywhere}
.routing-section.add-stops .panel.my-pins .custom-pin .list-content .info{ color: #737582; }
/* Layer panel */
.routing-section.add-stops .panel.layer .search-results{ width: 100%; }
.routing-section.add-stops .panel.layer .search-results .item-search{ width: 100%; height: auto; border-bottom: 0.3px solid rgb(0, 0, 0,0.1); position: relative; transition: all 0.3s; }
.routing-section.add-stops .panel.layer .search-results .item-search:hover{ background: #EBF1F7; }
.routing-section.add-stops .panel.layer .search-results .item-search.selected{ background: #EBF1F7; }
.routing-section.add-stops .panel.layer .search-results .item-search .list-content { padding: 30px 110px 30px 5px;}
.routing-section.add-stops .panel.layer .search-results .item-search .list-content .name{ display: block; color: #52575C; font-size: 16px; font-weight: 600; transition: all 0.3s ease; max-width: 300px;cursor: pointer; margin-bottom: 5px; line-break: anywhere; }
.routing-section.add-stops .panel.layer .search-results .item-search .list-content .api-num{ display: block; color: #A0A4A8; font-size: 14px;  transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
/* Map panel */
.routing-section.add-stops .panel.map-pins{ width: 100%; height: 100%;}
/* Users panel */
.routing-section.add-stops .panel.users ul .name{ display: block; color: #52575C; font-size: 16px; font-weight: 600; transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; line-height: 1.3; line-break: anywhere; }
.routing-section.add-stops .panel.users ul .email{ display: block; color: #A0A4A8; font-size: 14px; transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; line-height: 1.3; }
.routing-section.add-stops .panel.users ul li{ padding: 20px 30px; position: relative; border-bottom: 1px solid #d9d9d9; transition: all 0.3s ease;}
.routing-section.add-stops .panel.users ul li a{ position: absolute; right: 30px; top: 50%; margin-top: -5px; display: block; }
.routing-section.add-stops .panel.users ul li .ui-chkbox-box{ top: -4px; position: relative; }
.routing-section.add-stops .panel.users ul li .initials{ width: 40px; height: 40px; display: block; float: left; background: #797f8d; color: #FFF; border-radius: 100%; text-align: center; margin-right: 10px; font-size: 20px; font-weight: 500; line-height: 40px; }
.routing-section.add-stops .panel.users ul li .user-info{ padding-right: 30px; padding-top: 2px; }
.routing-section.add-stops .panel.users ul li:hover { background: #EBF1F7; cursor: pointer; }

/* Notes Block */
.note-list {padding: 0; display: flex; flex-direction: column; justify-content: space-between;}
.note-list.show {transform: translate(0);}
.note-list .content{height: auto; padding: 0 30px;}
.note-list .content .main-info{margin: 30px 0;}
.note-list .content .notes{}
.note-list .content .notes ul{border: 1px solid #C4C4C4; border-radius: 8px; height: calc(100vh - 460px); max-height: calc(100vh - 460px); overflow-y: auto;}
.note-list .content .notes .card{width: 100%; height: auto; padding: 20px; transition: all 0.2s ease; display: flex; align-items: center; justify-content: space-between;}
.note-list .content .notes .card:hover{background: #EBF1F7; cursor: pointer;}
.note-list .content .notes .card .options{}
.note-list .content .notes .card .options .icon-edit{opacity: 0.7; transition: all 0.2s ease; cursor: pointer; margin-right: 10px;}
.note-list .content .notes .card .options .icon-edit:hover{opacity: 0.9;}
.note-list .content .notes .card .icon-close{color: #CF4727; opacity: 0.7; transition: all 0.2s ease; cursor: pointer;}
.note-list .content .notes .card .icon-close:hover{opacity: 0.9;}

/* Custom routes Panel */
.routing-section.add-stops .tab-view-panels .panel.custom-routes .title { display: flex; flex-direction: row; justify-content: space-evenly; align-items: center; }
.routing-section.add-stops .container .tab-view-panels .panel.custom-routes .buttons { display: flex; justify-content: center; padding: 10px 0px;}
.routing-section.add-stops .container .tab-view-panels .panel.custom-routes .icon-close {padding: 5px; display: flex; justify-content: flex-end; }
.routing-section.add-stops .tab-view-panels .panel.custom-routes{ position: relative; }
.routing-section.add-stops .tab-view-panels .panel.custom-routes h2 { padding: 15px; text-align: center; }
.routing-section.add-stops .tab-view-panels.one .panel.custom-routes ul {height: 240px; border-bottom: 1px solid #d9d9d9;}
.routing-section.add-stops .tab-view-panels.two .panel.custom-routes ul{height: 240px; border-bottom: 1px solid #d9d9d9;}
.routing-section.add-stops .tab-view-panels.three .panel.custom-routes ul{height: 240px; border-bottom: 1px solid #d9d9d9;}
.routing-section.add-stops .tab-view-panels.four-more .panel.custom-routes ul{height: 240px; border-bottom: 1px solid #d9d9d9;}
.routing-section.add-stops .tab-view-panels .panel.custom-routes ul{ overflow-y: overlay; position: relative;}
.routing-section.add-stops .tab-view-panels .panel.custom-routes ul .item{ display: flex; flex-direction: row; padding: 20px 30px; border-bottom: 1px solid #d9d9d9; transition: all 0.3s ease; justify-content: space-between; align-items: center; }
.routing-section.add-stops .tab-view-panels .panel.custom-routes ul .item:hover{ background: #EBF1F7; cursor: pointer; }
.routing-section.add-stops .tab-view-panels .panel.custom-routes ul .item p-checkbox{  }
.routing-section.add-stops .tab-view-panels .panel.custom-routes ul .item div.list-content{ display: flex; flex-grow: 2; margin: auto 30px; }
.routing-section.add-stops .tab-view-panels .panel.custom-routes ul .item div.list-content p.name{  }
.routing-section.add-stops .tab-view-panels .panel.custom-routes ul .item div.buttons{  }
.routing-section.add-stops .tab-view-panels .panel.custom-routes ul .item div.buttons i{ font-size: 20px; transition: all .5s ease; }
.routing-section.add-stops .tab-view-panels .panel.custom-routes ul .item div.buttons i:hover{ opacity: .7; }
.routing-section.add-stops .tab-view-panels .panel.custom-routes .buttons{ display: flex; padding: 15px; justify-content: center; vertical-align: bottom;}


/* My Draws Panel */
/* List Draws */
.routing-custom-section .routing-custom-section-header{ position: fixed; height: 260px; top: 0; width: 480px; left: 0; }
.routing-custom-section .marker-search{ padding: 0 30px; }
.routing-custom-section .marker-search input{ border: none; }
.routing-custom-section .search-container{ position: relative; margin-bottom: 10px; }
.routing-custom-section .search-container .icon-search-bar{ color: #C9CED6; font-size: 18px; position: absolute; right: 8px; top: 9px; }
.routing-custom-section .content { width: 100%; display: block; height: calc(100vh - 360px); overflow: hidden;}

.routing-custom-section .content .routes{ width: 100%; display: flex; height: 100%;}
/* .routing-custom-section .content .routes ul.routes-list{ width: 100%; display: flex; height: 100%; flex-direction: column;} */
/* .routing-custom-section .content .routes ul.routes-list li.route-item{ position: relative; width: 100%; display: flex; min-height: 200px; padding: 40px 40px 20px; border-bottom: 0.3px solid #00000031; flex-direction: column;}
.routing-custom-section .content .routes ul.routes-list li.route-item:hover{ background: #EBF1F7; }
.routing-custom-section .content .routes ul.routes-list li.route-item div.body{ position: relative; width: 85%; }
.routing-custom-section .content .routes ul.routes-list li.route-item div.body:hover{ cursor: pointer; }
.routing-custom-section .content .routes ul.routes-list li.route-item div.body .title{ margin-bottom: 20px}
.routing-custom-section .content .routes ul.routes-list li.route-item div.body .description{ display: block; max-width: fit-content; margin-bottom: 20px }
.routing-custom-section .content .routes ul.routes-list li.route-item div.buttons.options{ width: 100%; display: flex; justify-content: flex-end; align-self: flex-end; margin-top: auto;}
.routing-custom-section .content .routes ul.routes-list li.route-item div.buttons.options button{ margin-left: 20px; } */

.routing-custom-section .content .empty{ width: 100%; display: flex; justify-content: center; height: 100%;}
.routing-custom-section .content .empty p{ display: inline-block; margin-top: 80px; font-size: 18px; color: #000000; opacity: 0.5; width: 300px; text-align: center;}
.routing-custom-section .bottom { width: 100%; display: flex; position: absolute; bottom: 0; left: 0; height: 100px; justify-content: center; align-items: center;}
.routing-custom-section .bottom button.add-button{margin: 0; width: 150px; font-weight: 600;}
.routing-custom-section .bottom button.add-button i{font-size: 12px}

/*  */
.add-edit-routing-custom-section.add-route {position: fixed; z-index: 2; top: 0; left: 0; height: 100vh; padding-top: 0; width: 480px; background-color: #FFFFFF;}
.add-edit-routing-custom-section.add-route i.icon-close{position: absolute; top: 20px; right: 20px; color: #000000; cursor: pointer;}
.add-edit-routing-custom-section.add-route .content{display: flex; flex-direction: column; padding: 30px; align-items: center; width: 100%; height: 100%;}
.add-edit-routing-custom-section.add-route .content .title{text-align: center;}
.add-edit-routing-custom-section.add-route .content .title h1{font-size: 24px; font-weight: 500;}
.add-edit-routing-custom-section.add-route .content .title p{margin-top: 20px; opacity: .5; font-size: 18px; }
.add-edit-routing-custom-section.add-route .content form{margin-top: 60px;}
.add-edit-routing-custom-section.add-route .content form input:first-child{margin-bottom: 30px;}
.add-edit-routing-custom-section.add-route .content form input{border-radius: 8px; border: 1px solid #C4C4C4; padding: 10px 20px;}
.add-edit-routing-custom-section.add-route .content .buttons{margin-top: 60px}
.add-edit-routing-custom-section.add-route .content .buttons button:last-child{margin-left: 15px;}

/* Set start/end points */
.add-edit-routing-custom-section.start-end-points header {}
.add-edit-routing-custom-section.start-end-points .container {}
.add-edit-routing-custom-section.start-end-points .container .point {width: 100%;}
.add-edit-routing-custom-section.start-end-points .container .end-point.point {margin-top: 50px;}
.add-edit-routing-custom-section.start-end-points .container .point .mini-title span{opacity: .5;}
.add-edit-routing-custom-section.start-end-points .container .point .coordinates {display: flex; flex-direction: row; justify-content: space-between;}
.add-edit-routing-custom-section.start-end-points .container .point .coordinates .input {max-width: 80%;}
.add-edit-routing-custom-section.start-end-points .container .point .coordinates .input input {margin: 10px auto}
.add-edit-routing-custom-section.start-end-points .container .point .coordinates .point-buttons {display: flex; flex-direction: column; align-items: center; justify-content: center;}
.add-edit-routing-custom-section.start-end-points .container .point .coordinates .point-buttons * {font-size: 25px; transition: all 0.3s ease; margin: 10px auto; }
.add-edit-routing-custom-section.start-end-points .container .point .coordinates .point-buttons *:hover {opacity: .7; cursor: pointer;}

.add-edit-routing-custom-section {position: fixed; z-index: 2; top: 0; left: 0; height: 100vh; padding-top: 0; width: 480px; background-color: #FFFFFF;}
.add-edit-routing-custom-section header {position: relative; height: auto;}
.add-edit-routing-custom-section .title { display: flex; align-items: center; flex-direction: column;}
.add-edit-routing-custom-section .title .back{ position: absolute; left: 20px; top: 50px; margin-top: -8px; }
.add-edit-routing-custom-section .title .back *{cursor: pointer; font-size: 20px; transition: all 0.3s ease; }
.add-edit-routing-custom-section .title .back *:hover{ opacity: .7; }
.add-edit-routing-custom-section .title .functions { position: absolute; right: 20px; top: 50px; margin-top: -8px; }
.add-edit-routing-custom-section .title .functions *{cursor: pointer; font-size: 20px; transition: all 0.3s ease;}
.add-edit-routing-custom-section .title .functions *:hover{ opacity: .7; }
.add-edit-routing-custom-section header .title p.description{ text-align: center; line-height: 25px; color: #000000; opacity: 0.5; font-size: 18px; padding: 0 40px; padding-bottom: 20px; overflow-wrap: break-word;}

.add-edit-routing-custom-section .container{ width: 100%; height: calc(100vh - 154px); display: flex; padding: 30px; flex-direction: column; align-items: center;  }
.add-edit-routing-custom-section .container .colors .mini-title{ color: #000000; opacity: .5; font-weight: 600; }
.add-edit-routing-custom-section .container .colors .ui-colorpicker{ position: absolute }
.add-edit-routing-custom-section .container .colors .custom.no-color-selected{ background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='black' stroke-width='1'/><path d='M0 0 L100 100 ' stroke='black' stroke-width='1'/></svg>"); background-repeat:no-repeat; background-position:center center; background-size: 100% 100%, auto; }
.add-edit-routing-custom-section .container .colors .custom span{color: #1f232e7F; font-size: 10px; position: absolute; white-space: nowrap; display: block; top: 35px;}
.add-edit-routing-custom-section .container .draw-info{ align-self: baseline; }
.add-edit-routing-custom-section .container .draw-info p{ }
.add-edit-routing-custom-section .container .draw-info span{ margin-left: 5px; }
/* -------------------- */
/* Panel tabs */
.add-edit-routing-custom-section .container ul.tab-view{display: flex; width: 100%; flex-direction: row; justify-content: space-evenly; padding: 10px 0px;}
.add-edit-routing-custom-section .container ul.tab-view li{display: block; padding: 5px 15px; color: #1f232e7F; transition: all 0.2s ease; font-size: 18px;}
.add-edit-routing-custom-section .container ul.tab-view li:hover{border-bottom: 2px solid #CF4727; cursor: pointer;}
.add-edit-routing-custom-section .container ul.tab-view li.selected{opacity: 1; border-bottom: 2px solid #CF4727; color: #1f232e}
/* Cards */
.add-edit-routing-custom-section .container div.tab-view-panels{height: auto; overflow: hidden; overflow-y: auto; width: 90%;}
.add-edit-routing-custom-section .container div.tab-view-panels .panel {flex-direction: column;}
.add-edit-routing-custom-section .container div.tab-view-panels .panel ul{border: 1px solid #C4C4C4; border-radius: 8px; height: calc(100vh - 500px); max-height: calc(100vh - 500px); overflow-y: auto;}
.add-edit-routing-custom-section .container div.tab-view-panels .card{width: 100%; height: auto; padding: 20px; transition: all 0.2s ease; display: flex; align-items: center; justify-content: space-between;}
.add-edit-routing-custom-section .container div.tab-view-panels .card .options{}
.add-edit-routing-custom-section .container div.tab-view-panels .card .options .icon-edit{opacity: 0.5; transition: all 0.2s ease; cursor: pointer; margin-right: 10px;}
.add-edit-routing-custom-section .container div.tab-view-panels .card .options .icon-edit:hover{opacity: 0.9;}
.add-edit-routing-custom-section .container div.tab-view-panels .card:hover{ background: #EBF1F7; cursor: pointer;}
.add-edit-routing-custom-section .container div.tab-view-panels .card.active{ background: #EBF1F7;}
.add-edit-routing-custom-section .container div.tab-view-panels .card.active .icon-close{ opacity: 0.9;}
.add-edit-routing-custom-section .container div.tab-view-panels .card.active .card-header{font-weight: 600;}
.add-edit-routing-custom-section .container div.tab-view-panels .card .icon-close{color: #CF4727; opacity: 0.5; transition: all 0.2s ease; cursor: pointer;}
.add-edit-routing-custom-section .container div.tab-view-panels .card .icon-close:hover{opacity: 0.9;}

.add-edit-routing-custom-section .container div.tab-view-panels .panel ul li.add-stop {display: flex;}
.add-edit-routing-custom-section .container div.tab-view-panels .panel ul li.add-stop button{width: 100%; margin: 10px}
.add-edit-routing-custom-section .container div.tab-view-panels .card .card-header.stop{display: flex; flex-direction: column;}
.add-edit-routing-custom-section .container div.tab-view-panels .card .card-header.stop .type{color: #9FA2B4; font-size: 10px;}
/* -------------------- */
.add-edit-routing-custom-section .container .buttons{display: flex; width: 100%; position: absolute; bottom: 0; padding: 30px 0 40px; flex-direction: row; justify-content: space-evenly; align-items: center;}
.add-edit-routing-custom-section .container .buttons button{}

/* Add Edit Recorded Route */
.page-sidebar.add-edit-recorded-route {padding: 0; display: flex; flex-direction: column; justify-content: space-between;}
.page-sidebar.add-edit-recorded-route.show {transform: translate(0);}
.page-sidebar.add-edit-recorded-route .header{position: relative; height: 260px; display: grid; align-items: center; padding: 0 30px 30px; background: #EBF1F7;}
.page-sidebar.add-edit-recorded-route .header .icon-arrow.back{position: absolute; justify-self: baseline; align-self: baseline; margin: 20px 0 0 20px;}
.page-sidebar.add-edit-recorded-route .header .title{margin-top: 30px;}
.page-sidebar.add-edit-recorded-route .header .title input{margin-bottom: 30px;}
.page-sidebar.add-edit-recorded-route .header h2{justify-self: center; font-size: 24px;}
.page-sidebar.add-edit-recorded-route .content{height: auto; padding: 0 30px;}
.page-sidebar.add-edit-recorded-route .content .main-info{margin: 30px 0;}
.page-sidebar.add-edit-recorded-route .content .notes{}
.page-sidebar.add-edit-recorded-route .content .notes ul{border: 1px solid #C4C4C4; border-radius: 8px; height: calc(100vh - 460px); max-height: calc(100vh - 460px); overflow-y: auto;}
.page-sidebar.add-edit-recorded-route .content .notes .card{width: 100%; height: auto; padding: 20px; transition: all 0.2s ease; display: flex; align-items: center; justify-content: space-between;}
.page-sidebar.add-edit-recorded-route .content .notes .card:hover{background: #EBF1F7; cursor: pointer;}
.page-sidebar.add-edit-recorded-route .content .notes .card .options{}
.page-sidebar.add-edit-recorded-route .content .notes .card .options .icon-edit{opacity: 0.7; transition: all 0.2s ease; cursor: pointer; margin-right: 10px;}
.page-sidebar.add-edit-recorded-route .content .notes .card .options .icon-edit:hover{opacity: 0.9;}
.page-sidebar.add-edit-recorded-route .content .notes .card .icon-close{color: #CF4727; opacity: 0.7; transition: all 0.2s ease; cursor: pointer;}
.page-sidebar.add-edit-recorded-route .content .notes .card .icon-close:hover{opacity: 0.9;}

.page-sidebar.add-edit-recorded-route .footer{display: flex; justify-content: space-evenly; padding: 20px;}


/* Add Edit Note */
.page-sidebar.note {padding: 0; display: flex; flex-direction: column; justify-content: space-between;}
.page-sidebar.note.show {transform: translate(0);}
.page-sidebar.note .header{position: relative; height: 50px; display: grid; align-items: center; padding: 0 30px;}
.page-sidebar.note .header .icon-arrow.back{position: absolute; justify-self: baseline;}
.page-sidebar.note .header h2{justify-self: center; font-size: 24px;}
.page-sidebar.note .content{height: calc(100vh - 200px); overflow: auto; padding: 0 40px;}
.page-sidebar.note .content .main-info{margin-top: 30px;}
.page-sidebar.note .content .main-info .title{}
.page-sidebar.note .content .main-info .description{height: 100px; overflow: auto; resize: none; margin: 20px 0}
.page-sidebar.note .content .position-info{margin-top: 30px;}
.page-sidebar.note .content .position-info .box{border-bottom: 1px solid var(--color-gray); padding: 8px 0;}
.page-sidebar.note .content .position-info .box h3{}
.page-sidebar.note .content .position-info .box p{color: var(--color-gray-dark); line-height:20px;}
.page-sidebar.note .content .position-info .coordinates{margin-top: 20px;}
.page-sidebar.note .content .position-info .coordinates span{}
.page-sidebar.note .content .buttons{margin-top: 50px; display: flex; flex-direction: row; justify-content: flex-end;}
.page-sidebar.note .footer{display: flex; justify-content: space-evenly; padding: 20px 40px;}

/* Share route page side bar */
.page-sidebar.share-sidebar {padding: 0; display: flex; flex-direction: column; }
.page-sidebar.share-sidebar.show {transform: translate(0);}
.page-sidebar.share-sidebar .icon-arrow.back{position: absolute; justify-self: baseline; padding: 20px;}
.page-sidebar.share-sidebar .icon-arrow.back:hover{cursor: pointer;}
.page-sidebar.share-sidebar .header{position: relative; height: 50px; display: grid; align-items: center; padding: 60px;}
.page-sidebar.share-sidebar .header h2{justify-self: center; font-size: 20px; line-break: anywhere;}
.page-sidebar.share-sidebar .header span{margin-left: 35px; padding: 10px;}
.page-sidebar.share-sidebar .content{height: calc(100vh - 100px); overflow: visible; padding: 30px !important;}
.page-sidebar.share-sidebar .content .panel-selection .tab-view {border-bottom: 1px solid rgba(0, 0, 0, 0.10); margin-bottom: 0 !important;}
.page-sidebar.share-sidebar .content .panel-selection .tab-view .selected{ opacity: 1; border-bottom: 2px solid #CF4727; color: #CF4727; }
.page-sidebar.share-sidebar .content .search-container  {position: relative; padding: 20px !important;}
.page-sidebar.share-sidebar .content .search-container .search-bar .users-input{  border-radius: 8px; border: 0px solid #FFF; background: var(--black1---dark, #f3f3f3); color: black;}
.page-sidebar.share-sidebar .content .search-container .search-bar .icon-search-bar{ position: absolute; top: 8px; color: #CF4727; font-size: 20px; padding: 20px !important; }
.page-sidebar.share-sidebar .content .user-list{ max-height: calc(100vh - 400px);border-bottom: 1px solid #b3b3b3; margin-bottom: 20px; }
.page-sidebar.share-sidebar .content .user-list{ height: calc(100vh - 400px); min-height: 150px; }
.page-sidebar.share-sidebar .content .user-list li{ width: 100%; position: relative; padding: 10px 60px 10px 30px; align-items: center;}
.page-sidebar.share-sidebar .content .user-list li:hover{ background: #EBF1F7; transition: 0.5s;}
.page-sidebar.share-sidebar .content .user-list li .user { display: flex; flex-direction: row;}
.page-sidebar.share-sidebar .content .user-list li .user .user-info { display: flex; align-items: center;}
.page-sidebar.share-sidebar .content .user-list li .profile{position: relative; display: flex; width: 48px; height: 48px; align-items: center; justify-content: center; font-size: 24px; background: #313A5280; color: #FFFFFF; border-radius: 100%; font-weight: 600;}
.page-sidebar.share-sidebar .content .user-list li .name{ font-size: 14px !important; color: #000 !important; line-height: 1.3; margin-bottom: 2px; padding: 10px;}
.page-sidebar.share-sidebar .content .user-list li a{ position: absolute; right: 30px; top: 25px }
.page-sidebar.share-sidebar .content .external-users-panel p { padding: 20px; color: black; font-size: 16px; font-style: normal; font-weight: 500; line-height: 140%; letter-spacing: 0.304px; };
.page-sidebar.share-sidebar .content .external-users-panel { max-height: 600px; }
.page-sidebar.share-sidebar .content .external-users-panel .external-licenses-list ul { height: 100%;}
.page-sidebar.share-sidebar .content .external-users-panel .external-license-card {display: flex; flex-direction: column; width: 389px; height: 54px; gap: 20px; border-radius: 8px; border: 1px solid rgba(0, 0, 0, 0.10); margin-left: 20px; margin-bottom: 10px;}
.page-sidebar.share-sidebar .content .external-users-panel .external-license-card:hover {background: var(--Background-selected, #EBF1F7); cursor: pointer; transition: 0.5s;}
.page-sidebar.share-sidebar .content .external-users-panel .external-license-card.selected {background: var(--Background-selected, #EBF1F7); height: 200px !important; transition: 0.5s;}
.page-sidebar.share-sidebar .content .external-users-panel .external-license-card .card-header {display: flex; justify-content: space-between;}
.page-sidebar.share-sidebar .content .external-users-panel .external-license-card .card-header .plan-info {display: flex; align-items: center;}
.page-sidebar.share-sidebar .content .external-users-panel .external-license-card .card-header .plan-info .external-license-checkbox {padding: 10px;}
.page-sidebar.share-sidebar .content .external-users-panel .external-license-card .card-header .icon-arrow {cursor: pointer; font-size: 15px; transition: all 0.3s ease; color: #CF4727; padding: 10px;}
.page-sidebar.share-sidebar .content .external-users-panel .external-license-card .card-content {display: flex; flex-direction: row; align-items: center; justify-content: space-evenly; visibility: hidden;}
.page-sidebar.share-sidebar .content .external-users-panel .external-license-card .card-content.selected{visibility: visible; transition: 1s;}
.page-sidebar.share-sidebar .content .external-users-panel .external-license-card .card-content .available-licenses h2{ color: #000; font-size: 16px; font-style: normal; font-weight: 600; line-height: 140%; letter-spacing: 0.304px;}
.page-sidebar.share-sidebar .content .external-users-panel .external-license-card .card-content .available-licenses span { color: var(--Primary, #76AF1A); leading-trim: both; font-size: 42px; font-style: normal; font-weight: 600; line-height: 140%; /* 58.8px */ letter-spacing: 0.798px;}
.page-sidebar.share-sidebar .content .external-users-panel .external-license-card .card-content .used-licenses { margin-left: 10px;}
.page-sidebar.share-sidebar .content .external-users-panel .external-license-card .card-content .used-licenses h2{ color: #000; font-size: 16px; font-style: normal; font-weight: 600; line-height: 140%; letter-spacing: 0.304px;}
.page-sidebar.share-sidebar .content .external-users-panel .external-license-card .card-content .used-licenses span { color: var(--Primary, #CF4727); leading-trim: both; font-size: 42px; font-style: normal; font-weight: 600; line-height: 140%; /* 58.8px */ letter-spacing: 0.798px;}
.page-sidebar.share-sidebar .content .external-users-panel .buttons { align-items: center; display: flex; justify-content: center; position: fixed; display: flex; width: 100%; bottom: 0; left: 0; padding: 20px; border-top: 1px solid #0000001e;}
.page-sidebar.share-sidebar .content .external-users-panel .cancel { background: transparent; color: #CF4727;}

.page-sidebar.share-sidebar .content .external-link-panel { padding: 20px 0px 20px;}
.page-sidebar.share-sidebar .content .external-link-panel .license-name { margin: 10px;}
.page-sidebar.share-sidebar .content .external-link-panel .link-info { display: flex; flex-direction: row; justify-content: space-between; border-radius: 8px; background: #F4F4F5; padding: 30px; align-items: center;}
.page-sidebar.share-sidebar .content .external-link-panel .link-info a {color: #CF4727; width: 70%; line-break: anywhere; text-decoration: underline;}
.page-sidebar.share-sidebar .content .external-link-panel .link-info span {color: #CF4727;}
.page-sidebar.share-sidebar .content .external-link-panel .link-info span:hover {color: #CF4727; cursor: pointer; }
.page-sidebar.share-sidebar .content .external-link-panel .buttons { align-items: center; display: flex; justify-content: center; position: fixed; display: flex; width: 100%; bottom: 0; left: 0; padding: 20px; border-top: 1px solid #0000001e;}
.page-sidebar.share-sidebar .content .external-link-panel .cancel { background: transparent; color: #CF4727;}

.page-sidebar.share-sidebar .content .external-share-by-email-panel .share-section .guide{ padding-bottom: 10px; display: block; }
.page-sidebar.share-sidebar .content .external-share-by-email-panel .share-section p-chips{ width: 420px; border-radius: 8px; margin-bottom: 20px; padding: 10px 5px;}
.page-sidebar.share-sidebar .content .external-share-by-email-panel .share-section p-chips ul { width: 420px; border-radius: 8px; margin-bottom: 20px; padding: 10px 5px;}
.page-sidebar.share-sidebar .content .external-share-by-email-panel .share-section p-chips ul li { max-width: 100%; margin: 5px; }
.page-sidebar.share-sidebar .content .external-share-by-email-panel .share-section p-chips .ui-chips > ul.ui-inputtext {overflow: auto; max-height: 180px;}
.page-sidebar.share-sidebar .content .external-share-by-email-panel .share-section p-chips .ui-chips > ul.ui-inputtext .ui-chips-token {background-color: #CF4727;}
.page-sidebar.share-sidebar .content .external-share-by-email-panel .share-section p-chips ul.ui-inputtext .ui-chips-input-token input {width: 380px;}
.page-sidebar.share-sidebar .content .external-share-by-email-panel .share-section textarea { height: 300px; border-radius: 8px; color: rgba(0, 0, 0, 0.70); font-size: 14px; font-style: normal; font-weight: 400; line-height: 140%; /* 22.4px */ letter-spacing: 0.304px; padding: 10px; resize: none;}
.page-sidebar.share-sidebar .content .external-share-by-email-panel .share-section a { color: #CF4727; text-decoration: underline; }
.page-sidebar.share-sidebar .content .external-share-by-email-panel .buttons { display: flex; flex-direction: row; justify-content: center; }
.page-sidebar.share-sidebar .content .external-share-by-email-panel .cancel { background: transparent; color: #CF4727;}


.page-sidebar.share-sidebar .content .buttons{ display: flex; padding: 8px 16px; align-items: center; gap: 8px;}
.page-sidebar.share-sidebar .content .text-email-btn{border-radius: 26px; border: 2px solid var(--Primary, #CF4727); background: var(--White, #FFF); color: #CF4727; height: 80%; padding: 7px; transition: 0.5s;}
.page-sidebar.share-sidebar .content .text-email-btn:hover{background: var(--Primary, #CF4727); color: white;}
.page-sidebar.share-sidebar .content .through-app-btn{border-radius: 26px; background: var(--Primary, #CF4727); height: 80%; color: white; border: none; padding: 7px;}
.page-sidebar.share-sidebar .content .through-app-btn:hover{border: 3px solid var(--Primary, #CF4727); transition: 0.2s;}
.page-sidebar.share-sidebar .content .share-by {padding: 20px 0px;}
.page-sidebar.share-sidebar .content .share-by span{color: #CF4727;}
.page-sidebar.share-sidebar .content .share-by span:hover{cursor: pointer;}

.modal-pop-up .icon-close {position: absolute; right: 5px; top: 7px; font-size: 10px;}

.routing-option { border-bottom: 1px solid rgba(0, 0, 0, 0.1); font-size: 15px; }
.routing-option.end { border-bottom: none;  }
.routing-option.start:hover { background-color: #e1e1e1; cursor: pointer; border-radius: 15px 15px 0px 0px; }
.routing-option.end:hover { background-color: #e1e1e1; cursor: pointer; border-radius: 0px 0px 15px 15px; }
.routing-option:hover { background-color: #e1e1e1; cursor: pointer;  }
.routing-option p { padding: 10px 10px; }