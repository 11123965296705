
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}									

article, aside, figure, footer, header, hgroup, nav, section {display: block;}

img,
object,
embed {max-width: 100%;}

html {/*overflow-y: scroll;*/}

ul {list-style: none;}

blockquote, q {quotes: none;}

blockquote:before, 
blockquote:after, 
q:before, 
q:after {content: ''; content: none;}

a {margin: 0; padding: 0; font-size: 1em; vertical-align: baseline; background: transparent;}

del {text-decoration: line-through;}

abbr[title], dfn[title] {border-bottom: 1px dotted #000; cursor: help;}

/* tables still need cellspacing="0" in the markup */
table {border-collapse: collapse; border-spacing: 0;}
th {font-weight: bold; vertical-align: bottom;}
td {font-weight: normal; vertical-align: top;}

hr {display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0;}

input, select {vertical-align: middle;}

pre {
	white-space: pre;
	white-space: pre-wrap;
	white-space: pre-line;
	word-wrap: break-word;
}

input[type="radio"] {vertical-align: text-bottom;}
input[type="checkbox"] {vertical-align: bottom; *vertical-align: baseline;}
.ie6 input {vertical-align: text-bottom;}

select, input, textarea {font: 1em sans-serif;}

table {font-size: inherit; font: 1em;}
 
a:hover, a:active {outline: none;}

small {font-size: 85%;}

strong, th {font-weight: bold;}

td, td img {vertical-align: top;} 

p, span{-webkit-text-size-adjust:none; }

sub, sup {font-size: 75%; line-height: 0; position: relative;}
sup {top: -0.5em;}
sub {bottom: -0.25em;}

pre, code, kbd, samp {font-family: monospace, sans-serif;}

.clickable, 
input[type=button], 
input[type=submit], 
button {cursor: pointer;}

button, input, select, textarea {margin: 0;}

button {width: auto; overflow: visible;}
 
.ie7 img {-ms-interpolation-mode: bicubic;}
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}

.clearfix:before, .clearfix:after { content: "\0020"; display: block; height: 0; overflow: hidden; }  
.clearfix:after { clear: both; }  
.clearfix { zoom: 1; }  

body {font: 16px  Arial, Helvetica, sans-serif;}

body, select, input, textarea, button { color: #333; -webkit-appearance: none; border-radius:0px; outline: none; box-shadow: none; }
select:focus, input:focus, textarea:focus, button:focus{ outline: none; }

a {color: #03f;}
a:hover {color: #999;}

::-moz-selection{background: #3eabfe; color: #FFFFFF; text-shadow: none;}
::selection {background: #3eabfe; color: #FFFFFF; text-shadow: none;} 
a:link {-webkit-tap-highlight-color:rgba(0,0,0,0.2);} 

ins {background-color: #fcd700; color: #000; text-decoration: none;}
mark {background-color: #fcd700; color: #000; font-style: italic; font-weight: bold;}

*,
*:after,
*:before{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}