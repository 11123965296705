.dashboard-section{ padding: 40px 30px; background-color: #F3F7FA;}

/*Header Section*/
.header-section{ margin-bottom: 60px; }
.header-section h2{ color: #252733; font-size: 24px; float: left; }
.header-section .right-bar{ float: right; }

.header-section .right-bar .search-bar{ border-bottom: 1px solid #DFE0EB; float: left; padding: 5px; width: 300px; position: relative; }
.header-section .right-bar .search-bar i{ color: #C9CED6; font-size: 20px; margin-right: 20px; }
.header-section .right-bar .search-bar .search{ background: none; border: none; position: relative; top: -3px; width: 85%; }
.header-section .right-bar .search-bar .search::-webkit-input-placeholder{ color: #A6ACBE; }
.header-section .right-bar .search-bar .search::-moz-placeholder{ color: #A6ACBE; }
.header-section .right-bar .search-bar .search:-ms-input-placeholder{ color: #A6ACBE; }
.header-section .right-bar .search-bar .search:-moz-placeholder{ color: #A6ACBE; }

.header-section .right-bar .buttons{ float: left; border-left: 1px solid #DFE0EB; margin-left: 30px; }
.header-section .right-bar .buttons a{ color: #C9CED6; display: inline-block; font-size: 20px; width: 30px; height: 30px; background: none; text-align: center; line-height: 30px; margin-left: 10px; transition: all 0.3s ease; }
.header-section .right-bar .buttons a:hover{ color: #000; }

/* Table container */
.list.table-container { background: #fff; border: 1px solid #dfe0eb; border-radius: 8px; padding: 30px 0;}
.list.table-container .title { font-size: 19px; font-weight: 500; padding: 10px 30px 20px;; opacity: 1; transition: opacity .5s ease; margin-right: 10px; color: var(--color-black); display: inline-block;}
.list.table-container .title span:nth-child(2) { margin-left: 30px; }
.list.table-container .title span:hover { cursor: pointer; }
.list.table-container .title span.selected { color: var(--color-default); border-bottom: 2px solid var(--color-default); }
.list.table-container .panel .tab:hover { opacity: .7; }
.list.table-container .panel .tab.selected { font-weight: bold; opacity: 1; border-bottom: 2px solid var(--color-default); }
/* .list.table-container .panel .tab { padding: 0 30px 30px;} */

/*User Table*/
.users-list.table-container thead tr{display:block; width: calc(100%);}
.users-list.table-container thead tr th{display:inline-block;}

.users-list.table-container .table-wrapper {max-height: 620px; height: calc(100vh - 450px); overflow: hidden;}

.users-list.table-container .spacer{max-width: 3%; width: 3%;}
.users-list.table-container .spacer-scroll{width: calc(3%);}
.users-list.table-container .mail{max-width: 24%; width: 24%;}
.users-list.table-container .first-name,
.users-list.table-container .last-name,
.users-list.table-container .company,
.users-list.table-container .status,
.users-list.table-container .created-at,
.users-list.table-container .updated-at,
.users-list.table-container .vehicle {width: 9.28%;}
.users-list.table-container .staff {width: 5%;}

.users-list.table-container .option-dots:hover { color: #000;}
.users-list.table-container .option-dots { color: #c5c7cd; display: block; width: 30px; height: 30px; cursor: pointer; background: none;  text-align: center;  line-height: 30px;  position: absolute;  right: 10px;  top: 18px;  transition: all .3s ease;}
.users-list.table-container a {  color: #000;  transition: all .3s ease;}
.users-list.table-container .vehicle a { transition: all .3s ease; font-weight: 500;}
.users-list.table-container .vehicle a:hover { cursor: pointer; opacity: .85; text-decoration: underline; }

.users-list.table-container span.date{ display: block; color: #252733; font-size: 14px; font-weight: 600; transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.users-list.table-container span.hour{ display: block; color: #C5C7CD; font-size: 12px; transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.users-list.table-container.edit-user-container .bottom-buttons{ display: block; text-align: center; margin: 0 auto; width: 220px; margin-top: 30px;}
.users-list.table-container .bottom-buttons .red-button{ margin-left: 20px; }

.cancel-button{ width: 100px;  padding: 10px 0; margin: 0 auto; float: left; border: 1px solid #9FA2B4; background: #FFF; box-sizing: border-box; border-radius: 8px; font-weight: 600; font-size: 13px; letter-spacing: 0.2px; color: #9FA2B4;}
.save-button{ width: 100px; font-size: 13px; border-radius: 8px; padding: 10px 0; margin: 0 auto; float: right; }
.save-button i{ margin-right: 5px; }

.user-table{ background: #fff; border: 1px solid #dfe0eb; border-radius: 8px; padding: 30px 10px; }
.user-table .title{ padding: 0 30px 30px; }
.user-table a{ color: #000; transition: all 0.3s ease; }
.user-table a:hover{ color: #007EFF; }

.user-table .bottom-buttons{ padding: 0 30px; }
.user-table .bottom-buttons .add-button{ width: 100px; font-size: 13px; border-radius: 8px; padding: 10px 0; float: left; margin-top: 30px; }
.users-list .bottom-buttons .show-pending-users-button{ width: 150px; font-size: 13px; border-radius: 8px; padding: 10px 0; float: left; margin-top: 30px; }
.user-table .bottom-buttons .add-button i{ margin-right: 5px; }
.user-table .bottom-buttons .pagination{ float: right; margin-top: 30px; }

.user-table .option-dots{ color: #c5c7cd; display: block; width: 30px; height: 30px; cursor: pointer; background: none; text-align: center; line-height: 30px; position: absolute; right: 10px; top: 10px; transition: all 0.3s ease; }
.user-table .option-dots:hover{ color: #000; }

.user-table .user-search-bar .search {float: left; width: 300px;}
.user-table .add-user-button { background: #CF4727; color: #FFF;  border: none;  font-size: 13px;  font-family: "SF Display", "Lato", sans-serif;  cursor: pointer; margin-left: 15px; width: 100px;  border-radius: 8px; padding: 10px 0;}

.user-table .search_popover-container { width: 300px;}

.user-table .search_popover-container .search-bar{ border-bottom: 1px solid #DFE0EB; float: left; padding: 5px; width: 300px; position: relative; }
.user-table .search_popover-container .search-bar i{ color: #C9CED6; font-size: 20px; margin-right: 20px; }
.user-table .search_popover-container .search-bar .search{ background: none; border: none; position: relative; top: -3px; }
.user-table .search_popover-container .search-bar .search::-webkit-input-placeholder{ color: #A6ACBE; }
.user-table .search_popover-container .search-bar .search::-moz-placeholder{ color: #A6ACBE; }
.user-table .search_popover-container .search-bar .search:-ms-input-placeholder{ color: #A6ACBE; }
.user-table .search_popover-container .search-bar .search:-moz-placeholder{ color: #A6ACBE; }

/*Tables*/
table{ width: 100%; font-size: 15px; }
table th{ text-align: left; font-weight: 600; color: #9FA2B4; }
table th,
table td{ padding: 15px 8px; border-bottom: 1px solid #dfe0eb; transition: all 0.3s ease; font-size: 14px; position: relative; }
table td.center,th.center {text-align: center;}
/* table tr:hover td{ background: #f7f8ff; } */

/*Settings Table*/
.dashboard-section.settings form {position: relative;}
.dashboard-section.settings form .body-section{display: flex; flex-direction: row; min-height: 70vh; width: 100%;}
.dashboard-section.settings form .body-section.one-child{flex-direction: column;}
.dashboard-section.settings .header-section {margin-bottom: 40px;}
.dashboard-section.settings .header-section h2 {font-weight: 600; font-size: 30px; line-height: 36px; letter-spacing: 0.4px;  color: #313A52;}
.dashboard-section.settings .settings-container h2{  font-style: normal; font-weight: bold; font-size: 24px; line-height: 29px; letter-spacing: 0.4px;  color: #252733; margin-bottom: 20px; display: flex; flex-direction: column;}
.dashboard-section.settings .settings-container.left { width: 40%; padding: 30px; background-color: white; border-radius: 8px; border: 1px solid #DFE0EB;}
.dashboard-section.settings .settings-container.left.all-width { display: block; width: 100%; padding: 30px;}
.dashboard-section.settings .settings-container.left.all-width ul.inputs-list { display: flex; flex-direction: row; flex-wrap: wrap; width: 100%;}
.dashboard-section.settings .settings-container.left.all-width ul.inputs-list li.row:nth-child(even) { margin-left: 40px;}
.dashboard-section.settings .settings-container.left.all-width li.row { width: 40%;}
.dashboard-section.settings .settings-container.left li.row {height: 70px; margin-bottom: 30px; width: 100%; position: relative;}
.dashboard-section.settings .settings-container.left li.row:last-child {margin-bottom: 0;}
.dashboard-section.settings .settings-container.left li.row input {width: 100%;}
.dashboard-section.settings .settings-container.left li span.reset {position: absolute; right: 20px; top: 40px;font-weight: 600; font-size: 14px; line-height: 20px; align-items: center; letter-spacing: 0.2px; color: #CF4727; cursor: pointer; }
.dashboard-section.settings .settings-container.right {height: 100%; width: 60%; padding: 0 40px;  padding-right: 0; }
.dashboard-section.settings .settings-container.right .table-container{ margin-bottom: 20px; padding: 30px; background-color: white; border-radius: 8px; border: 1px solid #DFE0EB;}

.dashboard-section.settings .settings-container.right .table-container.enterprise-account .enterprise-account-table tr:hover td{ background: #f7f8ff; };

.dashboard-section.settings .table-container.permissions div { display: flex; flex-direction: row; align-items: center; }
.dashboard-section.settings .table-container.permissions div .label { margin: 10px; }
.dashboard-section.settings .table-container.permissions div .switch { margin: 10px !important; }
.dashboard-section.settings .table-container.states .head-options{ margin-bottom: 20px; }
.dashboard-section.settings .table-container.states .head-options h2{ float: left; }
.dashboard-section.settings .table-container.states .head-options .select-all-button{ float: right; color: #D85927; text-decoration: underline; cursor: pointer; position: relative; top: 5px; }
.dashboard-section.settings .table-container.states .head-options .select-all-button:hover{ text-decoration: none; }
.dashboard-section.settings .table-container.states{ margin-top: 30px; }
.dashboard-section.settings .table-container.states li{ width: 33%; float: left; height: 30px; margin-bottom: 10px; font-size: 15px; cursor: pointer; padding: 10px; }

.dashboard-section.settings table tbody td{ border-bottom: 0;}
.dashboard-section.settings table tbody .exceeded-error{ background: rgba(239, 141, 110, 0.15); }
.dashboard-section.settings table tbody .exceeded-error .number-cell{ color: #CF4727 }
.dashboard-section.settings table tbody .exceeded-error .text-cell{ color: black }
.dashboard-section.settings table tbody .exceeded-error .error-cell{ color: var(--Primary, #CF4727); font-size: 12px; font-style: normal; line-height: normal; letter-spacing: 0.2px; align-items: center; }
.dashboard-section.settings table tbody .exceeded-error .error-cell svg { vertical-align: bottom; }
.dashboard-section.settings table tbody .view-users-cell span{ color: #CF4727; }
.dashboard-section.settings table tbody .view-users-cell span:hover{ cursor: pointer; }
.dashboard-section.settings .table-container.about-us {margin-top: 30px; display: flex; align-items: center; justify-content: center; flex-direction: column; width: 100%;}
.dashboard-section.settings .table-container.about-us h2{margin-bottom: 10px;}
.dashboard-section.settings .table-container.about-us p{font-size: 16px;  line-height: 19px; letter-spacing: 0.4px; color: #000000;    opacity: 0.5;margin-bottom: 30px;}
.dashboard-section.settings .table-container.about-us ul{display: flex; flex-direction: row; flex-wrap: wrap; width: 100%; justify-content: center; align-items: center; }
.dashboard-section.settings .table-container.about-us li{text-align: center; margin: 0 20px;}
.dashboard-section.settings .table-container.about-us li a{ font-weight: 600; font-size: 18px; line-height: 21px; letter-spacing: 0.4px; color: #D85927;}

.dashboard-section .ui-multiselect{ width: 100% !important; }
.dashboard-section .p-multiselect{ width: 100% !important; }
.dashboard-section .settings-container.left li > label{ display: block; margin-bottom: 10px; }

/*Pagination*/
.pagination{ color: #9FA2B4; float: right; padding: 0 20px; padding-top: 30px; }
.pagination a{ color: #9FA2B4; cursor: pointer; width: 30px; height: 30px; font-size: 15px; background: none; display: inline-block; text-align: center; line-height: 30px; transition: all 0.3s ease; }
.pagination a:hover{ color: #CF4727; }
.pagination .page-number{ display: inline-block; margin-right: 40px; font-size: 14px; }
.pagination .disabled{ cursor: not-allowed; opacity: 0.6; pointer-events: none;}
.pagination .prev{ transform: rotate(90deg); }
.pagination .next{ transform: rotate(-90deg); }

/*Edit User*/
.edit-user-container{ padding: 30px; }
.edit-user-container .left{width: 60%; float: left; padding-right: 40px;}
.edit-user-container .reset-password{width: 40%; float: left;margin-top: 25px; padding: 40px; border: 1px solid #C4C4C4;  box-sizing: border-box;  border-radius: 8px;}
.edit-user-container .permissions{width: 40%; float: left; margin-top: 25px; padding: 40px; border: 1px solid #C4C4C4;  box-sizing: border-box;  border-radius: 8px;}
.edit-user-container label{ width: 100%; display: block; font-weight: 600; color: #9FA2B4; margin-bottom: 10px; font-size: 14px; }
.edit-user-container input,
.edit-user-container select{ border: 1px solid #c4c4c4; width: 100%; padding: 10px 15px; border-radius: 5px; margin-bottom: 30px; font-size: 14px; }
.edit-user-container .ui-multiselect{ border: 1px solid #c4c4c4; width: 100% !important; padding: 3px 10px; border-radius: 5px; margin-bottom: 30px; }
.edit-user-container .ui-multiselect input{ margin-bottom: 0; }
.edit-user-container .p-multiselect{ border: 1px solid #c4c4c4; width: 100% !important; padding: 3px 10px; border-radius: 5px; margin-bottom: 30px; }
.edit-user-container .p-multiselect input{ margin-bottom: 0; }

.edit-user-container .title{ padding: 0 0 30px; }
.edit-user-container .title span{ color: #9fa2b4; }
.edit-user-container input:focus{ background: #f7f8fc; }
.edit-user-container .test .ui-state-highlight{ background: #EEE !important; color: #000 !important; }
.edit-user-container .ui-calendar{  width: 100% !important; border-radius: 5px; margin-bottom: 30px; }
.edit-user-container .ui-calendar input{ margin-bottom: 0; }
.edit-user-container textarea { height: 100px; border: 1px solid #c4c4c4; width: 100%; padding: 10px 15px; border-radius: 5px; margin-bottom: 30px; font-size: 14px; }
.edit-user-container .header { padding: 0 0 30px; }
.edit-user-container .header .title { display: inline;}
.edit-user-container .header .restore-button{ float: right; color: #CF4727; width: 200px; border: 1px solid #CF4727 !important; display: block; font-family: "SF Display", "Lato", sans-serif; cursor: pointer; font-size: 13px; border-radius: 8px; padding: 8px 0; background: #FFF; }
.edit-user-container input[readonly] {  border: none; cursor: not-allowed; pointer-events: none;}

.edit-user-container .permissions form{ }
.edit-user-container .permissions form ul{ display: flex; flex-direction: column; align-items: flex-start; justify-content: center; margin-top: 20px; }
.edit-user-container .permissions form ul li{ margin: 1px 0; display: flex; flex-direction: row; align-items: center; justify-content: flex-start; }
.edit-user-container .permissions form ul li span.label{ margin: 0 10px; }
.edit-user-container .permissions .buttons{ margin-top: 20px; }


/*Search*/
.users .search_popover{ top: 40px; background: #fff; box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12); border-radius: 8px; left: 0; width: 100%; padding: 10px 0; z-index: 10; overflow-y: auto; }
.users .search_popover .list li{ padding: 10px 20px; transition: all 0.3s ease; }
.users .search_popover .list li .name{ display: block; color: #52575C; font-size: 16px; font-weight: 600; transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.users .search_popover .list li .email{ display: block; color: #A0A4A8; font-size: 14px; transition: all 0.3s ease; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.users .search_popover .no-results{ padding: 0 20px; }
.users .search_popover .list li:hover{ cursor: pointer; background: #f5faff; }
.users .search_popover .list li:hover .name,
.users .search_popover .list li:hover .email{ color: #007EFF; }

.users .search-bar  .search_popover{ position: absolute;  max-height: 400px;}
.users .user-search-bar .search_popover{ max-height: 180px;}

/*Menu popup*/
.users .menu-popup-wrap{ position: relative; }
.users .menu-popup{ background: #fff; box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12); border-radius: 8px; right: 10px; width: 0; max-height: 0; opacity: 0; overflow: hidden; padding: 10px 0; z-index: 10; position: absolute; top: 50px; transition: opacity 0.3s ease; }
.users .menu-popup.show{ opacity: 1; width: 170px; max-height: 500px; overflow: visible; }
.users .menu-popup ul li{ padding: 10px 20px; transition: all 0.3s ease; }
.users .menu-popup ul li:hover{ color: #007EFF; cursor: pointer; }


/*Change Password*/
.change-password{ position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); z-index: 9999; }
.change-password .change-password-content{ width: 450px; height: 350px; top: 50%; left: 50%; margin-left: -225px; margin-top: -175px; position: absolute; background: #FFF; border: 1px solid #C4C4C4; box-sizing: border-box; border-radius: 4px; z-index: 9999; padding: 20px; }
.change-password .change-password-content label{ display: block; margin-bottom: 10px; color: #9FA2B4; font-weight: normal; margin-top: 10px; }
.change-password .change-password-content .ui-dialog-buttonpane{ display: block; margin-top: 20px; text-align: center; }
.change-password .change-password-content .ui-dialog-buttonpane button{ display: inline-block; float: none; margin: 0 10px; font-family: "SF Display", "Lato", sans-serif; font-size: 14px; }

/* External Users Table */

.users-list.table-container.external .mail{max-width: 24%; width: 24%;}
.users-list.table-container.external .first-name,
.users-list.table-container.external .last-name,
.users-list.table-container.external .vendor,
.users-list.table-container.external .license,
.users-list.table-container.external .phone-number,
.users-list.table-container.external .first-active-date,
.users-list.table-container.external .last-active-date {width: 10%;}