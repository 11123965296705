@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Muli:wght@400;600;700;800&display=swap');
@import url("https://use.fontawesome.com/releases/v5.7.2/css/all.css");

/** Black */
@font-face {
    font-family: "SF Display";
    font-weight: 900;
    src: url("../fonts/SF-UI-Display-Black.otf");
  }

  /** Bold */
  @font-face {
    font-family: "SF Display";
    font-weight: 700;
    src: url("../fonts/SF-UI-Display-Bold.otf");
  }

  /** Heavy */
  @font-face {
    font-family: "SF Display";
    font-weight: 800;
    src: url("../fonts/SF-UI-Display-Heavy.otf");
  }

  /** Light */
  @font-face {
    font-family: "SF Display";
    font-weight: 200;
    src: url("../fonts/SF-UI-Display-Light.otf");
  }

  /** Medium */
  @font-face {
    font-family: "SF Display";
    font-weight: 500;
    src: url("../fonts/SF-UI-Display-Medium.otf");
  }

  /** Regular */
  @font-face {
    font-family: "SF Display";
    font-weight: 400;
    src: url("../fonts/SF-UI-Display-Regular.otf");
  }

  /** Semibold */
  @font-face {
    font-family: "SF Display";
    font-weight: 600;
    src: url("../fonts/SF-UI-Display-Semibold.otf");
  }

  /** Thin */
  @font-face {
    font-family: "SF Display";
    font-weight: 300;
    src: url("../fonts/SF-UI-Display-Thin.otf");
  }

  /** Ultralight */
  @font-face {
    font-family: "SF Display";
    font-weight: 100;
    src: url("../fonts/SF-UI-Display-Ultralight.otf");
  }

  /*---------------------------------------------------------------------------*
   * SF UI Text
   *---------------------------------------------------------------------------*/

  /** Bold */
  @font-face {
    font-family: "SF Text";
    font-weight: 700;
    src: url("../fonts/SF-UI-Text-Bold.otf");
  }

  /** Bold Italic */
  @font-face {
    font-family: "SF Text";
    font-weight: 700;
    font-style: italic;
    src: url("../fonts/SF-UI-Text-BoldItalic.otf");
  }

  /** Heavy */
  @font-face {
    font-family: "SF Text";
    font-weight: 800;
    src: url("../fonts/SF-UI-Text-Heavy.otf");
  }

  /** Heavy Italic */
  @font-face {
    font-family: "SF Text";
    font-weight: 800;
    font-style: italic;
    src: url("../fonts/SF-UI-Text-HeavyItalic.otf");
  }

  /** Light */
  @font-face {
    font-family: "SF Text";
    font-weight: 200;
    src: url("../fonts/SF-UI-Text-Light.otf");
  }

  /** Light Italic */
  @font-face {
    font-family: "SF Text";
    font-weight: 200;
    font-style: italic;
    src: url("../fonts/SF-UI-Text-LightItalic.otf");
  }

  /** Medium */
  @font-face {
    font-family: "SF Text";
    font-weight: 500;
    src: url("../fonts/SF-UI-Text-Medium.otf");
  }

  /** Medium Italic */
  @font-face {
    font-family: "SF Text";
    font-weight: 500;
    font-style: italic;
    src: url("../fonts/SF-UI-Text-MediumItalic.otf");
  }

  /** Regular */
  @font-face {
    font-family: "SF Text";
    font-weight: 400;
    src: url("../fonts/SF-UI-Text-Regular.otf");
  }

  /** Regular Italic */
  @font-face {
    font-family: "SF Text";
    font-weight: 400;
    font-style: italic;
    src: url("../fonts/SF-UI-Text-RegularItalic.otf");
  }

  /** Semibold */
  @font-face {
    font-family: "SF Text";
    font-weight: 600;
    src: url("../fonts/SF-UI-Text-Semibold.otf");
  }

  /** Semibold Italic */
  @font-face {
    font-family: "SF Text";
    font-weight: 600;
    font-style: italic;
    src: url("../fonts/SF-UI-Text-SemiboldItalic.otf");
  }

  /** Ultrathin */
  @font-face {
    font-family: "SF Text";
    font-weight: 100;
    src: url("../fonts/SF-UI-Text-Ultrathin.otf");
  }

  /** Ultrathin Italic */
  @font-face {
    font-family: "SF Text";
    font-weight: 100;
    font-style: italic;
    src: url("../fonts/SF-UI-Text-UltrathinItalic.otf");
  }

  /*---------------------------------------------------------------------------*
   * SF Mono
   *---------------------------------------------------------------------------*/

  /** Bold */
  @font-face {
    font-family: "SF Mono";
    font-weight: 700;
    src: url("../fonts/SFMono-Bold.otf");
  }

  /** Bold Italic */
  @font-face {
    font-family: "SF Mono";
    font-weight: 700;
    font-style: italic;
    src: url("../fonts/SFMono-BoldItalic.otf");
  }

  /** Heavy */
  @font-face {
    font-family: "SF Mono";
    font-weight: 800;
    src: url("../fonts/SFMono-Heavy.otf");
  }

  /** Heavy Italic */
  @font-face {
    font-family: "SF Mono";
    font-weight: 800;
    font-style: italic;
    src: url("../fonts/SFMono-HeavyItalic.otf");
  }

  /** Light */
  @font-face {
    font-family: "SF Mono";
    font-weight: 200;
    src: url("../fonts/SFMono-Light.otf");
  }

  /** Light Italic */
  @font-face {
    font-family: "SF Mono";
    font-weight: 200;
    font-style: italic;
    src: url("../fonts/SFMono-LightItalic.otf");
  }

  /** Medium */
  @font-face {
    font-family: "SF Mono";
    font-weight: 500;
    src: url("../fonts/SFMono-Medium.otf");
  }

  /** Medium Italic */
  @font-face {
    font-family: "SF Mono";
    font-weight: 500;
    font-style: italic;
    src: url("../fonts/SFMono-MediumItalic.otf");
  }

  /** Regular */
  @font-face {
    font-family: "SF Mono";
    font-weight: 400;
    src: url("../fonts/SFMono-Regular.otf");
  }

  /** Regular Italic */
  @font-face {
    font-family: "SF Mono";
    font-weight: 400;
    font-style: italic;
    src: url("../fonts/SFMono-RegularItalic.otf");
  }

  /** Semibold */
  @font-face {
    font-family: "SF Mono";
    font-weight: 600;
    src: url("../fonts/SFMono-Semibold.otf");
  }

  /** Semibold Italic */
  @font-face {
    font-family: "SF Mono";
    font-weight: 600;
    font-style: italic;
    src: url("../fonts/SFMono-SemiboldItalic.otf");
  }

  /*Wellsite Font Face Kit*/
  @font-face {
    font-family: 'wellsite';
    src: url('../fonts/wellsite.eot?20072903');
    src: url('../fonts/wellsite.eot?20072903#iefix') format('embedded-opentype'),
         url('../fonts/wellsite.woff2?20072903') format('woff2'),
         url('../fonts/wellsite.woff?20072903') format('woff'),
         url('../fonts/wellsite.ttf?20072903') format('truetype'),
         url('../fonts/wellsite.svg?20072903#wellsite') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'dlabs';
    src: url('../fonts/dlabs.eot?70972963');
    src: url('../fonts/dlabs.eot?70972963#iefix') format('embedded-opentype'),
         url('../fonts/dlabs.woff2?70972963') format('woff2'),
         url('../fonts/dlabs.woff?70972963') format('woff'),
         url('../fonts/dlabs.ttf?70972963') format('truetype'),
         url('../fonts/dlabs.svg?70972963#dlabs') format('svg');
    font-weight: normal;
    font-style: normal;
  }

   [class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "wellsite";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  [class^="icon-"]:before, [class*=" icon-"]:hover {
    cursor: pointer;
  }

  [class^="icondl-"]:before, [class*=" icondl-"]:before {
    font-family: "dlabs";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /*wellsite codes*/
  .icon-lock-open:before { content: '\e800'; } /* 'î €' */
.icon-arrow:before { content: '\e801'; } /* 'î ' */
.icon-check:before { content: '\e802'; } /* 'î ‚' */
.icon-clock:before { content: '\e803'; } /* 'î ƒ' */
.icon-close:before { content: '\e804'; } /* 'î „' */
.icon-dotbar:before { content: '\e805'; } /* 'î …' */
.icon-dots:before { content: '\e806'; } /* 'î †' */
.icon-edit:before { content: '\e807'; } /* 'î ‡' */
.icon-heart:before { content: '\e808'; } /* 'î ˆ' */
.icon-heart-focus:before { content: '\e809'; } /* 'î ‰' */
.icon-layers:before { content: '\e80a'; } /* 'î Š' */
.icon-location:before { content: '\e80b'; } /* 'î ‹' */
.icon-location-round:before { content: '\e80c'; } /* 'î Œ' */
.icon-map:before { content: '\e80d'; } /* 'î ' */
.icon-map-focus:before { content: '\e80e'; } /* 'î Ž' */
.icon-map-user:before { content: '\e80f'; } /* 'î ' */
.icon-minus:before { content: '\e810'; } /* 'î ' */
.icon-pins:before { content: '\e811'; } /* 'î ‘' */
.icon-pin-small:before { content: '\e812'; } /* 'î ’' */
.icon-plus:before { content: '\e813'; } /* 'î “' */
.icon-reset:before { content: '\e814'; } /* 'î ”' */
.icon-search:before { content: '\e815'; } /* 'î •' */
.icon-search-bar:before { content: '\e816'; } /* 'î –' */
.icon-search-focus:before { content: '\e817'; } /* 'î —' */
.icon-settings:before { content: '\e818'; } /* 'î ˜' */
.icon-settings2:before { content: '\e819'; } /* 'î ™' */
.icon-share:before { content: '\e81a'; } /* 'î š' */
.icon-trash:before { content: '\e81b'; } /* 'î ›' */
.icon-users:before { content: '\e81c'; } /* 'î œ' */
.icon-outline-pin:before { content: '\e81d'; } /* 'î ' */
.icon-fill-pin:before { content: '\e81e'; } /* 'î ž' */
.icon-pin-focus:before { content: '\e81f'; } /* 'î Ÿ' */
.icon-settings-focus:before { content: '\e820'; } /* 'î  ' */
.icon-users-focus:before { content: '\e821'; } /* 'î ¡' */
.icon-map-focus-1:before { content: '\e822'; } /* 'î ¢' */
.icon-attach-1:before { content: '\e823'; } /* 'î £' */
.icon-map2:before { content: '\e824'; } /* 'î ¤' */
.icon-chat-focus:before { content: '\e825'; } /* 'î ¥' */
.icon-insight-dashboard:before { content: '\e826'; } /* 'î ¦' */
.icon-chat:before { content: '\e827'; } /* 'î §' */
.icon-hamburger:before { content: '\e828'; } /* 'î ¨' */
.icon-up-hand:before { content: '\e829'; } /* 'î ©' */
.icon-routing-focus:before { content: '\e82a'; } /* 'î ª' */
.icon-routing:before { content: '\e82b'; } /* 'î «' */
.icon-send:before { content: '\e82c'; } /* 'î ¬' */
.icon-map-favorite-focus:before { content: '\e82d'; } /* 'î ­' */
.icon-map-favorite:before { content: '\e82e'; } /* 'î ®' */
.icon-lock:before { content: '\e82f'; } /* 'î ¯' */
.icon-record:before { content: '\e830'; } /* 'î °' */
.icon-stop:before { content: '\e831'; } /* 'î ±' */
.icon-record-1:before { content: '\e832'; } /* 'î ²' */
.icon-stop-1:before { content: '\e833'; } /* 'î ³' */
.icon-truck:before { content: '\e834'; } /* 'î ´' */
.icon-truck-focus:before { content: '\e835'; } /* 'î µ' */
.icon-pause:before { content: '\e836'; } /* 'î ¶' */
.icon-play:before { content: '\e837'; } /* 'î ·' */
.icon-medal:before { content: '\e838'; } /* 'î ¸' */
.icon-pencil:before { content: '\e839'; } /* 'î ¹' */
.icon-pause-1:before { content: '\e83a'; } /* 'î º' */
.icon-play-1:before { content: '\e83b'; } /* 'î »' */
.icon-sound:before { content: '\e83c'; } /* 'î ¼' */
.icon-mic:before { content: '\e83d'; } /* 'î ½' */
.icon-add-note:before { content: '\e83e'; } /* 'î ¾' */
.icon-doc:before { content: '\e83f'; } /* 'î ¿' */
.icon-filter:before { content: '\e840'; } /* 'î¡€' */
.icon-image:before { content: '\e844'; } /* 'î¡„' */
.icon-turn-right:before { content: '\e845'; } /* 'î¡…' */
.icon-turn-left:before { content: '\e846'; } /* 'î¡†' */
.icon-info:before { content: '\e847'; } /* 'î¡‡' */
.icon-location-2:before { content: '\e849'; } /* 'î¡‰' */
.icon-fi_570953:before { content: '\e84a'; } /* 'î¡Š' */
.icon-hamburger-1:before { content: '\e84b'; } /* 'î¡‹' */
.icon-doc-text:before { content: '\f0f6'; } /* 'ïƒ¶' */
.icon-doc-text-inv:before { content: '\f15c'; } /* 'ï…œ' */
.icon-up:before { content: '\f176'; } /* 'ï…¶' */
.icon-clone:before { content: '\f24d'; }

  

  /*dlabs codes*/
  .icondl-add-days:before { content: '\e800'; }
  .icondl-add-days-full:before { content: '\e801'; }
  .icondl-arrow-left:before { content: '\e802'; }
  .icondl-arrow-right:before { content: '\e803'; }
  .icondl-cancel:before { content: '\e804'; }
  .icondl-cancel-circled:before { content: '\e805'; }
  .icondl-candidates:before { content: '\e806'; }
  .icondl-candidates-full:before { content: '\e807'; }
  .icondl-clock:before { content: '\e808'; }
  .icondl-clock-full:before { content: '\e809'; }
  .icondl-close:before { content: '\e80a'; }
  .icondl-book:before { content: '\e80b'; }
  .icondl-contract:before { content: '\e80c'; }
  .icondl-contract-full:before { content: '\e80d'; }
  .icondl-close-month:before { content: '\e80e'; }
  .icondl-download:before { content: '\e80f'; }
  .icondl-edit:before { content: '\e810'; }
  .icondl-error:before { content: '\e811'; }
  .icondl-free-days:before { content: '\e812'; }
  .icondl-free-days-full:before { content: '\e813'; }
  .icondl-info:before { content: '\e814'; }
  .icondl-logout:before { content: '\e815'; }
  .icondl-minus:before { content: '\e816'; }
  .icondl-my-free-days:before { content: '\e817'; }
  .icondl-my-free-days-full:before { content: '\e818'; }
  .icondl-my-hours:before { content: '\e819'; }
  .icondl-my-hours-full:before { content: '\e81a'; }
  .icondl-no-reports:before { content: '\e81b'; }
  .icondl-ok:before { content: '\e81c'; }
  .icondl-plus:before { content: '\e81d'; }
  .icondl-process:before { content: '\e81e'; }
  .icondl-process-full:before { content: '\e81f'; }
  .icondl-profile:before { content: '\e820'; }
  .icondl-profile-full:before { content: '\e821'; }
  .icondl-projects:before { content: '\e822'; }
  .icondl-projects-full:before { content: '\e823'; }
  .icondl-reports:before { content: '\e824'; }
  .icondl-reports-full:before { content: '\e825'; }
  .icondl-save:before { content: '\e826'; }
  .icondl-search:before { content: '\e827'; }
  .icondl-take-days:before { content: '\e828'; }
  .icondl-take-days-full:before { content: '\e829'; }
  .icondl-users:before { content: '\e82a'; }
  .icondl-users-full:before { content: '\e82b'; }
  .icondl-vacancies:before { content: '\e82c'; }
  .icondl-vacancies-full:before { content: '\e82d'; }
  .icondl-warning:before { content: '\e82e'; }
  .icondl-calendar-filled:before { content: '\e82f'; }
  .icondl-calendar-plus:before { content: '\e830'; }
  .icondl-calendar-question-filled:before { content: '\e831'; }
  .icondl-calendar-user-filled:before { content: '\e832'; }
  .icondl-candidates-1:before { content: '\e833'; }
  .icondl-clock-filled:before { content: '\e834'; }
  .icondl-contract-filled:before { content: '\e835'; }
  .icondl-file:before { content: '\e836'; }
  .icondl-folder-filled:before { content: '\e837'; }
  .icondl-gears-filled:before { content: '\e838'; }
  .icondl-graph:before { content: '\e839'; }
  .icondl-megaphone-filled:before { content: '\e83a'; }
  .icondl-profile-filled:before { content: '\e83b'; }
  .icondl-reports-filled:before { content: '\e83c'; }
  .icondl-target:before { content: '\e83d'; }
  .icondl-users-1:before { content: '\e83e'; }
  .icondl-close-circled:before { content: '\e83f'; }
  .icondl-delete-tab:before { content: '\e840'; }
  .icondl-download-circled:before { content: '\e841'; }
  .icondl-pdf:before { content: '\e842'; }
  .icondl-plus-circled:before { content: '\e843'; }

