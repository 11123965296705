/*Marker Info*/
.marker-info{ background: #FFF; width: 100%; height: 100vh; z-index: 200; position: fixed; }
.marker-info .marker-actions{ top: 38px; }
.marker-info .icon-arrow{ position: absolute; left: 20px; top: 10px; cursor: pointer; font-size: 15px; transform: rotate(90deg); top: 50%; margin-top: -8px; transition: all 0.3s ease; }
.marker-info .icon-arrow:hover{ left: 15px; }
.marker-info .marker-descriptions{ width: 100%; padding: 40px; position: relative; }
.marker-info .marker-descriptions .title figure{ display:inline-block; width: 12px; }
.marker-info .marker-descriptions .name{ font-weight: 500; font-size: 21px; color: #000; max-width: 300px; margin-bottom: 15px; }
.marker-info .marker-descriptions .info-desc span{ float: left; }
.marker-info .marker-descriptions .info-desc li{ float: left; width: 50%; font-style: normal; font-weight: normal; font-size: 15px; line-height: 30px; letter-spacing: 0.019em; color: #737582; max-width: 200px;  text-overflow: ellipsis;  height: 30px; }
.marker-info .marker-descriptions .info-desc li.all-space { float: left; width: 100%; max-width: 100%; height: auto; min-height: 60px; white-space: normal;  }

.marker-info .btn-white{ margin-top: 20px; padding: 7px 30px; font-size: 13px; }
.marker-info .btn-white .plus{ display: inline-block; }
.marker-info .marker-actions .icon-outline-pin,
.marker-info .marker-actions .icon-heart,
.marker-info .marker-actions .icon-heart-focus{ top: 2px; }

.marker-info .route-to-wrapper{ position: absolute; bottom: 20px; width: 100%; text-align: center; }
.marker-info .route-to-wrapper .route-to i{ font-size: 32px; position: absolute; top: 1px; margin-left: 10px; cursor: pointer; color: #9FA2B4; }
.marker-info .route-to-wrapper .route-to .btn{ width: 220px; display: inline-block; margin: 0 auto 15px; padding: 8px 0; }
.marker-info .route-to-wrapper .route-more{ position: absolute; bottom: 60px; right: 0; width: 100%; opacity: 0; max-height: 0; overflow: hidden; transition: all 0.3s; }
.marker-info .route-to-wrapper .route-more.show{ overflow: visible; max-height: 500px; opacity: 1; }
.marker-info .route-to-wrapper .route-more .btn{ display: block; }
.marker-info .route-to-wrapper .route-more .white-buttons{ max-width: 220px; float: right; margin-right: 110px; }

.marker-info .add-to-pins{ position: relative; display: block; margin-bottom: 10px; }


.marker-info .marker-gates{ width: 100%; margin-bottom: 32px;  height: calc(100vh - 580px); }
.marker-info .marker-gates h3{ margin-bottom: 20px; position: relative; left: 30px; }
.marker-info .marker-gates h3:before{ content: '\e805'; font-family: 'wellsite'; font-size: 21px; color: #000; float: left; margin-right: 5px; font-weight: normal; position: relative; top: -2px; }
.marker-info .marker-gates li{ position: relative;  padding: 20px 50px; width:90% }
.marker-info .marker-gates li figure{ float: left; margin-right: 13px; }
.marker-info .marker-gates li .name{ display: block; font-size: 18px; margin-bottom: 5px; }
.marker-info .marker-gates li .lat-long{ display: block; color: #a3a6b1; }
.marker-info .marker-gates li .icon-trash{ position: absolute; top: 50%; right: -20px; font-size: 22px; cursor: pointer; margin-top: -16px; }
.marker-info .marker-gates li .icondl-plus-circled{ position: absolute; top: 50%; right: -20px; font-size: 22px; cursor: pointer; margin-top: -16px; }
.marker-info .marker-gates .btn-white{ display: block; width: 200px; margin: 20px auto 0; }

/*Marker Actions*/
.marker-actions{ position: absolute; background: none; right: 10px; }
.marker-actions figure{ float: left; width: 35px; cursor: pointer; }
.marker-actions .icon-outline-pin{ float: left; color: #CF4727; font-size: 25px; margin-right: 0; cursor: pointer; position: relative; top: 5px; }
.marker-actions .icon-outline-pin.active:before{ content: '\e81e'; font-size: 27px; }
.marker-actions .icon-heart{ float: left; color: #CF4727; font-size: 25px; margin-right: 5px; cursor: pointer; position: relative; top: 5px; }
.marker-actions .icon-heart-focus{ float: left; color: #CF4727; font-size: 25px; margin-right: 0; cursor: pointer; position: relative; top: 5px; }
.marker-actions .icon-dots{ font-size: 20px; margin-left: 0; position: relative; top: 5px; cursor: pointer; }
.marker-actions .icon-clone{ font-size: 20px; position: relative; top: 2px; }
.marker-actions .icon-share{ font-size: 24px; position: relative; top: 2px; }
.marker-actions .icon{transition: all 0.3s ease; cursor: pointer;}
.marker-actions .icon:hover{ opacity: .7;}

    /*Menu Popup*/
    .marker-actions .menu-popup-wrap{ position: relative; }
    .marker-actions .menu-popup{ background: #FFF; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08); border-radius: 5px; right: 10px; width: 0; max-height: 0; opacity: 0; overflow: hidden; z-index: 10; position: absolute; top: 30px; transition: opacity 0.3s ease; }
    .marker-actions .menu-popup.show{ opacity: 1; width: 110px; max-height: 500px; overflow: visible; padding: 10px 0; z-index: 100; }
    .marker-actions .menu-popup ul li{ padding: 5px 10px; transition: all 0.3s ease; font-size: 14px; color: #1F232E; display: block; width: 100%; cursor: pointer; border: none !important; }
    .marker-actions .menu-popup ul li:hover{ background: #ebf1f780; }
    .marker-actions .menu-popup ul li i{ font-size: 17px; }
