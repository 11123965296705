 agm-map{ height: 100%; }

.map-container{ position: absolute; width: 100%; height: 100vh; top: 0; left: 0; overflow: hidden; }
.map-container .map{ position: absolute; width: 100%; height: 100vh; top: 0; right: 0; transition: all 0.3s ease; }
.map-container.sidebarOpen .map{ width: calc(100% - 552px); }

/*Map Options*/
.map-container .map .map-options{ width: 46px; position: fixed; right: 30px; top: 30px; background: none; z-index: 99;}
.map-container .map .map-options a{ width: 46px; height: 46px; line-height: 46px; text-align: center; border-radius: 100%; background:#FFF; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); display: block; cursor: pointer; margin-bottom: 10px; color: #313A52; font-size: 20px; transition: all 0.3s; }
.map-container .map .map-options a:hover{ box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3); }
.map-container .map .map-options a.active{ background: #313A52; color: #FFF; }


    .map-container .map .map-options a.location{ margin-top: 40px; }

/*Map Mini Maps*/
.map-container .map .mini-maps{ position: fixed; bottom: 40px; right: 30px; }
.map-container .map .mini-maps .mini-map-image{ width: 120px; height: 120px; float: left; margin-left: 15px; cursor: pointer;  position: relative; }
.map-container .map .mini-maps .mini-map-image.disabled{ opacity: 0.4; cursor: not-allowed; }

    /*Tooltip*/
    .map-container .map .mini-maps .mini-map-image .tooltiptext{ visibility: visible; width: 120px; background: none; color: #fff; text-align: left; border-radius: 6px; padding: 5px 0; position: absolute; z-index: 1; width: 120px; bottom: 8px; left: 10px; margin-left: 0; font-size: 13px; width: 100%; padding-right: 20px; }


/*Search Container*/
.map-container .map .search-container{ position: fixed; left: 110px; top: 35px; z-index: 999; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08); border-radius: 8px; transition: all 0.3s; }
.map-container .map .search-container.hide{ transform: translateY(-200px); }
.map-container .map .search-container input{ background: none; border: none; width: 500px; height: 40px; border-radius: 8px; background: #FFF; padding: 0 20px; padding-right: 55px; }
.map-container .map .search-container .icon-search-bar{ position: absolute; top: 8px; right: 15px; color: #C9CED6; font-size: 20px; }

/*Layers Expand*/
.layers-box{ background: #FFF; border-radius: 50px; margin-bottom: 10px;}
.layers-box .layers{ margin-bottom: 0 !important; }
.layers-box .layers-expand{ background: none; max-height: 0; overflow: hidden; transition: all 0.3s ease; }
.layers-box .layers-expand.open{ max-height: 400px; }
.layers-box .layers-expand li{ width: 100%; height: auto; text-align: center; }
.layers-box .layers-expand li:first-child{ margin-top: 10px; }
.layers-box .layers-expand li:last-child{ margin-top: 10px; }
.layers-box .layers-expand li img{ border: 1px solid transparent; background: transparent; padding: 5px; transition: all 0.3s ease; cursor: pointer; border-radius: 5px; }
.layers-box .layers-expand li:hover img{ border: 1px solid #313a52; background: #ebf1f7; }
.layers-box .layers-expand li.selected img{ border: 1px solid #313a52; background: #ebf1f7; }
.layers-box .layers-expand li span{ display: block; font-size: 11px; margin-bottom: 5px; }

/*Map info*/
.map-info-box{ position: fixed; width: 0; overflow: hidden; right: 325px; bottom: 40px; background: #FFF; z-index: 999; opacity: 0; visibility: hidden; height: 55px; border-radius: 10px; box-shadow: 0 0 10px rgba(0,0,0,0.3); padding: 20px; display: flex; flex-direction: row; justify-content: space-between; transition: all 0.3s; }
.map-info-box.open{ width: 500px; visibility: visible; opacity: 1; }
.map-info-box ul{ display: flex; flex-direction: row; justify-content: space-between; width: 100%; font-size: 13px; font-weight: bold; }
.map-info-box ul p span{ color: #CF4727; }

.map-info-open{ position: fixed; width: 30px; height: 30px; bottom: 50px; right: 290px; text-align: center; line-height: 30px; font-size: 20px; cursor: pointer; opacity: 0.7; transition: all 0.3s; }
.map-info-open:hover{ opacity: 1; }
.map-info-open.close i:before{ content: '\e83f'; }
.map-info-open.close{ opacity: 1; }

/* Map Markers */
.marker-label{-webkit-text-stroke: 1px #FFF; margin-top: 25px;}

/* Map Draw Route */
.map-container .delete-menu {position: absolute; background: white; padding: 3px; color: #666; font-weight: bold; border: 1px solid #999; font-family: sans-serif; font-size: 12px; box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.3); margin-top: -10px; margin-left: 10px; cursor: pointer;}
  
.map-container .delete-menu:hover {background: #eee;}

/* Map Draw Menu */
.menu.draw { position: absolute; display: flex; background: #F3F3F3; border: 1px solid #929292; font-size: 16px;}
.menu.draw .menu-item{transition: all 0.3s ease; cursor: pointer;}
.menu.draw .menu-item:hover{background: #CFCFCF;}
.menu.draw .menu-item.delete{color: #CF4727; border-right: 1px solid #929292;}
.map-container .draw-menu {height: 46px; position: fixed; left: 580px; top: 20px; background: none; z-index: 99; display: flex; flex-direction: row;}
.map-container .draw-menu i.icon-up::before{transform: rotate(45deg);}
.map-container .draw-menu a{display: block; width: 46px; height: 46px; line-height: 46px; text-align: center; border-radius: 100%; background:#FFF; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); cursor: pointer; margin-right: 10px; color: #313A52; font-size: 20px; transition: all 0.3s;}
.map-container .draw-menu a.active{background: #313A52; color: #FFF}
.draw-marker-label{ padding-bottom: 50px; text-shadow: -1px 0px 0px #FFF, 1px 0px 0px #FFF, 0px -1px 0px #FFF, 0px 1px 0px #FFF; font-weight: 400; letter-spacing: 0.6px; font-size: 15px !important;}
div.info-window {max-height: 160px;}
div.info-window .info-window__title {max-width: 180px; display: block; overflow-wrap: break-word;}
div.info-window .info-window__description {max-width: 180px; display: block; overflow-wrap: break-word;}